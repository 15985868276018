export default function DeleteSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M17.2222 22.25H8.87215C8.03673 22.2389 7.23666 21.9381 6.63192 21.4079C6.02719 20.8776 5.66237 20.157 5.6104 19.39L4.62101 5.55C4.61594 5.44966 4.63254 5.34938 4.66987 5.25498C4.70719 5.16057 4.76449 5.07391 4.83846 5C4.91466 4.9234 5.00692 4.86177 5.10968 4.81881C5.21244 4.77584 5.32359 4.75244 5.43645 4.75H20.6579C20.7697 4.74977 20.8804 4.7707 20.9831 4.81148C21.0857 4.85226 21.1782 4.91202 21.2546 4.98704C21.3311 5.06207 21.39 5.15077 21.4276 5.24761C21.4652 5.34446 21.4808 5.44739 21.4734 5.55L20.5275 19.39C20.4749 20.1638 20.104 20.8902 19.49 21.4214C18.8761 21.9527 18.0652 22.249 17.2222 22.25ZM6.34973 6.25L7.18692 19.3C7.21457 19.6935 7.40426 20.0625 7.71736 20.3319C8.03045 20.6013 8.44341 20.7508 8.87215 20.75H17.2222C17.6502 20.7485 18.0617 20.5982 18.3743 20.3292C18.6868 20.0603 18.8773 19.6927 18.9075 19.3L19.7881 6.3L6.34973 6.25Z' />
      <path d='M22.8322 6.25H3.26173C3.04546 6.25 2.83805 6.17098 2.68512 6.03033C2.5322 5.88968 2.44629 5.69891 2.44629 5.5C2.44629 5.30109 2.5322 5.11032 2.68512 4.96967C2.83805 4.82902 3.04546 4.75 3.26173 4.75H22.8322C23.0485 4.75 23.2559 4.82902 23.4088 4.96967C23.5617 5.11032 23.6476 5.30109 23.6476 5.5C23.6476 5.69891 23.5617 5.88968 23.4088 6.03033C23.2559 6.17098 23.0485 6.25 22.8322 6.25Z' />
      <path d='M16.3087 6.25H9.78516C9.56977 6.24741 9.36399 6.16756 9.21168 6.02747C9.05936 5.88737 8.97254 5.69811 8.96973 5.5V3.7C8.98351 3.18682 9.2113 2.69816 9.60596 2.33518C10.0006 1.97219 10.5319 1.76268 11.0899 1.75H15.004C15.5712 1.76305 16.1104 1.97951 16.5065 2.35317C16.9026 2.72684 17.1243 3.22812 17.1241 3.75V5.5C17.1213 5.69811 17.0345 5.88737 16.8821 6.02747C16.7298 6.16756 16.524 6.24741 16.3087 6.25ZM10.6006 4.75H15.4932V3.75C15.4932 3.63065 15.4417 3.51619 15.3499 3.4318C15.2582 3.34741 15.1337 3.3 15.004 3.3H11.0899C10.9601 3.3 10.8357 3.34741 10.7439 3.4318C10.6521 3.51619 10.6006 3.63065 10.6006 3.75V4.75Z' />
      <path d='M16.3086 18.25C16.0932 18.2474 15.8874 18.1676 15.7351 18.0275C15.5828 17.8874 15.496 17.6981 15.4932 17.5V9.5C15.4932 9.30109 15.5791 9.11032 15.732 8.96967C15.8849 8.82902 16.0923 8.75 16.3086 8.75C16.5249 8.75 16.7323 8.82902 16.8852 8.96967C17.0381 9.11032 17.124 9.30109 17.124 9.5V17.5C17.1212 17.6981 17.0344 17.8874 16.8821 18.0275C16.7298 18.1676 16.524 18.2474 16.3086 18.25Z' />
      <path d='M9.78516 18.25C9.56977 18.2474 9.36399 18.1676 9.21168 18.0275C9.05936 17.8874 8.97254 17.6981 8.96973 17.5V9.5C8.96973 9.30109 9.05564 9.11032 9.20856 8.96967C9.36149 8.82902 9.5689 8.75 9.78516 8.75C10.0014 8.75 10.2088 8.82902 10.3618 8.96967C10.5147 9.11032 10.6006 9.30109 10.6006 9.5V17.5C10.5978 17.6981 10.511 17.8874 10.3586 18.0275C10.2063 18.1676 10.0006 18.2474 9.78516 18.25Z' />
      <path d='M13.0469 18.25C12.8315 18.2474 12.6257 18.1676 12.4734 18.0275C12.3211 17.8874 12.2343 17.6981 12.2314 17.5V9.5C12.2314 9.30109 12.3174 9.11032 12.4703 8.96967C12.6232 8.82902 12.8306 8.75 13.0469 8.75C13.2631 8.75 13.4706 8.82902 13.6235 8.96967C13.7764 9.11032 13.8623 9.30109 13.8623 9.5V17.5C13.8595 17.6981 13.7727 17.8874 13.6204 18.0275C13.468 18.1676 13.2623 18.2474 13.0469 18.25Z' />
    </svg>
  );
}
