export default function BoxesSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_6147_9129)'>
        <path d='M11 13.75C11 13.337 10.662 13 10.25 13H6.5V16C6.5 16.552 6.052 17 5.5 17C4.948 17 4.5 16.552 4.5 16V13H0.75C0.337 13 0 13.337 0 13.75V23.25C0 23.663 0.337 24 0.75 24H10.25C10.662 24 11 23.663 11 23.25V13.75Z' />
        <path d='M24 13.75C24 13.337 23.662 13 23.25 13H19.5V16C19.5 16.552 19.052 17 18.5 17C17.948 17 17.5 16.552 17.5 16V13H13.75C13.337 13 13 13.337 13 13.75V23.25C13 23.663 13.337 24 13.75 24H23.25C23.662 24 24 23.663 24 23.25V13.75Z' />
        <path d='M17.5 0.75C17.5 0.338 17.162 0 16.75 0H13V3C13 3.552 12.552 4 12 4C11.448 4 11 3.552 11 3V0H7.25C6.837 0 6.5 0.338 6.5 0.75V10.25C6.5 10.663 6.837 11 7.25 11H16.75C17.162 11 17.5 10.663 17.5 10.25V0.75Z' />
      </g>
      <defs>
        <clipPath id='clip0_6147_9129'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
