export default function DimensionsSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M12.4004 17.2145L16.0004 14.5965V10.7881L12.4004 13.4041V17.2145Z' />
      <path d='M8 14.5965L11.6 17.2145V13.4041L8 10.7881V14.5965Z' />
      <path d='M11.999 7.29395L8.27734 10.0007L11.999 12.7051L15.7206 10.0007L11.999 7.29395Z' />
      <path d='M19.8016 7.16953L12.2016 2.71593C12.1403 2.68006 12.0706 2.66115 11.9996 2.66113C11.9292 2.66127 11.8602 2.68019 11.7996 2.71593L4.19955 7.16953C4.13937 7.20484 4.08947 7.25528 4.0548 7.31583C4.02014 7.37639 4.00192 7.44496 4.00195 7.51474V16.4851C4.00196 16.555 4.02024 16.6236 4.05498 16.6841C4.08971 16.7447 4.1397 16.7951 4.19995 16.8303L11.8 21.2839C11.8613 21.3199 11.9311 21.3388 12.0022 21.3388C12.0732 21.3388 12.143 21.3199 12.2044 21.2839L19.8044 16.8303C19.8641 16.7947 19.9135 16.7442 19.9478 16.6836C19.982 16.6231 19.9998 16.5547 19.9996 16.4851V7.51474C19.9996 7.44492 19.9813 7.37632 19.9465 7.31576C19.9118 7.2552 19.8618 7.20479 19.8016 7.16953ZM18.7424 16.2059C18.6877 16.2968 18.5992 16.3623 18.4963 16.388C18.3934 16.4136 18.2845 16.3974 18.1936 16.3427L16.4208 15.2799L12.4704 18.1531C12.3336 18.2526 12.1689 18.3062 11.9998 18.3062C11.8307 18.3062 11.6659 18.2526 11.5292 18.1531L7.57836 15.2799L5.80555 16.3435C5.76051 16.3717 5.7103 16.3907 5.65786 16.3992C5.60542 16.4078 5.5518 16.4058 5.50012 16.3934C5.44845 16.3811 5.39976 16.3585 5.35691 16.3271C5.31405 16.2957 5.27788 16.256 5.25051 16.2105C5.22314 16.1649 5.20512 16.1144 5.1975 16.0618C5.18988 16.0092 5.19281 15.9557 5.20613 15.9042C5.21945 15.8528 5.24288 15.8045 5.27506 15.7622C5.30724 15.7199 5.34752 15.6845 5.39355 15.6579L7.19955 14.5735V10.2035C7.19978 10.0774 7.2297 9.95318 7.28689 9.84081C7.34409 9.72844 7.42694 9.63112 7.52876 9.55674L11.5996 6.59633V4.79993C11.5996 4.69385 11.6417 4.59211 11.7167 4.51709C11.7917 4.44208 11.8935 4.39993 11.9996 4.39993C12.1056 4.39993 12.2074 4.44208 12.2824 4.51709C12.3574 4.59211 12.3996 4.69385 12.3996 4.79993V6.59633L16.4704 9.55634C16.5722 9.63076 16.6551 9.72814 16.7123 9.84059C16.7695 9.95304 16.7994 10.0774 16.7996 10.2035V14.5735L18.6056 15.6571C18.6965 15.7118 18.7619 15.8003 18.7876 15.9032C18.8132 16.0061 18.797 16.115 18.7424 16.2059Z' />
      <path d='M21.6152 5.91437L12.3844 0.504771C12.2678 0.436422 12.1351 0.400391 12 0.400391C11.8649 0.400391 11.7322 0.436422 11.6156 0.504771L2.3848 5.91437C2.26733 5.98387 2.17004 6.08283 2.10255 6.20146C2.03507 6.32009 1.99972 6.45429 2 6.59077V17.41C1.99972 17.5465 2.03507 17.6807 2.10255 17.7993C2.17004 17.9179 2.26733 18.0169 2.3848 18.0864L11.6156 23.4956C11.7322 23.5639 11.8649 23.6 12 23.6C12.1351 23.6 12.2678 23.5639 12.3844 23.4956L21.6152 18.0864C21.7327 18.0169 21.83 17.9179 21.8975 17.7993C21.9649 17.6807 22.0003 17.5465 22 17.41V6.59077C22.0003 6.45429 21.9649 6.32009 21.8975 6.20146C21.83 6.08283 21.7327 5.98387 21.6152 5.91437ZM20.8 16.4856C20.7995 16.6947 20.7446 16.9001 20.6406 17.0816C20.5365 17.263 20.387 17.4143 20.2068 17.5204L12.6068 21.9744C12.4227 22.082 12.2133 22.1388 12 22.1388C11.7867 22.1388 11.5773 22.082 11.3932 21.9744L3.7932 17.5204C3.61296 17.4143 3.46347 17.263 3.35946 17.0816C3.25544 16.9001 3.20048 16.6947 3.2 16.4856V7.51517C3.20048 7.30602 3.25544 7.1006 3.35946 6.91915C3.46347 6.7377 3.61296 6.58648 3.7932 6.48037L11.3932 2.02637C11.5773 1.91871 11.7867 1.86196 12 1.86196C12.2133 1.86196 12.4227 1.91871 12.6068 2.02637L20.2068 6.48037C20.387 6.58648 20.5365 6.7377 20.6406 6.91915C20.7446 7.1006 20.7995 7.30602 20.8 7.51517V16.4856Z' />
    </svg>
  );
}
