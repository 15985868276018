export default function WaitingSvg() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#D80027' fillOpacity='0.16' />
      <path
        d='M24.7394 18.6644V11.7452C24.7394 11.106 24.2193 10.5859 23.5802 10.5859H9.75156C9.11237 10.5859 8.59234 11.106 8.59234 11.7452V12.1764H8.20609C7.56691 12.1764 7.04688 12.6964 7.04688 13.3356V20.2548C7.04688 20.894 7.56691 21.4141 8.20609 21.4141H17.0043C17.2049 23.4247 18.9062 25 20.9688 25C23.1657 25 24.9531 23.2126 24.9531 21.0156C24.9531 20.3962 24.811 19.8095 24.5577 19.2861C24.6744 19.102 24.7394 18.8865 24.7394 18.6644ZM9.52984 11.7452C9.52984 11.6229 9.62931 11.5234 9.75156 11.5234H23.5802C23.7024 11.5234 23.8019 11.6229 23.8019 11.7452V18.2175C23.6165 18.0298 23.4131 17.8601 23.1939 17.712V13.3356C23.1939 12.6964 22.6738 12.1764 22.0347 12.1764H9.52984V11.7452ZM7.98438 20.2548V13.3356C7.98438 13.2134 8.08384 13.1139 8.20609 13.1139H9.02866C9.03944 13.1147 9.05013 13.1155 9.06109 13.1155C9.07188 13.1155 9.08242 13.1147 9.09306 13.1139H22.0347C22.1569 13.1139 22.2564 13.2134 22.2564 13.3356V17.246C22.0893 17.1888 21.9175 17.1419 21.7413 17.1071C21.8145 17.0244 21.8593 16.916 21.8593 16.7969C21.8593 16.538 21.6494 16.3281 21.3906 16.3281H20.5468C20.288 16.3281 20.0781 16.538 20.0781 16.7969C20.0781 16.916 20.1229 17.0244 20.1961 17.1071C18.5407 17.4338 17.2512 18.7887 17.0218 20.4766H8.20609C8.08384 20.4766 7.98438 20.3771 7.98438 20.2548ZM20.9688 24.0625C19.2887 24.0625 17.9219 22.6957 17.9219 21.0156C17.9219 19.3356 19.2887 17.9688 20.9688 17.9688C22.6488 17.9688 24.0156 19.3356 24.0156 21.0156C24.0156 22.6957 22.6488 24.0625 20.9688 24.0625Z'
        fill='#D80027'
      />
      <path
        d='M17.6306 16.7728C17.6306 15.3763 16.4945 14.2402 15.098 14.2402C13.7015 14.2402 12.5654 15.3763 12.5654 16.7728C12.5654 18.1693 13.7015 19.3054 15.098 19.3054C16.4945 19.3054 17.6306 18.1693 17.6306 16.7728ZM15.098 18.3679C14.2185 18.3679 13.5029 17.6524 13.5029 16.7728C13.5029 15.8933 14.2185 15.1777 15.098 15.1777C15.9776 15.1777 16.6931 15.8933 16.6931 16.7728C16.6931 17.6524 15.9776 18.3679 15.098 18.3679Z'
        fill='#D80027'
      />
      <path
        d='M22.4229 21.7353L21.2031 21.027V19.375C21.2031 19.1161 20.9932 18.9062 20.7344 18.9062C20.4755 18.9062 20.2656 19.1161 20.2656 19.375V21.2969C20.2656 21.4639 20.3545 21.6183 20.499 21.7023L21.9521 22.546C22.0262 22.589 22.1072 22.6095 22.1871 22.6095C22.3487 22.6095 22.5059 22.5258 22.5929 22.376C22.7229 22.1521 22.6468 21.8652 22.4229 21.7353Z'
        fill='#D80027'
      />
    </svg>
  );
}
