export default function CalenderSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_5504_6611)'>
        <path d='M2.41739 16H13.5818C14.7522 16 15.7033 15.0489 15.7033 13.8785V3.30665C15.7033 2.13628 14.7522 1.18516 13.5818 1.18516H12.7404V0.592582C12.7404 0.266652 12.4737 0 12.1477 0C11.8218 0 11.5551 0.266652 11.5551 0.592582V1.18516H4.44407V0.592582C4.44407 0.266652 4.17737 0 3.85144 0C3.52551 0 3.25886 0.266652 3.25886 0.592582V1.18516H2.41739C1.24702 1.18516 0.295898 2.13628 0.295898 3.30665V13.8785C0.295898 15.0489 1.24702 16 2.41739 16ZM1.48111 3.30665C1.48111 2.79111 1.90185 2.37038 2.41739 2.37038H3.25886V2.96296C3.25886 3.28889 3.52551 3.55554 3.85144 3.55554C4.17737 3.55554 4.44402 3.28889 4.44402 2.96296V2.37038H11.5551V2.96296C11.5551 3.28889 11.8218 3.55554 12.1477 3.55554C12.4737 3.55554 12.7403 3.28889 12.7403 2.96296V2.37038H13.5818C14.0973 2.37038 14.5181 2.79111 14.5181 3.30665V13.8785C14.5181 14.3941 14.0973 14.8148 13.5818 14.8148H2.41739C1.90185 14.8148 1.48111 14.3941 1.48111 13.8785V3.30665Z' />
        <path d='M4.59229 6.81348C5.00138 6.81348 5.33302 6.48185 5.33302 6.07276C5.33302 5.66367 5.00138 5.33203 4.59229 5.33203C4.1832 5.33203 3.85156 5.66367 3.85156 6.07276C3.85156 6.48185 4.1832 6.81348 4.59229 6.81348Z' />
        <path d='M7.85108 6.81348C8.26017 6.81348 8.59181 6.48185 8.59181 6.07276C8.59181 5.66367 8.26017 5.33203 7.85108 5.33203C7.44199 5.33203 7.11035 5.66367 7.11035 6.07276C7.11035 6.48185 7.44199 6.81348 7.85108 6.81348Z' />
        <path d='M11.1108 6.81348C11.5199 6.81348 11.8516 6.48185 11.8516 6.07276C11.8516 5.66367 11.5199 5.33203 11.1108 5.33203C10.7018 5.33203 10.3701 5.66367 10.3701 6.07276C10.3701 6.48185 10.7018 6.81348 11.1108 6.81348Z' />
        <path d='M4.59229 9.77833C5.00138 9.77833 5.33302 9.4467 5.33302 9.0376C5.33302 8.62851 5.00138 8.29688 4.59229 8.29688C4.1832 8.29688 3.85156 8.62851 3.85156 9.0376C3.85156 9.4467 4.1832 9.77833 4.59229 9.77833Z' />
        <path d='M7.85108 9.77833C8.26017 9.77833 8.59181 9.4467 8.59181 9.0376C8.59181 8.62851 8.26017 8.29688 7.85108 8.29688C7.44199 8.29688 7.11035 8.62851 7.11035 9.0376C7.11035 9.4467 7.44199 9.77833 7.85108 9.77833Z' />
        <path d='M11.1108 9.77833C11.5199 9.77833 11.8516 9.4467 11.8516 9.0376C11.8516 8.62851 11.5199 8.29688 11.1108 8.29688C10.7018 8.29688 10.3701 8.62851 10.3701 9.0376C10.3701 9.4467 10.7018 9.77833 11.1108 9.77833Z' />
        <path d='M4.59229 12.7412C5.00138 12.7412 5.33302 12.4096 5.33302 12.0005C5.33302 11.5914 5.00138 11.2598 4.59229 11.2598C4.1832 11.2598 3.85156 11.5914 3.85156 12.0005C3.85156 12.4096 4.1832 12.7412 4.59229 12.7412Z' />
        <path d='M7.85108 12.7412C8.26017 12.7412 8.59181 12.4096 8.59181 12.0005C8.59181 11.5914 8.26017 11.2598 7.85108 11.2598C7.44199 11.2598 7.11035 11.5914 7.11035 12.0005C7.11035 12.4096 7.44199 12.7412 7.85108 12.7412Z' />
        <path d='M11.1108 12.7412C11.5199 12.7412 11.8516 12.4096 11.8516 12.0005C11.8516 11.5914 11.5199 11.2598 11.1108 11.2598C10.7018 11.2598 10.3701 11.5914 10.3701 12.0005C10.3701 12.4096 10.7018 12.7412 11.1108 12.7412Z' />
      </g>
      <defs>
        <clipPath id='clip0_5504_6611'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
