export default function ArrowRightSvg() {
  return (
    <svg
      width='1em'
      height='0.7em'
      viewBox='0 0 15 14'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M6.65625 1.09375C6.34375 1.40625 6.375 1.875 6.65625 2.1875L10.4375 5.75H1.46875C1.03125 5.75 0.71875 6.09375 0.71875 6.5V7.5C0.71875 7.9375 1.03125 8.25 1.46875 8.25H10.4375L6.65625 11.8438C6.375 12.1562 6.375 12.625 6.65625 12.9375L7.34375 13.625C7.65625 13.9062 8.125 13.9062 8.40625 13.625L14.5 7.53125C14.7812 7.25 14.7812 6.78125 14.5 6.46875L8.40625 0.40625C8.125 0.125 7.65625 0.125 7.34375 0.40625L6.65625 1.09375Z' />
    </svg>
  );
}
