import { useContext } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import ProductContext from '../contexts/ProductContext';
import Add3dModel from './Add3dModel';
import AddArEffect from './AddArEffect';
import OrderForm from './OrderForm';
import ProductInformation from './ProductInformation';
import ProductsPackage from './ProductsPackage';

const { TabPane } = Tabs;

export default function ProductDetails({ hideModal }) {
  const { product } = useContext(ProductContext);
  const { t } = useTranslation(['products']);

  return (
    <Tabs
      className='pv-tabs-container'
      type='card'
      defaultActiveKey='1'
      renderTabBar={({ panes, activeKey, onTabClick }) => (
        <Row justify='center' className='cc-mb-20 pv-tabs'>
          {panes.map(
            (pane) =>
              pane && (
                <Col
                  style={{
                    width: `${100 / panes.filter((pane) => !!pane).length}%`,
                  }}
                  key={pane.key}
                  className={`cc-fz-12 cc-fw-500 cc-clickable cc-center-items pv-tabpane ${
                    activeKey === pane.key ? 'pv-active-tabpane' : ''
                  }`}
                  onClick={(e) => onTabClick(pane.key, e)}>
                  {pane.props.tab}
                </Col>
              ),
          )}
        </Row>
      )}
      centered
      animated>
      <TabPane tab={t('productInfoTabPane')} key='1'>
        <ProductInformation hideModal={hideModal} />
      </TabPane>
      <TabPane tab={t('add3dModelTabPane')} key='2'>
        <Add3dModel />
      </TabPane>
      <TabPane tab={t('addArEffectTabPane')} key='3'>
        <AddArEffect />
      </TabPane>
      {['SERVICE', 'FOOD'].includes(product.type) && (
        <TabPane tab={t('orderFormTabPane')} key='4'>
          <OrderForm hideModal={hideModal} />
        </TabPane>
      )}
      {['GROUP'].includes(product.type) && (
        <TabPane tab={t('productsPackageTabPane')} key='5'>
          <ProductsPackage hideModal={hideModal} />
        </TabPane>
      )}
    </Tabs>
  );
}
