import { useContext } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Select,
  Space,
  TreeSelect,
  Typography,
} from 'antd';

import ChickChackConfigContext from 'contexts/ChickChackConfigContext';

import MultiLingualInput from 'components/MultiLingualInput';
import ProductContext from './contexts/ProductContext';

const { Option } = Select;
const { TreeNode } = TreeSelect;

export default function RequestCategory({ hideModal }) {
  const { categories } = useContext(ProductContext);

  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);

  const CategoryTreeNode = ({ id, name, subCategories }) => {
    if (subCategories?.length)
      return (
        <TreeNode key={id} value={id} title={name[inputsLang.code]}>
          {subCategories.map(CategoryTreeNode)}
        </TreeNode>
      );
    else return <TreeNode key={id} value={id} title={name[inputsLang.code]} />;
  };

  return (
    <Form name='product-category-form' layout='vertical'>
      <Form.Item
        label={
          <Typography className='cc-gc cc-fz-14 cc-fw-500'>
            Request Category Name
          </Typography>
        }>
        <Form.Item
          name='name'
          noStyle
          rules={[
            {
              type: 'object',
              required: true,
              fields: {
                ar: { type: 'string', required: true },
                en: { type: 'string', required: true },
              },
              message: 'Please enter a value in all languages',
            },
          ]}>
          <MultiLingualInput
            type='text'
            placeholder={'Category Name'}
            addonAfter={
              <Select
                className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                defaultValue='en'
                value={inputsLang?.code}
                onChange={(code) =>
                  setInputsLang(langs.find((lang) => lang.code === code))
                }>
                {langs?.map((lang) => (
                  <Option
                    className='cc-fz-14'
                    key={lang.code}
                    value={lang.code}>
                    {lang.code.toUpperCase()}
                  </Option>
                ))}
              </Select>
            }
          />
        </Form.Item>

        <Form.Item name='isSub' valuePropName='checked' noStyle>
          <Checkbox className='cc-pc cc-fw-500'>
            Add to pre-existing category
          </Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, curr) => prev?.isSub !== curr?.isSub}
        noStyle>
        {({ getFieldValue }) =>
          getFieldValue('isSub') && (
            <Form.Item
              name='parentCategoryId'
              label={
                <Typography className='cc-gc cc-fz-14 cc-fw-500'>
                  Select Category
                </Typography>
              }>
              <TreeSelect
                className='cc-w-100'
                showSearch
                showArrow
                treeDefaultExpandAll
                placeholder={'Type to Choose'}>
                {categories.map(CategoryTreeNode)}
              </TreeSelect>
            </Form.Item>
          )
        }
      </Form.Item>

      <Space direction='horizontal' align='center'>
        <Button type='primary' htmlType='submit'>
          Send
        </Button>
        <Button type='primary' ghost onClick={hideModal}>
          Cancel
        </Button>
      </Space>
    </Form>
  );
}
