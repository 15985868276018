import { useContext, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Image,
  Input,
  Menu,
  Row,
  Typography,
  Form,
  Select,
  Tag,
  Tooltip,
  Modal,
  message,
  TreeSelect,
} from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import {
  getProductToolTip,
  getProductIcon,
} from 'utils/products/get-product-info';
import MultiLingualInput from 'components/MultiLingualInput';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import UserContext from 'contexts/UserContext';
import ShowSvg from 'assets/jsx-svgs/show.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import HideSvg from 'assets/jsx-svgs/hide.svg';
import ArrowDownSvg from 'assets/jsx-svgs/arrow-down.svg';
import SliderSvg from 'assets/jsx-svgs/slider.svg';
import PriceTagSvg from 'assets/jsx-svgs/price-tag.svg';
import InfinitySvg from 'assets/jsx-svgs/infinity.svg';
import BoxesSvg from 'assets/jsx-svgs/boxes.svg';
import placeholderImg from 'assets/images/placeholder.png';

import ProductDetails from './ProductDetails';
import OptionsAndQty from './OptionsAndQty';
import ProductContext from './contexts/ProductContext';
import ProductImages from './ProductImages';
import RequestCategory from './RequestCategory';

const { Option } = Select;
const { TreeNode } = TreeSelect;

export default function ProductCard() {
  const [isPdVisible, setIsPdVisible] = useState(false);
  const [isOqVisible, setIsOqVisible] = useState(false);
  const [isPiVisible, setIsPiVisible] = useState(false);
  const [isRcVisible, setIsRcVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();

  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { user } = useContext(UserContext);
  const {
    product,
    productIdx,
    categories,
    refetchProduct,
    triggerQuantity,
    triggerShowStatus,
  } = useContext(ProductContext);
  const { t } = useTranslation(['products', 'common']);

  const onSave = async (values) => {
    setSaveLoading(true);

    if (!isNaN(Number(values.id))) {
      // existing product
      const reqData = {
        productId: values.id,
        name: values.name,
        price: Number(values.price),
        quantity: isNaN(Number(values.quantity)) ? -1 : Number(values.quantity),
        categories: values.categories || [],
      };

      try {
        await axios.put(
          `/seller/product/${user?.selectedBusiness?.id}/edit-main-info`,
          reqData,
        );

        await refetchProduct(values.id, productIdx);
        message.success('Product saved successfully');
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      }
    } else {
      // new product
      const reqData = {
        type: values.type,
        name: values.name,
        price: Number(values.price),
        quantity: isNaN(Number(values.quantity)) ? -1 : Number(values.quantity),
        categories: values.categories || [],
      };

      try {
        const {
          data: { data: updatedProduct },
        } = await axios.post(
          `/seller/product/${user?.selectedBusiness?.id}/add`,
          reqData,
        );

        await refetchProduct(updatedProduct.id, productIdx);
        message.success('Product saved successfully');
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      }
    }

    setSaveLoading(false);
  };

  const CategoryTreeNode = ({ id, name, subCategories }) => {
    if (subCategories?.length)
      return (
        <TreeNode key={id} value={id} title={name[inputsLang.code]}>
          {subCategories.map(CategoryTreeNode)}
        </TreeNode>
      );
    else return <TreeNode key={id} value={id} title={name[inputsLang.code]} />;
  };

  useDeepCompareEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      quantity: product.quantity,
    });
  }, [product.quantity]);

  return (
    <>
      <Form
        className='pv-product-form'
        name='product-form'
        form={form}
        initialValues={{
          ...product,
          categories: product?.categories?.map((cat) => cat.id) || [],
        }}
        onFinish={onSave}>
        <Card
          className='pv-product-card'
          bodyStyle={{ padding: '10px 0' }}
          cover={
            <div
              className='cc-center-items pv-product-card-cover'
              onClick={() =>
                isNaN(product.id)
                  ? message.warn(t('saveProductWarn'))
                  : setIsPiVisible(true)
              }>
              <Image
                className='cc-clickable'
                style={{ objectFit: 'contain' }}
                height={311}
                src={product.image || placeholderImg}
                preview={false}
              />

              <Button
                className='cc-fz-14 pv-product-card-cover-btn'
                type='primary'>
                {t('addImageBtn')}
              </Button>
            </div>
          }
          actions={[
            <div
              className='cc-center-items'
              onClick={() =>
                isNaN(product.id)
                  ? message.warn(t('saveProductWarn'))
                  : setIsPdVisible(true)
              }>
              <Icon className='cc-sc cc-fz-20 pv-mr-5' component={SliderSvg} />
              <Typography.Text className='cc-sc cc-fz-12 cc-fw-500'>
                {t('ProductDetailsBtn')}
              </Typography.Text>
            </div>,
            <Dropdown
              trigger={['click']}
              disabled={isNaN(product.id)}
              overlay={
                <Menu>
                  <Menu.Item key='1'>
                    <div
                      className='pv-align-center'
                      onClick={() =>
                        product.image
                          ? triggerShowStatus(product.id, productIdx)
                          : message.warn(t('addPicWarn'))
                      }>
                      <Icon
                        className='cc-sc cc-fz-16 pv-mr-5'
                        component={product.showStatus ? HideSvg : ShowSvg}
                      />
                      <Typography.Text className='cc-sc cc-fz-12 cc-fw-500'>
                        {product.showStatus
                          ? t('hideFromStore')
                          : t('showInStore')}
                      </Typography.Text>
                    </div>
                  </Menu.Item>

                  <Menu.Divider key='md-1' />

                  <Menu.Item key='2'>
                    <div className='pv-align-center'>
                      <Icon
                        className='cc-fz-18 pv-rc pv-mr-5'
                        component={DeleteSvg}
                      />
                      <Typography.Text className='cc-fz-12 cc-fw-500 pv-rc'>
                        {t('deleteProductTxt')}
                      </Typography.Text>
                    </div>
                  </Menu.Item>
                </Menu>
              }>
              <div
                className='cc-center-items'
                onClick={
                  isNaN(product.id) &&
                  (() => message.warn(t('saveProductWarn')))
                }>
                <Typography.Text className='cc-sc cc-fz-12 cc-fw-500 pv-mr-5'>
                  {t('moreBtn')}
                </Typography.Text>
                <Icon className='cc-sc cc-fz-16' component={ArrowDownSvg} />
              </div>
            </Dropdown>,
          ]}>
          <div className='pv-product-form-body'>
            <Form.Item name='id' noStyle>
              <Input hidden />
            </Form.Item>

            <Form.Item name='type' noStyle>
              <Input hidden />
            </Form.Item>

            <Form.Item
              className='pv-product-form-item'
              name='name'
              help=''
              rules={[
                { required: true },
                {
                  type: 'object',
                  required: true,
                  fields: {
                    ar: { type: 'string', required: true },
                    en: { type: 'string', required: true },
                  },
                  message: 'Please enter a value in all languages',
                },
              ]}>
              <MultiLingualInput
                type='text'
                placeholder={t('productNamePlaceholder')}
                addonBefore={
                  <Tooltip title={getProductToolTip(product.type, t)}>
                    <Icon
                      className='cc-sc cc-fz-20'
                      component={getProductIcon(product.type, t)}
                    />
                  </Tooltip>
                }
                addonAfter={
                  <Select
                    className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                    defaultValue='en'
                    value={inputsLang?.code}
                    onChange={(code) =>
                      setInputsLang(langs.find((lang) => lang.code === code))
                    }>
                    {langs?.map((lang) => (
                      <Option
                        className='cc-fz-14'
                        key={lang.code}
                        value={lang.code}>
                        {lang.code.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                }
              />
            </Form.Item>

            <Form.Item
              className='pv-product-form-item'
              name='price'
              help=''
              rules={[{ required: true }]}>
              <Input
                type='number'
                placeholder={t('productPricePlaceholder')}
                addonBefore={
                  <Icon className='cc-sc cc-fz-20' component={PriceTagSvg} />
                }
                addonAfter={
                  <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
                    AED
                  </Typography.Text>
                }
              />
            </Form.Item>

            <Form.Item
              className='pv-product-form-item'
              name='quantity'
              help=''
              normalize={(value) => (value < 0 ? 0 : value)}
              rules={[{ required: true }]}>
              <Input
                type={
                  product.quantity === t('unlimitedQuantity')
                    ? 'text'
                    : 'number'
                }
                placeholder='Enter product Quantity'
                disabled={product.quantity === t('unlimitedQuantity')}
                addonBefore={
                  <Icon className='cc-sc cc-fz-20' component={BoxesSvg} />
                }
                addonAfter={
                  <div className='cc-center-items'>
                    <Icon
                      className={`cc-clickable ${
                        product.quantity === t('unlimitedQuantity')
                          ? 'cc-sc'
                          : 'cc-gc'
                      } cc-fz-24`}
                      component={InfinitySvg}
                      onClick={() => triggerQuantity(productIdx)}
                    />

                    {['READYTOUSE', 'SERVICE'].includes(product.type) && (
                      <>
                        <Divider className='pv-oq-divider' type='vertical' />
                        <Button
                          className='cc-sc cc-fz-12 cc-fw-500'
                          style={{ padding: 0 }}
                          size='small'
                          type='link'
                          onClick={() =>
                            isNaN(product.id)
                              ? message.warn(t('saveProductWarn'))
                              : setIsOqVisible(true)
                          }>
                          {t('optionsAndQtyBtn')}
                        </Button>
                      </>
                    )}
                  </div>
                }
              />
            </Form.Item>

            <Row gutter={10}>
              <Col span={16}>
                <Form.Item name='categories' noStyle>
                  <TreeSelect
                    className='cc-w-100'
                    showSearch
                    showArrow
                    multiple
                    treeDefaultExpandAll
                    maxTagCount={2}
                    maxTagPlaceholder={(ov) => `+${ov.length}`}
                    placeholder={t('categoriesPlaceholder')}
                    tagRender={(option) => (
                      <Tag
                        className='pv-select-tag'
                        key={option.value}
                        color='#5B91FB'
                        onClose={option.onClose}
                        closable>
                        {option.label}
                      </Tag>
                    )}>
                    {categories.map(CategoryTreeNode)}
                  </TreeSelect>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Button
                  className='cc-sc cc-fz-12 cc-fw-500 cc-w-100 pv-add-btn'
                  onClick={() => setIsRcVisible(true)}>
                  {t('addCategoryBtn')}
                </Button>
              </Col>
            </Row>
          </div>
        </Card>

        <Button
          className='cc-w-100 pv-save-btn'
          type='primary'
          htmlType='submit'
          loading={saveLoading}>
          {t('saveBtn')}
        </Button>
      </Form>

      <Modal
        visible={isPdVisible}
        onCancel={() => setIsPdVisible(false)}
        width='70%'
        style={{ top: '3%' }}
        footer={null}
        destroyOnClose>
        <ProductDetails hideModal={() => setIsPdVisible(false)} />
      </Modal>

      <Modal
        visible={isOqVisible}
        onCancel={() => setIsOqVisible(false)}
        width='70%'
        style={{ top: '3%' }}
        footer={null}
        destroyOnClose>
        <OptionsAndQty hideModal={() => setIsOqVisible(false)} />
      </Modal>

      <Modal
        className='pv-modal-with-header'
        visible={isPiVisible}
        onCancel={() => setIsPiVisible(false)}
        width='70%'
        style={{ top: '3%' }}
        footer={null}
        title={
          <Typography.Text className='cc-pc cc-fz-18 cc-fw-600'>
            {t('addImgsModalTitle')}
          </Typography.Text>
        }
        destroyOnClose>
        <ProductImages hideModal={() => setIsPiVisible(false)} />
      </Modal>

      <Modal
        className='pv-modal-with-header'
        visible={isRcVisible}
        onCancel={() => setIsRcVisible(false)}
        width='70%'
        footer={null}
        title={
          <Typography.Text className='cc-pc cc-fz-18 cc-fw-600'>
            Request New Category
          </Typography.Text>
        }
        destroyOnClose>
        <RequestCategory hideModal={() => setIsRcVisible(false)} />
      </Modal>
    </>
  );
}
