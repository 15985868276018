export default function OrdersSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M21.5039 19.791L20.4323 6.28584C20.3657 5.4157 19.6296 4.73438 18.7571 4.73438H16.64V7.87111C16.64 8.25956 16.3256 8.574 15.9371 8.574C15.5492 8.574 15.2342 8.25956 15.2342 7.87111V4.73438H8.7678V7.87111C8.7678 8.25956 8.45289 8.574 8.06491 8.574C7.67645 8.574 7.36201 8.25956 7.36201 7.87111V4.73438H5.24495C4.37247 4.73438 3.6363 5.4157 3.56978 6.28397L2.49765 19.7928C2.41473 20.8748 2.79006 21.9516 3.52712 22.7473C4.26423 23.5429 5.30917 23.9998 6.39395 23.9998H17.6081C18.6929 23.9998 19.7379 23.5429 20.475 22.7472C21.212 21.9516 21.5874 20.8748 21.5039 19.791ZM15.1677 12.9135L11.5324 16.5488C11.3951 16.6861 11.2152 16.755 11.0353 16.755C10.8553 16.755 10.6754 16.6861 10.5381 16.5488L8.83436 14.845C8.55976 14.5704 8.55976 14.1257 8.83436 13.8511C9.10895 13.5765 9.55412 13.5765 9.82825 13.8511L11.0353 15.0578L14.1739 11.9192C14.448 11.6451 14.8932 11.6451 15.1678 11.9192C15.4423 12.1938 15.4423 12.6389 15.1677 12.9135Z' />
      <path d='M12.0003 0C9.4423 0 7.36133 2.08144 7.36133 4.63945V4.73456H8.76711V4.63945C8.76711 2.85647 10.2174 1.40573 12.0004 1.40573C13.7833 1.40573 15.2336 2.85647 15.2336 4.63945V4.73456H16.6393V4.63945C16.6393 2.08144 14.5583 0 12.0003 0Z' />
    </svg>
  );
}
