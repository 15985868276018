import { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table, Typography } from 'antd';
import Icon from '@ant-design/icons';

import SearchSvg from 'assets/jsx-svgs/search.svg';
import WaitingSvg from 'assets/jsx-svgs/waiting.svg';
import UnderReviewSvg from 'assets/jsx-svgs/under-review.svg';
import ProcessingSvg from 'assets/jsx-svgs/processing.svg';
import DoneSvg from 'assets/jsx-svgs/done.svg';

import UnderlinedTitle from 'components/partials/UnderlinedTitle';

import columns from './table.columns';
import OrdersContext from '../OrdersContext';
import './style.css';
import { useHistory } from 'react-router';

export default function OrdersView() {
  const [tableLoading, setTableLoading] = useState(true);

  const history = useHistory();
  const { orders } = useContext(OrdersContext);

  const onSearchChange = (e) => {
    console.log(`search value:`, e.target.value);
  };

  useEffect(() => {
    if (orders && orders.length > 0) setTableLoading(false);
  }, [orders]);

  return (
    <>
      <Row className='cc-mb-15' justify='space-between'>
        <Col>
          <UnderlinedTitle level={3}>Orders</UnderlinedTitle>
        </Col>

        <Col>
          <Row gutter={15}>
            <Col>
              <Button className='cc-fz-14 cc-fw-500' type='primary'>
                + Add new Order
              </Button>
            </Col>

            <Col>
              <Input
                className='ov-search'
                type='text'
                placeholder='Search for products'
                suffix={
                  <Icon
                    className='cc-sc cc-fz-22 ov-search-icon'
                    component={SearchSvg}
                  />
                }
                onChange={onSearchChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='cc-mb-25' gutter={20}>
        <Col>
          <div className='ov-card'>
            <Icon className='cc-mb-5' component={WaitingSvg} />

            <Typography className='cc-gc cc-fz-12 cc-fw-600'>
              Waiting Payment
            </Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600'>22</Typography>
          </div>
        </Col>

        <Col>
          <div className='ov-card'>
            <Icon className='cc-mb-5' component={UnderReviewSvg} />

            <Typography className='cc-gc cc-fz-12 cc-fw-600'>
              Under Review
            </Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600'>15</Typography>
          </div>
        </Col>

        <Col>
          <div className='ov-card'>
            <Typography className='cc-gc cc-fz-12 cc-fw-600'>
              Waiting to complete payment
            </Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600'>15</Typography>
          </div>
        </Col>

        <Col>
          <div className='ov-card'>
            <Icon className='cc-mb-5' component={ProcessingSvg} />

            <Typography className='cc-gc cc-fz-12 cc-fw-600'>
              Processing
            </Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600'>15</Typography>
          </div>
        </Col>

        <Col>
          <div className='ov-card'>
            <Icon className='cc-mb-5' component={DoneSvg} />

            <Typography className='cc-gc cc-fz-12 cc-fw-600'>Done</Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600'>32</Typography>
          </div>
        </Col>
      </Row>

      <Table
        className='ov-table'
        columns={columns(5045)}
        dataSource={orders}
        rowKey={(record) => record.id}
        loading={tableLoading}
        pagination
        onRow={(record) => ({
          onClick: () => history.push(`/orders/${record.id}`),
        })}
        rowClassName={() => 'cc-clickable'}
        bordered
      />
    </>
  );
}
