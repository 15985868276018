export default function ShowSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_4359_4523)'>
        <path d='M23.6889 11.1593C23.4781 10.9107 18.4403 5.08008 12.0002 5.08008C5.55978 5.08008 0.522167 10.9107 0.310875 11.1595C-0.103666 11.6469 -0.103666 12.3542 0.311119 12.8421C0.522167 13.0906 5.55978 18.921 12.0002 18.921C18.4403 18.921 23.4781 13.0902 23.6892 12.8416C24.1037 12.3542 24.1037 11.6469 23.6889 11.1593ZM13.9673 8.5826C14.4004 8.3462 15.0043 8.61745 15.316 9.1882C15.6277 9.7592 15.5292 10.4138 15.0962 10.6502C14.6634 10.8868 14.0592 10.6153 13.7473 10.0446C13.4358 9.47358 13.534 8.81899 13.9673 8.5826ZM12.0002 16.8086C7.50137 16.8086 3.71542 13.3536 2.40503 12.0005C3.28992 11.0867 5.30462 9.21525 7.9047 8.09616C7.39755 8.86773 7.10023 9.78966 7.10023 10.782C7.10023 13.4881 9.29382 15.6817 11.9999 15.6817C14.706 15.6817 16.8998 13.4881 16.8998 10.782C16.8998 9.78966 16.6028 8.86773 16.0956 8.09616C18.6957 9.2155 20.7101 11.0867 21.595 12.0005C20.2846 13.3543 16.4987 16.8086 12.0002 16.8086Z' />
      </g>
      <defs>
        <clipPath id='clip0_4359_4523'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
