export default function UnderReviewSvg() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#73AF00' fillOpacity='0.15' />
      <g clipPath='url(#clip0_5504_7327)'>
        <path
          d='M17.6155 22.5739C17.4835 22.6068 17.349 22.636 17.2155 22.6606C16.8665 22.7255 16.6357 23.0612 16.7003 23.4105C16.7322 23.5824 16.8298 23.7255 16.9623 23.819C17.0989 23.9151 17.2727 23.9585 17.4498 23.9255C17.6088 23.896 17.7691 23.8613 17.9263 23.8221C18.271 23.7363 18.481 23.3871 18.395 23.0427C18.3092 22.6979 17.9603 22.4881 17.6155 22.5739Z'
          fill='#73AF00'
        />
        <path
          d='M22.3732 13.9506C22.4182 14.0863 22.5043 14.1972 22.6129 14.2738C22.774 14.3872 22.9848 14.4254 23.186 14.3589C23.5233 14.2469 23.706 13.8832 23.5944 13.5462C23.5436 13.3925 23.4872 13.2383 23.4271 13.0882C23.2952 12.7584 22.921 12.5978 22.591 12.7297C22.2613 12.8616 22.1007 13.2359 22.2327 13.5658C22.2832 13.6919 22.3305 13.8214 22.3732 13.9506Z'
          fill='#73AF00'
        />
        <path
          d='M19.7066 21.6555C19.5931 21.7304 19.476 21.8029 19.358 21.8708C19.0502 22.0484 18.9448 22.4418 19.1223 22.7495C19.1705 22.8332 19.2347 22.9016 19.3086 22.9538C19.507 23.0934 19.7765 23.1144 20.0008 22.9851C20.1411 22.9042 20.2806 22.8182 20.4157 22.7288C20.712 22.5331 20.7935 22.134 20.5977 21.8376C20.4019 21.5411 20.003 21.4596 19.7066 21.6555Z'
          fill='#73AF00'
        />
        <path
          d='M23.9939 15.7488C23.9799 15.3938 23.6809 15.1175 23.3258 15.1314C22.9711 15.1454 22.6946 15.4445 22.7085 15.7994C22.7138 15.935 22.7152 16.0728 22.7121 16.2083C22.7071 16.4309 22.816 16.6294 22.9852 16.7487C23.086 16.8197 23.2084 16.8626 23.341 16.8656C23.696 16.8735 23.9902 16.5919 23.9981 16.2367C24.0016 16.0748 24.0003 15.9107 23.9939 15.7488Z'
          fill='#73AF00'
        />
        <path
          d='M22.2677 19.9629C21.983 19.7493 21.5803 19.8073 21.367 20.0914C21.2854 20.2003 21.1994 20.3077 21.1114 20.4112C20.8813 20.6816 20.9138 21.0876 21.1841 21.3179C21.1995 21.331 21.2151 21.343 21.2313 21.3542C21.5 21.5437 21.8735 21.5002 22.0908 21.2452C22.196 21.1217 22.2985 20.9933 22.396 20.8633C22.6093 20.5792 22.5516 20.1761 22.2677 19.9629Z'
          fill='#73AF00'
        />
        <path
          d='M23.2132 17.639C22.8743 17.5327 22.5133 17.7214 22.4071 18.0604C22.3664 18.1899 22.3214 18.32 22.2728 18.4474C22.166 18.7279 22.2683 19.0366 22.5028 19.202C22.5459 19.2322 22.5933 19.2578 22.6448 19.2773C22.9766 19.4039 23.3483 19.2374 23.4748 18.9054C23.5325 18.7539 23.5861 18.5991 23.6346 18.4452C23.7407 18.1061 23.5521 17.7453 23.2132 17.639Z'
          fill='#73AF00'
        />
        <path
          d='M14.8099 22.6661C14.2349 22.5629 13.6832 22.3871 13.1601 22.1418C13.1539 22.1386 13.1484 22.1349 13.1419 22.132C13.0186 22.0739 12.8956 22.0118 12.7763 21.9469C12.7759 21.9464 12.7751 21.9461 12.7745 21.9458C12.5556 21.8253 12.342 21.6919 12.1345 21.5459C9.10884 19.4145 8.38135 15.2188 10.5129 12.1932C10.9764 11.5355 11.5373 10.9868 12.1626 10.552C12.1703 10.5466 12.178 10.5413 12.1856 10.5359C14.3891 9.01757 17.3811 8.91525 19.7209 10.4754L19.2184 11.2015C19.0787 11.4036 19.1646 11.5509 19.4092 11.5289L21.5921 11.3334C21.837 11.3114 21.9834 11.0996 21.9175 10.8631L21.3314 8.75112C21.2657 8.51436 21.0977 8.48602 20.9579 8.68808L20.4542 9.41589C18.7371 8.26325 16.6776 7.82373 14.6335 8.17819C14.4276 8.21382 14.2246 8.25744 14.0244 8.3082C14.0229 8.30848 14.0217 8.30865 14.0204 8.30892C14.0127 8.31082 14.0048 8.31333 13.9973 8.31542C12.2346 8.76808 10.6967 9.79615 9.5993 11.263C9.59005 11.2739 9.58052 11.2847 9.57178 11.2966C9.53529 11.3458 9.49908 11.396 9.46362 11.4463C9.40563 11.5287 9.34847 11.6132 9.29378 11.6977C9.28693 11.7079 9.28171 11.7182 9.27572 11.7285C8.37013 13.1318 7.93333 14.7542 8.00824 16.4058C8.00841 16.4112 8.0081 16.4167 8.00824 16.4223C8.0155 16.5836 8.02829 16.7472 8.04566 16.9082C8.04659 16.9186 8.04889 16.9285 8.05065 16.9388C8.0686 17.1008 8.09113 17.2631 8.11937 17.4253C8.40635 19.081 9.18736 20.5708 10.3577 21.7301C10.3604 21.7328 10.3632 21.7358 10.366 21.7386C10.3669 21.7396 10.368 21.7402 10.3689 21.7412C10.6834 22.0513 11.0254 22.3382 11.3936 22.5975C12.3573 23.2766 13.4305 23.7255 14.5829 23.9323C14.9326 23.9951 15.2666 23.7623 15.3294 23.4128C15.3921 23.0631 15.1595 22.7288 14.8099 22.6661Z'
          fill='#73AF00'
        />
        <path
          d='M15.6056 10.8613C15.3179 10.8613 15.085 11.0945 15.085 11.3817V16.5665L19.8268 19.0178C19.9032 19.0573 19.9849 19.0759 20.0653 19.0759C20.2536 19.0759 20.4355 18.9733 20.528 18.7944C20.6599 18.539 20.5601 18.2252 20.3047 18.0933L16.1256 15.9328V11.3817C16.1256 11.0945 15.8929 10.8613 15.6056 10.8613Z'
          fill='#73AF00'
        />
      </g>
      <defs>
        <clipPath id='clip0_5504_7327'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
