import { useContext } from 'react';
import { Button, Col, Row, Form, Typography, Input, Select } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import MultiLingualInput from 'components/MultiLingualInput';
import LargeTextSvg from 'assets/jsx-svgs/large-text.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import RoundedDollarSignSvg from 'assets/jsx-svgs/rounded-dollar-sign.svg';

const { Option } = Select;

export default function OptionsList({ name }) {
  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { t } = useTranslation(['products']);

  return (
    <Form.List name={[name, 'options']}>
      {(fields, { add, remove }) => (
        <Row className='pv-modal-choices-container cc-mb-20'>
          <Col span={24} className='cc-mb-10'>
            <Typography className='cc-pc cc-fz-14 cc-fw-600'>
              {t('fieldChoicesTitle')}
            </Typography>
          </Col>

          {fields.map((field) => (
            <Col className='cc-mb-15' key={field.name} span={24}>
              <Row justify='space-between' align='middle'>
                <Col span={14}>
                  <Form.Item
                    name={[field.name, 'name']}
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        fields: {
                          ar: { type: 'string', required: true },
                          en: { type: 'string', required: true },
                        },
                        message: 'Please enter a value in all languages',
                      },
                    ]}
                    noStyle>
                    <MultiLingualInput
                      type='text'
                      placeholder={t('optionNamePlaceholder')}
                      prefix={
                        <Icon
                          className='cc-gc pv-mr-5'
                          component={LargeTextSvg}
                        />
                      }
                      addonAfter={
                        <Select
                          className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                          defaultValue='en'
                          value={inputsLang?.code}
                          onChange={(code) =>
                            setInputsLang(
                              langs.find((lang) => lang.code === code),
                            )
                          }>
                          {langs?.map((lang) => (
                            <Option
                              className='cc-fz-14'
                              key={lang.code}
                              value={lang.code}>
                              {lang.code.toUpperCase()}
                            </Option>
                          ))}
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={[field.name, 'price']} noStyle>
                    <Input
                      type='number'
                      placeholder={t('optionPricePlaceholder')}
                      prefix={
                        <Icon
                          className='cc-gc pv-mr-5'
                          component={RoundedDollarSignSvg}
                        />
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={1}>
                  <Icon
                    className='cc-fz-24 pv-rc'
                    component={DeleteSvg}
                    onClick={() => remove(field.name)}
                  />
                </Col>
              </Row>
            </Col>
          ))}

          <Col span={24}>
            <Button
              className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn-2'
              type='primary'
              onClick={() => add()}>
              {t('addNewValueBtn')}
            </Button>
          </Col>
        </Row>
      )}
    </Form.List>
  );
}
