import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  Progress,
  Row,
  Typography,
  Upload,
} from 'antd';
import Icon from '@ant-design/icons';

import UserContext from 'contexts/UserContext';
import addProductsSvg from 'assets/svgs/add-products.svg';
import manageDimensionSvg from 'assets/svgs/manage-dimension.svg';
import manageOrdersSvg from 'assets/svgs/manage-orders.svg';
import getStartedSvg from 'assets/svgs/get-started.svg';
import DummyImgSvg from 'assets/jsx-svgs/dummy-img.svg';
import ArrowRightSvg from 'assets/jsx-svgs/arrow-right.svg';
import img1 from 'assets/temp/1.png';

import './style.css';

const { Dragger } = Upload;

export default function HomeView() {
  const { user, setUser } = useContext(UserContext);

  const switchUserBusiness = (business) => {
    setUser({ ...user, selectedBusiness: business });
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify='space-between'>
          <Col>
            <Typography className='cc-sc cc-fz-24 cc-fw-600 cc-mb-10'>
              Welcome To Your Dashboard!
            </Typography>
          </Col>

          <Col>
            <Button className='cc-fz-14 cc-fw-500' type='primary'>
              View as a visitor
            </Button>
          </Col>
        </Row>
      </Col>

      {user?.businesses?.length ? (
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600 cc-mb-20'>
            Switch between your businesses
          </Typography>

          <Row gutter={20}>
            {user.businesses.map((business, idx) => (
              <Col key={`${business.handle}-${idx}`} span={4}>
                <div
                  className={`hv-business-card ${
                    business.id === user.selectedBusiness.id
                      ? 'hv-business-card-selected'
                      : 'cc-clickable hv-business-card-not-selected'
                  }`}
                  onClick={() => switchUserBusiness(business)}>
                  <Image
                    className='cc-mb-5'
                    src={img1}
                    width='100%'
                    preview={false}
                  />

                  <Typography className='cc-sc cc-fz-18 cc-fw-600'>
                    {business.name}
                  </Typography>

                  <Typography.Text className='cc-gc cc-fw-500'>
                    {business.handle}
                  </Typography.Text>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      ) : null}

      <Divider />

      <Col span={24}>
        <Typography className='cc-sc cc-fz-24 cc-fw-600 cc-mb-20'>
          Complete your business profile.
        </Typography>

        <Col span={14}>
          <Row gutter={20}>
            <Col
              span={8}
              className='cc-dragger-container cc-dragger-container-h-100'>
              <Dragger
                className='cc-dragger'
                listType='picture'
                maxCount={1}
                customRequest={({ onSuccess }) => onSuccess('ok')}>
                <div className='cc-dragger-icon'>
                  <Icon component={DummyImgSvg} />
                </div>
                <div className='cc-fw-500'>
                  <Typography className='cc-sc'>
                    Drop your image here
                  </Typography>
                  <Typography className='cc-pc'>or browse</Typography>
                </div>
              </Dragger>
            </Col>

            <Col span={16}>
              <Input.TextArea
                className='cc-custom-input'
                autoSize={{ minRows: 5, maxRows: 5 }}
                placeholder='Add your bio (150 characters)'
              />
            </Col>
          </Row>
        </Col>
      </Col>

      <Divider />

      <Col span={24}>
        <Typography className='cc-sc cc-fz-16 cc-fw-500 cc-mb-10'>
          There Are Only 3 Steps To Launching Your Business
        </Typography>

        <Row gutter={10} className='cc-mb-25'>
          <Col>
            <Typography className='cc-sc cc-fz-16 cc-fw-500'>
              2 Steps left
            </Typography>
          </Col>

          <Col span={2}>
            <Progress
              showInfo={false}
              trailColor='rgba(151, 151, 151, 0.3)'
              percent={30}
            />
          </Col>
        </Row>

        <Row gutter={20} className='cc-mb-25'>
          <Col span={8}>
            <div className='hv-card'>
              <div>
                <Image src={addProductsSvg} height={90} preview={false} />

                <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-15 hv-mt-25'>
                  Add your Products
                </Typography>
              </div>

              <div>
                <Typography.Paragraph className='cc-gc cc-fw-500'>
                  Showcase your products and services to customers.
                </Typography.Paragraph>

                <Typography.Paragraph className='cc-gc cc-fw-500 cc-mb-15'>
                  Add products one by one or using bulk import from an Excel
                  sheet.
                </Typography.Paragraph>
              </div>

              <Link
                className='cc-fz-14 cc-fw-600 hv-card-footer'
                to='/products'>
                Add your Products{' '}
                <span className='hv-fz-20 hv-ml-10'>
                  <ArrowRightSvg />
                </span>
              </Link>
            </div>
          </Col>

          <Col span={8}>
            <div className='hv-card'>
              <div>
                <Image src={manageDimensionSvg} height={90} preview={false} />

                <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-15 hv-mt-25'>
                  Manage Your Dimension
                </Typography>
              </div>

              <div>
                <Typography.Paragraph className='cc-gc cc-fw-500'>
                  Get a free domain for your online store when you subscribe to
                  one of our paid plans.
                </Typography.Paragraph>

                <Typography.Paragraph className='cc-gc cc-fw-500 cc-mb-15'>
                  Pick your domain and start selling
                </Typography.Paragraph>
              </div>

              <Link
                className='cc-fz-14 cc-fw-600 hv-card-footer'
                to='/manage-dimensions'>
                Manage Your Dimension{' '}
                <span className='hv-fz-20 hv-ml-10'>
                  <ArrowRightSvg />
                </span>
              </Link>
            </div>
          </Col>

          <Col span={8}>
            <div className='hv-card'>
              <div>
                <Image src={manageOrdersSvg} height={90} preview={false} />

                <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-15 hv-mt-25'>
                  Manage your Orders
                </Typography>
              </div>

              <div>
                <Typography.Paragraph className='cc-gc cc-fw-500'>
                  Choose one of our professionally designed, responsive
                  templates.
                </Typography.Paragraph>

                <Typography.Paragraph className='cc-gc cc-fw-500 cc-mb-15'>
                  Adding your logo, colors, and images that reflect your brand.
                </Typography.Paragraph>
              </div>

              <Link className='cc-fz-14 cc-fw-600 hv-card-footer' to='/orders'>
                Track your Orders{' '}
                <span className='hv-fz-20 hv-ml-10'>
                  <ArrowRightSvg />
                </span>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className='hv-card-2' gutter={20}>
          <Col span={8}>
            <div className='cc-center-items hv-card-2-left'>
              <Image src={getStartedSvg} height={73} preview={false} />
            </div>
          </Col>

          <Col span={16}>
            <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-20'>
              Get Started On PiPhi
            </Typography>

            <Typography className='cc-sc cc-fz-14 cc-fw-600 cc-mb-20'>
              Free training to help you get store setup and selling in minutes,
              with through a group of videos you will able to build and launch
              your store.
            </Typography>

            <Button className='cc-fz-14 cc-fw-500' type='primary'>
              Get Started
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
