import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Typography,
  Image,
  Row,
  Col,
  message,
  Select,
  Skeleton,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { dashboardIstance, commonInstance } from 'api/axios';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import signupFg from 'assets/images/signup-fg.png';
import signupBg from 'assets/images/signup-bg.png';
import logo from 'assets/piphi_logo_nobg.png';

import './style.css';

const { Option } = Select;

export default function SignupView() {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { setUser } = useContext(UserContext);
  const {
    forms: { setLangs, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { t } = useTranslation(['signup', 'createBusiness', 'common']);

  const onFinish = async (values) => {
    setLoading(true);
    const { confirmPassword, ...data } = values;

    try {
      const {
        data: { data: user },
      } = await dashboardIstance.post('/auth/signup', data);

      const {
        data: { data: langs },
      } = await commonInstance.get('/language-list');

      setUser(user);
      setLangs(langs);
      setInputsLang(langs?.find((lang) => lang.code === 'ar') || langs[0]);
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { data: _countries },
      } = await commonInstance.get('/country-list');

      const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

      const countries = _countries.map((country) => ({
        ...country,
        displayName: regionNames.of(country.alpha2Code),
      }));

      setCountries(countries);
    })();
  }, []);

  return (
    <Row className='sv' gutter={0}>
      <Col className='sv-left' span={16}>
        <Row justify='space-between'>
          <Col span={2}>
            <Image
              className='sv-mb-45'
              src={logo}
              preview={false}
              width='100%'
            />
          </Col>

          <Col span={21}>
            <Row>
              <Col span={24}>
                <Typography.Title className='cc-pc'>
                  {t('title')}
                </Typography.Title>
              </Col>

              <Col span={24}>
                <Typography.Paragraph className='cc-gc sv-mb-55'>
                  {t('subTitle')}
                </Typography.Paragraph>
              </Col>

              <Col span={24}>
                <Form
                  name='signup-form'
                  onFinish={onFinish}
                  layout='vertical'
                  requiredMark={false}>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='firstName'
                        label={t('firstNameLabel')}
                        rules={[{ required: true, min: 2, max: 50 }]}>
                        <Input
                          size='large'
                          placeholder={t('firstNamePlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='lastName'
                        label={t('lastNameLabel')}
                        rules={[{ required: true, min: 2, max: 50 }]}>
                        <Input
                          size='large'
                          placeholder={t('lastNamePlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='email'
                        label={t('emailLabel')}
                        rules={[{ type: 'email', required: true }]}>
                        <Input
                          size='large'
                          placeholder={t('emailPlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='handle'
                        label={t('handleLabel')}
                        rules={[{ required: true, min: 3, max: 50 }]}>
                        <Input
                          size='large'
                          placeholder={t('handlePlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='password'
                        label={t('passwordLabel')}
                        rules={[
                          { required: true },
                          {
                            min: 6,
                            max: 50,
                            message:
                              'Password must be between 8 and 20 characters',
                          },
                        ]}
                        hasFeedback>
                        <Input
                          size='large'
                          type='password'
                          placeholder={t('passwordPlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className='cc-item-with-mh'
                        name='confirmPassword'
                        label={t('confirmPasswordLabel')}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value)
                                return Promise.resolve();

                              return Promise.reject(
                                new Error(
                                  'The two passwords that you do not match!',
                                ),
                              );
                            },
                          }),
                        ]}
                        dependencies={['password']}
                        hasFeedback>
                        <Input
                          size='large'
                          type='password'
                          placeholder={t('confirmPasswordPlaceholder')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className='sv-mb-55'
                        name='countryAlpha2Code'
                        label={t('countryLabel', { ns: 'createBusiness' })}
                        rules={[{ required: true }]}>
                        <Select
                          showSearch
                          showArrow
                          notFoundContent={!countries?.length && <Skeleton />}
                          placeholder={t('countryPlaceholder', {
                            ns: 'createBusiness',
                          })}
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            option.name
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }>
                          {countries.map((country) => (
                            <Option
                              name={country.displayName}
                              key={country.alpha2Code}
                              value={country.alpha2Code}>
                              <Image
                                src={`https://flagcdn.com/20x15/${country?.alpha2Code?.toLowerCase()}.png`}
                              />{' '}
                              {country.displayName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item className='cc-mb-5'>
                        <Button
                          className='cc-w-100'
                          type='primary'
                          htmlType='submit'
                          size='large'
                          loading={loading}>
                          {t('submitBtn')}
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        {t('loginTxt')}{' '}
                        <Link className='cc-fw-500' to='/login'>
                          {t('signupLink')}
                        </Link>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col className='cc-center-items sv-right' span={8}>
        <Image src={signupFg} width='100%' preview={false} />
        <div className='sv-bg-box'>
          <Image src={signupBg} width='100%' preview={false} />
        </div>
      </Col>
    </Row>
  );
}
