export default function CouponsSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 23'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M9.1875 9.77952C9.57583 9.77952 9.89062 9.48894 9.89062 9.13048C9.89062 8.77203 9.57583 8.48145 9.1875 8.48145C8.79917 8.48145 8.48438 8.77203 8.48438 9.13048C8.48438 9.48894 8.79917 9.77952 9.1875 9.77952Z' />
      <path d='M14.8125 13.6731C15.2008 13.6731 15.5156 13.3825 15.5156 13.024C15.5156 12.6656 15.2008 12.375 14.8125 12.375C14.4242 12.375 14.1094 12.6656 14.1094 13.024C14.1094 13.3825 14.4242 13.6731 14.8125 13.6731Z' />
      <path d='M21.8906 4.58691H2.10938C0.946219 4.58691 0 5.46035 0 6.53403V9.13018C0 9.48893 0.314484 9.77922 0.703125 9.77922H1.40625C2.18147 9.77922 2.8125 10.3617 2.8125 11.0773C2.8125 11.7929 2.18147 12.3754 1.40625 12.3754H0.703125C0.314484 12.3754 0 12.6657 0 13.0244V15.6206C0 16.6942 0.946219 17.5677 2.10938 17.5677H21.8906C23.0538 17.5677 24 16.6942 24 15.6206V13.0244C24 12.6657 23.6855 12.3754 23.2969 12.3754H22.5938C21.8185 12.3754 21.1875 11.7929 21.1875 11.0773C21.1875 10.3617 21.8185 9.77922 22.5938 9.77922H23.2969C23.6855 9.77922 24 9.48893 24 9.13018V6.53403C24 5.46035 23.0538 4.58691 21.8906 4.58691ZM7.07812 9.13018C7.07812 8.0565 8.02434 7.18307 9.1875 7.18307C10.3507 7.18307 11.2969 8.0565 11.2969 9.13018C11.2969 10.2039 10.3507 11.0773 9.1875 11.0773C8.02434 11.0773 7.07812 10.2039 7.07812 9.13018ZM8.748 14.8296C8.44523 14.6052 8.39578 14.197 8.63817 13.9168L14.2632 7.42646C14.5069 7.14819 14.9484 7.10129 15.2519 7.32503C15.5548 7.54943 15.6042 7.95759 15.3618 8.23775L9.73678 14.7281C9.49205 15.0086 9.05025 15.0524 8.748 14.8296ZM14.8125 14.9715C13.6493 14.9715 12.7031 14.0981 12.7031 13.0244C12.7031 11.9507 13.6493 11.0773 14.8125 11.0773C15.9757 11.0773 16.9219 11.9507 16.9219 13.0244C16.9219 14.0981 15.9757 14.9715 14.8125 14.9715Z' />
    </svg>
  );
}
