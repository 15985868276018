export default function CarSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_3844:2983)'>
        <path d='M23.3813 17.618C23.4446 17.2927 23.4238 16.9225 23.3168 16.5372C22.6154 14.0106 20.5166 8.8376 18.7725 6.70706C18.3148 6.14799 17.4332 6.19629 16.922 6.70706C16.6971 6.93206 16.5722 7.21862 16.5455 7.51248C16.4086 7.38583 16.2349 7.29839 16.0433 7.27336C16.0433 7.27336 11.6197 6.72214 11.5661 6.72915H9.55443C8.89893 6.72915 8.70709 7.10926 8.70709 7.57648V14.0342H7.2307V7.10909C7.2307 6.76649 6.95192 6.48771 6.60932 6.48771H0.621378C0.278773 6.48771 0 6.76649 0 7.10909C0 7.10909 0.00214658 16.8334 0.00214658 17.4624C0.00214658 18.1849 0.58788 18.7706 1.31037 18.7706H2.59414C2.52782 19.0407 2.49195 19.3226 2.49195 19.6129C2.49195 21.562 4.07794 23.1476 6.02731 23.1476C7.97658 23.1476 9.56245 21.562 9.56245 19.6129C9.56245 19.3226 9.52652 19.0407 9.46026 18.7706H13.4169V19.6374C13.4169 20.3226 13.9743 20.8801 14.6596 20.8801H14.6834C15.0148 20.8801 15.326 20.7502 15.5595 20.5143C15.793 20.2784 15.9197 19.966 15.9164 19.6346L15.9076 18.7706H17.0318C16.9655 19.0407 16.9296 19.3226 16.9296 19.6129C16.9296 21.562 18.5156 23.1476 20.465 23.1476C22.4142 23.1476 24.0001 21.562 24.0001 19.6129C24 18.8733 23.7712 18.1862 23.3813 17.618ZM20.4649 18.2788C21.2005 18.2788 21.7989 18.8773 21.7989 19.6129C21.7989 20.3485 21.2005 20.947 20.4649 20.947C19.729 20.947 19.1303 20.3485 19.1303 19.6129C19.1303 18.8773 19.7289 18.2788 20.4649 18.2788ZM7.3613 19.6129C7.3613 20.3485 6.76285 20.947 6.02726 20.947C5.29137 20.947 4.6927 20.3485 4.6927 19.6129C4.6927 18.8773 5.29137 18.2788 6.02726 18.2788C6.76285 18.2788 7.3613 18.8773 7.3613 19.6129ZM15.861 14.2247C15.854 13.541 15.2921 12.9848 14.6084 12.9848H12.5405V8.76213L13.5517 8.88635C13.634 9.26454 13.9702 9.54789 14.373 9.54789H17.9125L18.1868 11.8337L15.8676 14.8701L15.861 14.2247Z' />
        <path d='M10.3158 5.82368C11.6863 5.82368 12.8014 4.70864 12.8014 3.338C12.8014 3.16373 12.7831 2.99364 12.7488 2.82937L13.2942 2.81864C13.6191 2.8122 13.9693 2.58601 14.1088 2.29244L14.2917 1.90753C14.3785 1.72467 14.374 1.53374 14.2791 1.38365C14.1843 1.23367 14.0141 1.14764 13.8102 1.14764L11.4996 1.153C11.1474 0.961393 10.7441 0.852539 10.3157 0.852539C8.94511 0.852539 7.83008 1.96752 7.83008 3.33811C7.83013 4.70864 8.94517 5.82368 10.3158 5.82368Z' />
      </g>
      <defs>
        <clipPath id='clip0_3844:2983'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
