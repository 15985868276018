export default function HideSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_4359_4519)'>
        <path d='M23.8362 11.4963C22.3794 9.5877 20.6298 7.92166 18.6522 6.56002L22.0345 3.17777C22.375 2.84891 22.3844 2.3063 22.0556 1.96579C21.7267 1.62528 21.1841 1.61584 20.8436 1.9447C20.8365 1.95158 20.8294 1.95861 20.8225 1.96579L17.1274 5.65747C15.5546 4.79183 13.795 4.32119 12 4.28603C5.48577 4.28603 0.378115 11.2014 0.163814 11.4962C-0.0546047 11.7967 -0.0546047 12.2037 0.163814 12.5042C1.62058 14.4127 3.37019 16.0788 5.34776 17.4404L1.96551 20.8227C1.62499 21.1515 1.61555 21.6941 1.94441 22.0347C2.27327 22.3752 2.81588 22.3846 3.15639 22.0557C3.16352 22.0489 3.17055 22.0418 3.17748 22.0347L6.87257 18.343C8.4454 19.2086 10.205 19.6793 12 19.7144C18.5142 19.7144 23.6219 12.799 23.8362 12.5042C24.0546 12.2038 24.0546 11.7967 23.8362 11.4963ZM6.59403 16.1968C4.84794 15.0338 3.2828 13.6198 1.94923 12.0002C3.21093 10.472 7.30288 6.00029 12 6.00029C13.3364 6.02731 14.6503 6.34929 15.8477 6.94312L14.3597 8.43112C12.3909 7.12407 9.73537 7.66045 8.42832 9.62918C7.4762 11.0633 7.4762 12.9284 8.42832 14.3625L6.59403 16.1968ZM14.3143 10.8988C14.482 11.2418 14.57 11.6184 14.5714 12.0002C14.5714 13.4204 13.4202 14.5717 12 14.5717C11.6182 14.5702 11.2416 14.4823 10.8986 14.3145L14.3143 10.8988ZM9.68575 13.1016C9.518 12.7586 9.43006 12.382 9.42861 12.0002C9.42861 10.58 10.5799 9.42879 12 9.42879C12.3819 9.43025 12.7584 9.51819 13.1014 9.68593L9.68575 13.1016ZM12 18.0002C10.6636 17.9731 9.34981 17.6512 8.15235 17.0573L9.64034 15.5693C11.6091 16.8764 14.2647 16.34 15.5717 14.3713C16.5238 12.9372 16.5238 11.0721 15.5717 9.63797L17.406 7.80369C19.1521 8.96664 20.7172 10.3807 22.0508 12.0002C20.789 13.5285 16.6971 18.0002 12 18.0002Z' />
      </g>
      <defs>
        <clipPath id='clip0_4359_4519'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
