export default function GearSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M23.4672 9.91191L20.7142 9.23489C20.5488 8.71303 20.3359 8.20697 20.0791 7.72289C20.4924 7.03486 21.3611 5.58675 21.3611 5.58675C21.5273 5.31005 21.4833 4.95572 21.2553 4.72777L19.2668 2.73923C19.0388 2.51058 18.6852 2.46666 18.4078 2.63348L16.2682 3.91547C15.7868 3.6607 15.2835 3.44991 14.7652 3.2858L14.0881 0.532875C14.0098 0.21975 13.729 0 13.4062 0H10.5938C10.271 0 9.98948 0.21975 9.91191 0.532828C9.91191 0.532828 9.44292 2.45423 9.23555 3.28509C8.6828 3.46017 8.14861 3.68747 7.63978 3.96487L5.42123 2.63348C5.14383 2.46867 4.79091 2.51128 4.56225 2.73923L2.57372 4.72777C2.34577 4.95572 2.3018 5.31005 2.46797 5.58675L3.83991 7.87809C3.6188 8.31413 3.43341 8.76797 3.2858 9.23283L0.532875 9.91191C0.21975 9.99019 0 10.271 0 10.5938V13.4062C0 13.729 0.21975 14.0105 0.532828 14.0881L3.28645 14.7651C3.45333 15.2945 3.67027 15.8088 3.93328 16.3025L2.70558 18.3487C2.53941 18.6254 2.58337 18.9797 2.81133 19.2077L4.79986 21.1962C5.02716 21.4235 5.38144 21.4667 5.65884 21.302C5.65884 21.302 7.03556 20.4766 7.70986 20.0729C8.19806 20.3331 8.70891 20.548 9.23559 20.7149L9.91195 23.4671C9.98948 23.7803 10.271 24 10.5938 24H13.4062C13.729 24 14.0098 23.7803 14.0881 23.4672L14.7645 20.7149C15.3062 20.5432 15.828 20.3214 16.3245 20.0523C17.0173 20.4677 18.4078 21.302 18.4078 21.302C18.6845 21.4674 19.0388 21.4249 19.2668 21.1962L21.2553 19.2077C21.4832 18.9797 21.5272 18.6254 21.361 18.3487L20.0969 16.2428C20.3461 15.7696 20.5529 15.2746 20.7142 14.7651L23.4671 14.0881C23.7803 14.0098 24 13.729 24 13.4062V10.5938C24 10.271 23.7803 9.99019 23.4672 9.91191ZM12 16.9219C9.28636 16.9219 7.07812 14.7136 7.07812 12C7.07812 9.28636 9.28636 7.07812 12 7.07812C14.7136 7.07812 16.9219 9.28636 16.9219 12C16.9219 14.7136 14.7136 16.9219 12 16.9219Z' />
    </svg>
  );
}
