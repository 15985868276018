import { Avatar, Badge, Image, Layout, Typography } from 'antd';
import Icon from '@ant-design/icons';

import logo from 'assets/piphi_logo_nobg.png';
// import logo from 'assets/svgs/logo.svg';
import MessageSvg from 'assets/jsx-svgs/message.svg';
import BellSvg from 'assets/jsx-svgs/bell.svg';

const { Header } = Layout;

export default function NoBusinessNavBar() {
  return (
    <Header className='cc-w-100 cc-layout-nbv-navbar'>
      <div className='cc-layout-sidebar-logo'>
        <Image src={logo} width={50} preview={false} />
        <Typography className='cc-sc cc-fz-18 cc-fw-500 cc-layout-sidebar-logo-title'>
          PiPhi
        </Typography>
      </div>

      <div className='cc-center-items' style={{ height: '100%' }}>
        <Badge count={5}>
          <Icon
            className='cc-sc cc-fz-24 cc-layout-navbar-icon'
            component={MessageSvg}
          />
        </Badge>

        <Badge count={2}>
          <Icon
            className='cc-sc cc-fz-24 cc-layout-navbar-icon'
            component={BellSvg}
          />
        </Badge>

        <Avatar
          className='cc-layout-navbar-avatar'
          src={
            <Image
              src='https://joeschmoe.io/api/v1/random'
              width='100%'
              preview={false}
            />
          }
        />
      </div>
    </Header>
  );
}
