import { useContext } from 'react';
import { Modal } from 'antd';

import ChickChackConfigContext from 'contexts/ChickChackConfigContext';

export default function AddNewLangModal() {
  const {
    forms: { isVisible, setIsVisible },
  } = useContext(ChickChackConfigContext);

  return (
    <Modal
      title='Add New Language'
      visible={isVisible}
      onOk={() => setIsVisible(false)}
      onCancel={() => setIsVisible(false)}>
      <p>Add New Language</p>
    </Modal>
  );
}
