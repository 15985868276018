import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonTranslationEN from './locale/en/common.json';
import loginTranslationEN from './locale/en/login.json';
import createBusinessTranslationEN from './locale/en/create-business.json';
import noBusinessTranslationEN from './locale/en/no-business.json';
import productsTranslationEN from './locale/en/products.json';
import signupTranslationEN from './locale/en/signup.json';

import commonTranslationAR from './locale/ar/common.json';
import loginTranslationAR from './locale/ar/login.json';
import createBusinessTranslationAR from './locale/ar/create-business.json';
import noBusinessTranslationAR from './locale/ar/no-business.json';
import productsTranslationAR from './locale/ar/products.json';
import signupTranslationAR from './locale/ar/signup.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'ar',
  supportedLngs: ['ar', 'en'],
  resources: {
    en: {
      common: commonTranslationEN,
      login: loginTranslationEN,
      createBusiness: createBusinessTranslationEN,
      noBusiness: noBusinessTranslationEN,
      products: productsTranslationEN,
      signup: signupTranslationEN,
    },
    ar: {
      common: commonTranslationAR,
      login: loginTranslationAR,
      createBusiness: createBusinessTranslationAR,
      noBusiness: noBusinessTranslationAR,
      products: productsTranslationAR,
      signup: signupTranslationAR,
    },
  },
  defaultNS: 'common',
  debug: true,
});

export default i18n;
