import { Link } from 'react-router-dom';
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Image,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import Icon from '@ant-design/icons';

import SearchSvg from 'assets/jsx-svgs/search.svg';
import img4 from 'assets/temp/4.png';
import img5 from 'assets/temp/5.png';
import img6 from 'assets/temp/6.png';
import img7 from 'assets/temp/7.png';
import img8 from 'assets/temp/8.png';
import img9 from 'assets/temp/9.png';
import map from 'assets/temp/map.png';

import './style.css';

export default function RentView() {
  return (
    <>
      <Row className='cc-mb-15' justify='space-between'>
        <Col>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Welcome To Your Rents!
          </Typography>
        </Col>

        <Col>
          <Button
            className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
            type='primary'>
            Manage Rents
          </Button>
        </Col>
      </Row>

      <Row className='cc-mb-15' gutter={[0, 5]}>
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Earth Dimensions
          </Typography>
        </Col>

        <Col className='rv-map-container' span={24}>
          <Image src={map} preview={false} width='100%' height='auto' />

          <AutoComplete
            className='rv-search-auto-complete'
            style={{ width: 400 }}>
            <Input
              type='text'
              placeholder={'Search dimensions'}
              suffix={<Icon className='cc-sc cc-fz-22' component={SearchSvg} />}
              onChange={(e) => console.log(`search value:`, e.target.value)}
            />
          </AutoComplete>
        </Col>
      </Row>

      <Row className='cc-mb-15' gutter={[20, 5]}>
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Offical Rents
          </Typography>
        </Col>

        <Col span={8}>
          <Link to='#'>
            <div className='rv-card'>
              <Image
                className='cc-mb-5'
                src={img4}
                width='100%'
                preview={false}
              />

              <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-10'>
                Dubai Mall
              </Typography>

              <Typography.Paragraph className='cc-gc cc-fw-500'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
                consectetur adipiscing elit,
              </Typography.Paragraph>
            </div>
          </Link>
        </Col>

        <Col span={8}>
          <Link to='#'>
            <div className='rv-card'>
              <Image
                className='cc-mb-5'
                src={img5}
                width='100%'
                preview={false}
              />

              <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-10'>
                Dubai Mall
              </Typography>

              <Typography.Paragraph className='cc-gc cc-fw-500'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
                consectetur adipiscing elit,
              </Typography.Paragraph>
            </div>
          </Link>
        </Col>

        <Col span={8}>
          <Link to='#'>
            <div className='rv-card'>
              <Image
                className='cc-mb-5'
                src={img6}
                width='100%'
                preview={false}
              />

              <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-10'>
                Dubai Mall
              </Typography>

              <Typography.Paragraph className='cc-gc cc-fw-500'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
                consectetur adipiscing elit,
              </Typography.Paragraph>
            </div>{' '}
          </Link>
        </Col>
      </Row>

      <Row className='cc-mb-15' gutter={[20, 5]}>
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Influncer Maps
          </Typography>
        </Col>

        <Col span={8}>
          <div className='rv-card'>
            <Space direction='horizontal' size='large' className='cc-mb-25'>
              <Avatar
                size={112}
                src={
                  <Image
                    className='cc-mb-5'
                    src={img7}
                    width='100%'
                    preview={false}
                  />
                }
              />

              <Space direction='vertical' size='small'>
                <Typography className='cc-sc cc-fz-18 cc-fw-600'>
                  Jomana Ahmad
                </Typography>
                <Typography className='cc-sc cc-fz-14 cc-fw-400'>
                  Influncer
                </Typography>
              </Space>
            </Space>

            <Typography.Paragraph className='cc-gc cc-fw-500'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
              consectetur adipiscing elit,
            </Typography.Paragraph>
          </div>
        </Col>

        <Col span={8}>
          <div className='rv-card'>
            <Space direction='horizontal' size='large' className='cc-mb-25'>
              <Avatar
                size={112}
                src={
                  <Image
                    className='cc-mb-5'
                    src={img8}
                    width='100%'
                    preview={false}
                  />
                }
              />

              <Space direction='vertical' size='small'>
                <Typography className='cc-sc cc-fz-18 cc-fw-600'>
                  Ahmad Ahmad
                </Typography>
                <Typography className='cc-sc cc-fz-14 cc-fw-400'>
                  Influncer
                </Typography>
              </Space>
            </Space>

            <Typography.Paragraph className='cc-gc cc-fw-500'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
              consectetur adipiscing elit,
            </Typography.Paragraph>
          </div>
        </Col>

        <Col span={8}>
          <div className='rv-card'>
            <Space direction='horizontal' size='large' className='cc-mb-25'>
              <Avatar
                size={112}
                src={
                  <Image
                    className='cc-mb-5'
                    src={img9}
                    width='100%'
                    preview={false}
                  />
                }
              />

              <Space direction='vertical' size='small'>
                <Typography className='cc-sc cc-fz-18 cc-fw-600'>
                  Mohammed Ahmad
                </Typography>
                <Typography className='cc-sc cc-fz-14 cc-fw-400'>
                  Influncer
                </Typography>
              </Space>
            </Space>

            <Typography.Paragraph className='cc-gc cc-fw-500'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet,
              consectetur adipiscing elit,
            </Typography.Paragraph>
          </div>
        </Col>
      </Row>
    </>
  );
}
