export default function StorageSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_6120_7298)'>
        <path d='M2.39922 0.400391H1.59922C1.3783 0.400391 1.19922 0.579477 1.19922 0.800391V23.2004C1.19922 23.4213 1.3783 23.6004 1.59922 23.6004H2.39922C2.62013 23.6004 2.79922 23.4213 2.79922 23.2004V0.800391C2.79922 0.579477 2.62013 0.400391 2.39922 0.400391Z' />
        <path d='M22.3992 0.400391H21.5992C21.3783 0.400391 21.1992 0.579477 21.1992 0.800391V23.2004C21.1992 23.4213 21.3783 23.6004 21.5992 23.6004H22.3992C22.6201 23.6004 22.7992 23.4213 22.7992 23.2004V0.800391C22.7992 0.579477 22.6201 0.400391 22.3992 0.400391Z' />
        <path d='M3.59766 21.2002H20.3977V22.8002H3.59766V21.2002Z' />
        <path d='M4.79844 17.2002H10.3984C10.5045 17.2002 10.6063 17.2423 10.6813 17.3174C10.7563 17.3924 10.7984 17.4941 10.7984 17.6002V20.4002H4.39844V17.6002C4.39844 17.4941 4.44058 17.3924 4.51559 17.3174C4.59061 17.2423 4.69235 17.2002 4.79844 17.2002Z' />
        <path d='M5.59922 16H6.39922C6.50531 16 6.60705 16.0421 6.68207 16.1172C6.75708 16.1922 6.79922 16.2939 6.79922 16.4V16.8H5.19922V16.4C5.19922 16.2939 5.24136 16.1922 5.31638 16.1172C5.39139 16.0421 5.49313 16 5.59922 16Z' />
        <path d='M8.79844 16H9.59844C9.70453 16 9.80627 16.0421 9.88128 16.1172C9.9563 16.1922 9.99844 16.2939 9.99844 16.4V16.8H8.39844V16.4C8.39844 16.2939 8.44058 16.1922 8.51559 16.1172C8.59061 16.0421 8.69235 16 8.79844 16Z' />
        <path d='M13.5992 17.2002H19.1992C19.3053 17.2002 19.407 17.2423 19.482 17.3174C19.5571 17.3924 19.5992 17.4941 19.5992 17.6002V20.4002H13.1992V17.6002C13.1992 17.4941 13.2414 17.3924 13.3164 17.3174C13.3914 17.2423 13.4931 17.2002 13.5992 17.2002Z' />
        <path d='M14.4 16H15.2C15.3061 16 15.4078 16.0421 15.4828 16.1172C15.5579 16.1922 15.6 16.2939 15.6 16.4V16.8H14V16.4C14 16.2939 14.0421 16.1922 14.1172 16.1172C14.1922 16.0421 14.2939 16 14.4 16Z' />
        <path d='M17.5992 16H18.3992C18.5053 16 18.607 16.0421 18.682 16.1172C18.7571 16.1922 18.7992 16.2939 18.7992 16.4V16.8H17.1992V16.4C17.1992 16.2939 17.2414 16.1922 17.3164 16.1172C17.3914 16.0421 17.4931 16 17.5992 16Z' />
        <path d='M3.59766 13.2002H20.3977V14.8002H3.59766V13.2002Z' />
        <path d='M4.79844 9.2002H10.3984C10.5045 9.2002 10.6063 9.24234 10.6813 9.31735C10.7563 9.39237 10.7984 9.49411 10.7984 9.6002V12.4002H4.39844V9.6002C4.39844 9.49411 4.44058 9.39237 4.51559 9.31735C4.59061 9.24234 4.69235 9.2002 4.79844 9.2002Z' />
        <path d='M5.59922 8H6.39922C6.50531 8 6.60705 8.04214 6.68207 8.11716C6.75708 8.19217 6.79922 8.29391 6.79922 8.4V8.8H5.19922V8.4C5.19922 8.29391 5.24136 8.19217 5.31638 8.11716C5.39139 8.04214 5.49313 8 5.59922 8Z' />
        <path d='M8.79844 8H9.59844C9.70453 8 9.80627 8.04214 9.88128 8.11716C9.9563 8.19217 9.99844 8.29391 9.99844 8.4V8.8H8.39844V8.4C8.39844 8.29391 8.44058 8.19217 8.51559 8.11716C8.59061 8.04214 8.69235 8 8.79844 8Z' />
        <path d='M13.5992 9.2002H19.1992C19.3053 9.2002 19.407 9.24234 19.482 9.31735C19.5571 9.39237 19.5992 9.49411 19.5992 9.6002V12.4002H13.1992V9.6002C13.1992 9.49411 13.2414 9.39237 13.3164 9.31735C13.3914 9.24234 13.4931 9.2002 13.5992 9.2002Z' />
        <path d='M14.4 8H15.2C15.3061 8 15.4078 8.04214 15.4828 8.11716C15.5579 8.19217 15.6 8.29391 15.6 8.4V8.8H14V8.4C14 8.29391 14.0421 8.19217 14.1172 8.11716C14.1922 8.04214 14.2939 8 14.4 8Z' />
        <path d='M17.5992 8H18.3992C18.5053 8 18.607 8.04214 18.682 8.11716C18.7571 8.19217 18.7992 8.29391 18.7992 8.4V8.8H17.1992V8.4C17.1992 8.29391 17.2414 8.19217 17.3164 8.11716C17.3914 8.04214 17.4931 8 17.5992 8Z' />
        <path d='M3.59766 5.2002H20.3977V6.80019H3.59766V5.2002Z' />
        <path d='M4.79844 1.2002H10.3984C10.5045 1.2002 10.6063 1.24234 10.6813 1.31735C10.7563 1.39237 10.7984 1.49411 10.7984 1.6002V4.4002H4.39844V1.6002C4.39844 1.49411 4.44058 1.39237 4.51559 1.31735C4.59061 1.24234 4.69235 1.2002 4.79844 1.2002Z' />
        <path d='M5.59922 0H6.39922C6.50531 0 6.60705 0.0421427 6.68207 0.117157C6.75708 0.192172 6.79922 0.293913 6.79922 0.4V0.8H5.19922V0.4C5.19922 0.293913 5.24136 0.192172 5.31638 0.117157C5.39139 0.0421427 5.49313 0 5.59922 0V0Z' />
        <path d='M8.79844 0H9.59844C9.70453 0 9.80627 0.0421427 9.88128 0.117157C9.9563 0.192172 9.99844 0.293913 9.99844 0.4V0.8H8.39844V0.4C8.39844 0.293913 8.44058 0.192172 8.51559 0.117157C8.59061 0.0421427 8.69235 0 8.79844 0V0Z' />
        <path d='M13.5992 1.2002H19.1992C19.3053 1.2002 19.407 1.24234 19.482 1.31735C19.5571 1.39237 19.5992 1.49411 19.5992 1.6002V4.4002H13.1992V1.6002C13.1992 1.49411 13.2414 1.39237 13.3164 1.31735C13.3914 1.24234 13.4931 1.2002 13.5992 1.2002Z' />
        <path d='M14.4 0H15.2C15.3061 0 15.4078 0.0421427 15.4828 0.117157C15.5579 0.192172 15.6 0.293913 15.6 0.4V0.8H14V0.4C14 0.293913 14.0421 0.192172 14.1172 0.117157C14.1922 0.0421427 14.2939 0 14.4 0V0Z' />
        <path d='M17.5992 0H18.3992C18.5053 0 18.607 0.0421427 18.682 0.117157C18.7571 0.192172 18.7992 0.293913 18.7992 0.4V0.8H17.1992V0.4C17.1992 0.293913 17.2414 0.192172 17.3164 0.117157C17.3914 0.0421427 17.4931 0 17.5992 0V0Z' />
      </g>
      <defs>
        <clipPath id='clip0_6120_7298'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
