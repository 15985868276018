export default function RadioUnSelectedSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z'
        stroke='#1B1464'
        strokeWidth='2'
      />
    </svg>
  );
}
