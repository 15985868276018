import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Row,
  Menu,
  Form,
  Switch,
  Space,
  Typography,
  Divider,
  message,
  Select,
} from 'antd';
import Icon from '@ant-design/icons';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import { getFullFieldType } from 'utils/products/get-product-info';
import MultiLingualInput from 'components/MultiLingualInput';
import BurgerMenuSvg from 'assets/jsx-svgs/burger-menu.svg';
import ArrowDownSvg from 'assets/jsx-svgs/arrow-down.svg';
import SmallTextSvg from 'assets/jsx-svgs/small-text.svg';
import LargeTextSvg from 'assets/jsx-svgs/large-text.svg';
import DigitsSvg from 'assets/jsx-svgs/digits.svg';
import SingleChoiceSvg from 'assets/jsx-svgs/single-choice.svg';
import MultipleChoiceSvg from 'assets/jsx-svgs/multiple-choice.svg';
import DateSvg from 'assets/jsx-svgs/date.svg';
import TimeSvg from 'assets/jsx-svgs/time.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import DocumentSvg from 'assets/jsx-svgs/document.svg';

import ProductContext from '../../contexts/ProductContext';
import OptionsList from './OptionsList';

const { Option } = Select;

const DragHandle = sortableHandle(() => (
  <Icon
    className='cc-gc cc-fz-18'
    style={{ cursor: 'move' }}
    component={BurgerMenuSvg}
  />
));

const SortableItem = sortableElement(({ name }) => {
  const {
    forms: { inputsLang },
  } = useContext(ChickChackConfigContext);
  const { t } = useTranslation(['products']);

  return (
    <Col span={24} className='cc-z-1000'>
      <Row
        className='cc-mb-20 pv-modal-sortable-element'
        justify='space-between'>
        <Col>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.orderForm?.[name]?.name !== curr?.orderForm?.[name]?.name
            }>
            {({ getFieldValue }) => (
              <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                {getFieldValue(['orderForm', name, 'name'])?.[
                  inputsLang.code
                ] ||
                  getFullFieldType(
                    getFieldValue(['orderForm', name, 'type']),
                    t,
                  )}
              </Typography>
            )}
          </Form.Item>
        </Col>

        <Col>
          <DragHandle />
        </Col>
      </Row>
    </Col>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  const { t } = useTranslation(['products']);

  return (
    <Space
      className='cc-w-100 pv-modal-sortable-container'
      direction='vertical'
      size='middle'>
      <Typography className='cc-pc cc-fz-14 cc-fw-600'>
        {t('sortFieldsTitle')}
      </Typography>

      <Row>{children}</Row>
    </Space>
  );
});

export default function OrderForm({ hideModal }) {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { product, productIdx, refetchProduct } = useContext(ProductContext);
  const { t } = useTranslation(['products', 'common']);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const reqData = {
        orderForm: JSON.stringify(values.orderForm),
        productId: product.id,
      };

      await axios.put(
        `/seller/product/${user?.selectedBusiness?.id}/update-product-order-form`,
        reqData,
      );

      await refetchProduct(product.id, productIdx);
      hideModal();
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!product.id) hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  return (
    <Form name='order-form-form' initialValues={product} onFinish={onFinish}>
      <Form.List name='orderForm'>
        {(fields, { add, remove, move }) => (
          <Row gutter={20}>
            <Col span={18}>
              <Row>
                <Col className='cc-mb-25' span={24}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu className='pv-overlay-menu'>
                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='1'
                          onClick={() => add({ type: 'text' })}>
                          <Icon className='pv-mr-5' component={SmallTextSvg} />{' '}
                          {t('smallTxtField')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='2'
                          onClick={() => add({ type: 'textArea' })}>
                          <Icon className='pv-mr-5' component={LargeTextSvg} />{' '}
                          {t('largeTxtField')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='3'
                          onClick={() => add({ type: 'number' })}>
                          <Icon className='pv-mr-5' component={DigitsSvg} />{' '}
                          {t('digitsField')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='4'
                          onClick={() => add({ type: 'radio', options: [{}] })}>
                          <Icon
                            className='pv-mr-5'
                            component={SingleChoiceSvg}
                          />{' '}
                          {t('singleChoice')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='5'
                          onClick={() =>
                            add({ type: 'checkbox', options: [{}] })
                          }>
                          <Icon
                            className='pv-mr-5'
                            component={MultipleChoiceSvg}
                          />{' '}
                          {t('multupleChoices')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='6'
                          onClick={() => add({ type: 'date' })}>
                          <Icon className='pv-mr-5' component={DateSvg} />{' '}
                          {t('dateField')}
                        </Menu.Item>

                        <Menu.Item
                          className='cc-sc cc-fz-12 cc-fw-500 pv-menu-item'
                          key='7'
                          onClick={() => add({ type: 'time' })}>
                          <Icon className='pv-mr-5' component={TimeSvg} />{' '}
                          {t('timeField')}
                        </Menu.Item>
                      </Menu>
                    }>
                    <Button className='cc-center-items' type='primary'>
                      {t('addNewFieldBtn')}{' '}
                      <ArrowDownSvg className='apv-ml-10' />
                    </Button>
                  </Dropdown>
                </Col>

                <Col span={24}>
                  {fields.map((field) => (
                    <Form.Item key={field.name} shouldUpdate noStyle>
                      {({ getFieldValue }) => (
                        <>
                          <Row
                            className='cc-mb-10'
                            justify='space-between'
                            align='middle'
                            wrap={false}>
                            <Col>
                              <Typography.Text className='cc-sc cc-fz-18 cc-fw-500'>
                                {getFullFieldType(
                                  getFieldValue([
                                    'orderForm',
                                    field.name,
                                    'type',
                                  ]),
                                  t,
                                )}
                              </Typography.Text>
                            </Col>

                            <Space
                              direction='horizontal'
                              size='middle'
                              align='center'>
                              <Space
                                direction='horizontal'
                                size='small'
                                align='center'>
                                <Typography.Text className='cc-gc cc-fz-12'>
                                  {t('requiredTxt')}
                                </Typography.Text>

                                <Form.Item
                                  name={[field.name, 'required']}
                                  valuePropName='checked'
                                  noStyle>
                                  <Switch
                                    className='pv-switch'
                                    defaultChecked
                                  />
                                </Form.Item>
                              </Space>

                              <Icon
                                className='cc-fz-24 pv-rc'
                                component={DeleteSvg}
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          </Row>

                          <Row gutter={20}>
                            <Col span={12}>
                              <Form.Item
                                name={[field.name, 'name']}
                                rules={[
                                  {
                                    type: 'object',
                                    required: true,
                                    fields: {
                                      ar: { type: 'string', required: true },
                                      en: { type: 'string', required: true },
                                    },
                                    message:
                                      'Please enter a value in all languages',
                                  },
                                ]}>
                                <MultiLingualInput
                                  type='text'
                                  placeholder={t('fieldNamePlaceholder')}
                                  prefix={
                                    <Icon
                                      className='cc-gc pv-mr-5'
                                      component={LargeTextSvg}
                                    />
                                  }
                                  addonAfter={
                                    <Select
                                      className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                                      defaultValue='en'
                                      value={inputsLang?.code}
                                      onChange={(code) =>
                                        setInputsLang(
                                          langs.find(
                                            (lang) => lang.code === code,
                                          ),
                                        )
                                      }>
                                      {langs?.map((lang) => (
                                        <Option
                                          className='cc-fz-14'
                                          key={lang.code}
                                          value={lang.code}>
                                          {lang.code.toUpperCase()}
                                        </Option>
                                      ))}
                                    </Select>
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                name={[field.name, 'description']}
                                rules={[
                                  {
                                    type: 'object',
                                    required: true,
                                    fields: {
                                      ar: { type: 'string', required: true },
                                      en: { type: 'string', required: true },
                                    },
                                    message:
                                      'Please enter a value in all languages',
                                  },
                                ]}>
                                <MultiLingualInput
                                  type='text'
                                  placeholder={t('fieldDescPlaceholder')}
                                  prefix={
                                    <Icon
                                      className='cc-gc pv-mr-5'
                                      component={DocumentSvg}
                                    />
                                  }
                                  addonAfter={
                                    <Select
                                      className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                                      defaultValue='en'
                                      value={inputsLang?.code}
                                      onChange={(code) =>
                                        setInputsLang(
                                          langs.find(
                                            (lang) => lang.code === code,
                                          ),
                                        )
                                      }>
                                      {langs?.map((lang) => (
                                        <Option
                                          className='cc-fz-14'
                                          key={lang.code}
                                          value={lang.code}>
                                          {lang.code.toUpperCase()}
                                        </Option>
                                      ))}
                                    </Select>
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          {['radio', 'checkbox'].includes(
                            getFieldValue(['orderForm', field.name])?.type,
                          ) && <OptionsList name={field.name} />}
                        </>
                      )}
                    </Form.Item>
                  ))}

                  <Row gutter={10}>
                    <Divider />

                    <Col>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}>
                        {t('saveOrderFormBtn')}
                      </Button>
                    </Col>
                    <Col>
                      <Button type='primary' onClick={hideModal} ghost>
                        {t('cancelBtn')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <SortableContainer
                onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
                useDragHandle>
                {fields.map((field) => (
                  <SortableItem
                    key={`item-${field.name}`}
                    index={field.name}
                    name={field.name}
                  />
                ))}
              </SortableContainer>
            </Col>
          </Row>
        )}
      </Form.List>
    </Form>
  );
}
