import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Image,
  Row,
  Form,
  Input,
  Select,
  Skeleton,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { commonInstance, dashboardIstance } from 'api/axios';
import UnderlinedTitle from 'components/partials/UnderlinedTitle';
import UserContext from 'contexts/UserContext';
import nbvFg from 'assets/images/nbv-fg.png';

import './style.css';

const { Option } = Select;

export default function CreateBusinessView() {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation(['createBusiness', 'common']);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      await dashboardIstance.post('/business/add', values);

      const {
        data: { data: businesses },
      } = await dashboardIstance.get('/business/list');

      setUser({
        ...user,
        hasBusiness: true,
        businesses,
        selectedBusiness: businesses[businesses.length - 1],
      });
      history.push('/home');
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { data: _countries },
      } = await commonInstance.get('/country-list');

      const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

      const countries = _countries.map((country) => ({
        ...country,
        displayName: regionNames.of(country.alpha2Code),
      }));

      setCountries(countries);
    })();
  }, []);

  return (
    <Row className='cbv' gutter={0}>
      <Col className='cbv-left' span={16}>
        <UnderlinedTitle className='cc-mb-25' level={3}>
          {t('title')}
        </UnderlinedTitle>

        <Form
          name='create-business-form'
          onFinish={onFinish}
          layout='vertical'
          requiredMark={false}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name='name'
                label={t('businessNameLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('businessNamePlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='handle'
                label={t('handleLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('handlePlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='countryAlpha2Code'
                label={t('countryLabel')}
                rules={[{ required: true }]}>
                <Select
                  showSearch
                  showArrow
                  notFoundContent={!countries?.length && <Skeleton />}
                  placeholder={t('countryPlaceholder')}
                  filterOption={(input, option) =>
                    option.value.toLowerCase().includes(input.toLowerCase()) ||
                    option.name.toLowerCase().includes(input.toLowerCase())
                  }>
                  {countries.map((country) => (
                    <Option
                      name={country.displayName}
                      key={country.alpha2Code}
                      value={country.alpha2Code}>
                      <Image
                        src={`https://flagcdn.com/20x15/${country?.alpha2Code?.toLowerCase()}.png`}
                      />{' '}
                      {country.displayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='city'
                label={t('cityLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('cityPlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='street'
                label={t('streetLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('addressPlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='block'
                label={t('blockLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('addressPlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className='cbv-mb-45'
                name='zipCode'
                label={t('zipCodeLabel')}
                rules={[{ required: true }]}>
                <Input placeholder={t('zipCodePlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={24} />

            <Col span={12}>
              <Form.Item>
                <Button
                  className='cc-w-100'
                  type='primary'
                  htmlType='submit'
                  loading={loading}>
                  {t('submitBtn')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col className='cc-center-items' span={8}>
        <Image src={nbvFg} width='100%' preview={false} />
      </Col>
    </Row>
  );
}
