import { Image } from 'antd';

import unityImg from 'assets/images/unity.png';

export default function AddArEffect() {
  return (
    <div>
      <Image src={unityImg} width='100%' preview={false} />
    </div>
  );
}
