import { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import UserContext from 'contexts/UserContext';
import { dashboardIstance } from 'api/axios';

const toAbsoluteUrl = (origin, pathname) =>
  origin + process.env.PUBLIC_URL + pathname;

export default function Add3dModel() {
  const [loading, setLoading] = useState(false);
  const [iframeRef, setIframeRef] = useState(null);
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);

  const mountNode = iframeRef?.contentWindow?.document?.body;

  const onFinish = () => {
    setLoading(true);
    iframeRef.contentWindow.postMessage('Upload', '*');
    setLoading(false);
  };

  const onSave = async (strData) => {
    setLoading(true);
    try {
      const jsonData = JSON.parse(strData);
      const formData = form.getFieldsValue();
      const data = {
        name: formData.name,
        model: jsonData.modelURL,
        isSpecial: formData.isSpecial || false,
        image: jsonData.previewTexture,
        customerBusinessId: user?.selectedBusiness?.id,
      };

      await dashboardIstance.post('/seller/model/add', data);
      message.success('Success');
    } catch (error) {
      message.error(t('generalError'));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  window.saveModel = onSave;

  useEffect(() => {
    if (iframeRef) {
      const configScript =
        iframeRef.contentWindow.document.createElement('script');

      configScript.innerHTML = `
      var apiBase = "https://portal.chickchack.net:7005/api/v6/";
      var language = "english"; // "arabic"
      var startScene = "ProductsLoader_UI"; // "EditLand", "ProductsLoader_UI", "EditDimension"
      var dimensionId = null;
      var landId = -1;
      var unityLoaded = true;
      var production = true;

      var buildUrl = '${toAbsoluteUrl(window.location.origin, '/WebGL/Build')}';
      var config = {
        dataUrl: buildUrl + '/WebGL.data.unityweb',
        frameworkUrl: buildUrl + '/WebGL.framework.js.unityweb',
        codeUrl: buildUrl + '/WebGL.wasm.unityweb',
        streamingAssetsUrl: buildUrl + 'StreamingAssets',
        companyName: 'REVTECH',
        productName: 'PiPhiDashboard',
        productVersion: '0.1',
      };

      var container = document.querySelector('#unity-container');
      var canvas = document.querySelector('#unity-canvas');
      var loadingBar = document.querySelector('#unity-loading-bar');
      var progressBarFull = document.querySelector('#unity-progress-bar-full');

      loadingBar.style.display = 'block';

      XMLHttpRequest.prototype.originalOpen = XMLHttpRequest.prototype.originalOpen || XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function (...args) {
        this._url = args[1];
        if (this._url.indexOf("https://portal.chickchack.net:7005") != -1)
          this.withCredentials = true;
        this.originalOpen(...args);
      }

      window.saveModel = (...args) => window.top.saveModel(...args);
      window.onmessage = function(e) {
        try {
          if (e.data === "Upload") {
            window.unityInstance.SendMessage('ObjectLoader', 'Upload');
          }
        } catch(ignored) {}
      };
      `;

      const loaderScript =
        iframeRef.contentWindow.document.createElement('script');
      loaderScript.src =
        toAbsoluteUrl(window.location.origin, '/WebGL/Build') +
        '/WebGL.loader.js';

      loaderScript.onload = () => {
        iframeRef.contentWindow
          .createUnityInstance(
            iframeRef.contentWindow.canvas,
            iframeRef.contentWindow.config,
            (progress) => {
              iframeRef.contentWindow.progressBarFull.style.width =
                100 * progress + '%';
            },
          )
          .then((unityInstance) => {
            iframeRef.contentWindow.unityInstance = unityInstance;
            iframeRef.contentWindow.loadingBar.style.display = 'none';
          })
          .catch((message) => {
            alert(message);
          });
      };

      iframeRef.contentWindow.document.body.append(configScript);
      iframeRef.contentWindow.document.body.append(loaderScript);
    }
  }, [iframeRef]);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <iframe
          style={{
            width: '100%',
            height: '700px',
          }}
          title='Add 3D Model'
          ref={setIframeRef}>
          {mountNode &&
            createPortal(
              <div
                style={{
                  width: '100%',
                  height: '100%',
                }}
                id='unity-container'
                className='unity-desktop'>
                <canvas
                  style={{
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                  }}
                  id='unity-canvas'></canvas>

                <div id='unity-loading-bar'>
                  <div id='unity-logo'></div>
                  <div id='unity-progress-bar-empty'>
                    <div id='unity-progress-bar-full'></div>
                  </div>
                </div>
              </div>,
              mountNode,
            )}
        </iframe>
      </Col>

      <Col span={24}>
        <Form
          name='3d-model-form'
          className='cc-w-100'
          onFinish={onFinish}
          layout='vertical'
          form={form}>
          <Form.Item
            className='cc-mb-5'
            name='name'
            label='Model Name'
            rules={[{ required: true }]}>
            <Input type='text' placeholder='Enter model name' />
          </Form.Item>

          <Form.Item name='isSpecial' valuePropName='checked'>
            <Checkbox>Is Special</Checkbox>
          </Form.Item>

          <Form.Item noStyle>
            <Button
              className='cc-w-100'
              type='primary'
              htmlType='submit'
              loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
