export default function HomeSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 23 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_3844:2960)'>
        <path d='M21.5565 10.4388C21.556 10.4383 21.5555 10.4377 21.555 10.4372L12.518 0.647461C12.1328 0.22998 11.6207 0 11.0759 0C10.5312 0 10.019 0.229797 9.63368 0.647278L0.601397 10.4321C0.598355 10.4354 0.595312 10.4388 0.59227 10.4421C-0.198745 11.304 -0.197393 12.7024 0.596158 13.5621C0.958706 13.955 1.43754 14.1826 1.9495 14.2064C1.97029 14.2086 1.99125 14.2097 2.01238 14.2097H2.37256V21.4144C2.37256 22.84 3.44331 24 4.75964 24H8.2952C8.65353 24 8.94424 23.6852 8.94424 23.2969V17.6484C8.94424 16.9979 9.43271 16.4687 10.0332 16.4687H12.1186C12.7191 16.4687 13.2076 16.9979 13.2076 17.6484V23.2969C13.2076 23.6852 13.4982 24 13.8567 24H17.3922C18.7085 24 19.7793 22.84 19.7793 21.4144V14.2097H20.1133C20.6579 14.2097 21.17 13.9799 21.5555 13.5624C22.3499 12.7013 22.3503 11.3005 21.5565 10.4388Z' />
      </g>
      <defs>
        <clipPath id='clip0_3844:2960'>
          <rect width='22.1538' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
