import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Image,
  Row,
  Col,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { dashboardIstance, commonInstance } from 'api/axios';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import loginFg from 'assets/images/login-fg.png';
import loginBg from 'assets/images/login-bg.png';
import logo from 'assets/piphi_logo_nobg.png';

import './style.css';

export default function LoginView() {
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(UserContext);
  const {
    forms: { setLangs, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { t } = useTranslation(['login', 'common']);

  const onFinish = async (values) => {
    setLoading(true);
    const { confirmPassword, ...data } = values;

    try {
      const {
        data: { data: user },
      } = await dashboardIstance.post('/auth/login', data);

      const {
        data: { data: langs },
      } = await commonInstance.get('/language-list');

      if (user.hasBusiness) {
        const {
          data: { data: businesses },
        } = await dashboardIstance.get('/business/list');

        setUser({ ...user, businesses, selectedBusiness: businesses[0] });
      } else {
        setUser(user);
      }

      setLangs(langs);
      setInputsLang(langs?.find((lang) => lang.code === 'ar') || langs[0]);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className='lv' gutter={0}>
      <Col className='cc-center-items lv-left' span={12}>
        <Image className='lv-mb-45' src={logo} preview={false} width='15%' />
        <Typography.Title className='cc-pc lv-mb-45' level={2}>
          {t('title')}
        </Typography.Title>

        <Form
          name='login-form'
          className='cc-w-50'
          onFinish={onFinish}
          layout='vertical'
          requiredMark={false}>
          <Form.Item
            name='email'
            label={t('emailLabel')}
            rules={[{ type: 'email', required: true }]}>
            <Input type='email' placeholder={t('emailPlaceholder')} />
          </Form.Item>

          <Form.Item
            name='password'
            label={t('passwordLabel')}
            rules={[{ required: true, min: 6, max: 50 }]}>
            <Input type='password' placeholder={t('passwordPlaceholder')} />
          </Form.Item>

          <Form.Item>
            <Row className='cc-fw-500' justify='space-between' align='middle'>
              <Form.Item name='rememberMe' valuePropName='checked' noStyle>
                <Checkbox className='cc-pc'>{t('rememberMe')}</Checkbox>
              </Form.Item>

              <Link to='/forgot-password'>{t('forgotPassword')}</Link>
            </Row>
          </Form.Item>

          <Button
            className='cc-w-100'
            type='primary'
            htmlType='submit'
            loading={loading}>
            {t('submitBtn')}
          </Button>

          <Form.Item>
            {t('signupTxt')}{' '}
            <Link className='cc-fw-500' to='/signup'>
              {t('signupLink')}
            </Link>
          </Form.Item>
        </Form>
      </Col>

      <Col className='cc-center-items lv-right' span={12}>
        <Image src={loginFg} width='80%' preview={false} />
        <div className='lv-bg-box'>
          <Image src={loginBg} width='100%' preview={false} />
        </div>
      </Col>
    </Row>
  );
}
