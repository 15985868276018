import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Skeleton,
  message,
  Image,
} from 'antd';
import Icon from '@ant-design/icons';

import { commonInstance, dashboardIstance } from 'api/axios';
import UserContext from 'contexts/UserContext';
import UnderlinedTitle from 'components/partials/UnderlinedTitle';
import DummyImgSvg from 'assets/jsx-svgs/dummy-img.svg';

import './style.css';

const { Dragger } = Upload;
const { Option } = Select;

export default function SettingView() {
  const [countries, setCountries] = useState([]);

  const { t } = useTranslation(['common']);
  const [accountForm] = Form.useForm();
  const [businessForm] = Form.useForm();
  const { user } = useContext(UserContext);

  const onAccountFormFinish = async (values) => {
    try {
      await dashboardIstance.put('/seller/personal/update-information', values);

      message.success('Profile updated successfully');
    } catch (error) {
      message.error(t('generalError'));
    }
  };

  const onBusinessFormFinish = async (values) => {
    try {
      await dashboardIstance.put(
        `/business/edit/${user?.selectedBusiness?.id}`,
        values,
      );

      message.success('Business updated successfully');
    } catch (error) {
      message.error(t('generalError'));
    }
  };

  const onPasswordFormChange = async (values) => {
    try {
      await dashboardIstance.put('/seller/personal/update-password', {
        password: values.newPassword,
      });

      message.success('Password updated successfully');
    } catch (error) {
      message.error(t('generalError'));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: _countries },
        } = await commonInstance.get('/country-list');

        const {
          data: { data: accInfo },
        } = await dashboardIstance.get('/seller/personal/get');

        const {
          data: { data: businessInfo },
        } = await dashboardIstance.get(
          `/business/get/${user?.selectedBusiness?.id}`,
        );

        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

        const countries = _countries.map((country) => ({
          ...country,
          displayName: regionNames.of(country.alpha2Code),
        }));

        setCountries(countries);
        accountForm.setFieldsValue({
          firstName: accInfo.firstName,
          lastName: accInfo.lastName,
          email: accInfo.email,
          bio: accInfo.bio,
          dashboardLanguageId: accInfo.dashboardLanguageId,
          storeLanguageId: accInfo.storeLanguageId,
        });
        businessForm.setFieldsValue(businessInfo);
      } catch (error) {
        message.error(t('generalError'));
      }
    })();
  }, [accountForm, businessForm, t, user?.selectedBusiness?.id]);

  return (
    <>
      <UnderlinedTitle className='cc-mb-20' level={3}>
        Settings
      </UnderlinedTitle>

      <Form
        className='sv-card'
        name='account-form'
        layout='vertical'
        form={accountForm}
        onFinish={onAccountFormFinish}>
        <Row gutter={20}>
          <Col span={24} className='cc-mb-20'>
            <Typography className='cc-pc cc-fz-18 cc-fw-600'>
              Edit account information
            </Typography>
          </Col>

          <Col span={5} className='cc-dragger-container'>
            <span className='cc-mb-5'>Image</span>
            <Dragger
              className='cc-dragger'
              listType='picture'
              name='image'
              action='https://portal.chickchack.net:7005/api/v6/customer-dashboard/seller/personal/update-image'
              // onChange={onChange}
              withCredentials
              // itemRender={() => null}
            >
              <div className='cc-dragger-icon'>
                <Icon component={DummyImgSvg} />
              </div>
              <div className='cc-fw-500'>
                <Typography className='cc-sc'>Drop your image here</Typography>
                <Typography className='cc-pc'>or browse</Typography>
              </div>
            </Dragger>
          </Col>

          <Col span={19}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name='firstName'
                  label={`First Name`}
                  rules={[{ required: true }]}>
                  <Input type='text' placeholder={`Enter First Name`} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='lastName'
                  label={`Last Name`}
                  rules={[{ required: true }]}>
                  <Input type='text' placeholder={`Enter Last Name`} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name={`email`}
                  label={`Email`}
                  rules={[{ required: true }]}>
                  <Input placeholder={`Enter Email`} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name={`bio`}
                  label={`Bio`}
                  rules={[{ required: true }]}>
                  <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder={`Enter Bio`}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='dashboardLanguageId'
                  label={`Dashboard Language`}
                  rules={[{ required: true }]}>
                  <Select
                    className='cc-w-100 cc-sc cc-fw-500'
                    placeholder='Select Dashboard Language'>
                    <Option value='ar'>Ar</Option>
                    <Option value='en'>En</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='storeLanguageId'
                  label={`Preferred Input Language`}
                  rules={[{ required: true }]}>
                  <Select
                    className='cc-w-100 cc-sc cc-fw-500'
                    placeholder='Select Preferred Input Language'>
                    <Option value='ar'>Ar</Option>
                    <Option value='en'>En</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col push={22} span={2}>
            <Button className='cc-w-100' type='primary' htmlType='submit'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      <Form
        className='sv-card'
        name='password-form'
        layout='vertical'
        onFinish={onPasswordFormChange}>
        <Row gutter={20}>
          <Col span={24} className='cc-mb-20'>
            <Typography className='cc-pc cc-fz-18 cc-fw-600'>
              Edit account password
            </Typography>
          </Col>

          <Col span={8}>
            <Form.Item
              name='oldPassword'
              label={'Old Password'}
              rules={[
                { required: true },
                {
                  min: 8,
                  max: 20,
                  message: 'Password must be between 8 and 20 characters',
                },
              ]}
              hasFeedback>
              <Input
                size='large'
                type='password'
                placeholder={'Enter Old Password'}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name='newPassword'
              label={'New Password'}
              rules={[
                { required: true },
                {
                  min: 8,
                  max: 20,
                  message: 'Password must be between 8 and 20 characters',
                },
              ]}
              hasFeedback>
              <Input
                size='large'
                type='password'
                placeholder={'Enter New Password'}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name='confirmPassword'
              label={'Confirm Password'}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value)
                      return Promise.resolve();

                    return Promise.reject(
                      new Error('The two passwords that you do not match!'),
                    );
                  },
                }),
              ]}
              dependencies={['password']}
              hasFeedback>
              <Input
                size='large'
                type='password'
                placeholder={'Enter Confirm Password'}
              />
            </Form.Item>
          </Col>

          <Col push={22} span={2}>
            <Button className='cc-w-100' type='primary' htmlType='submit'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      <Form
        className='sv-card'
        name='business-form'
        layout='vertical'
        form={businessForm}
        onFinish={onBusinessFormFinish}>
        <Row gutter={20}>
          <Col span={24} className='cc-mb-20'>
            <Typography className='cc-pc cc-fz-18 cc-fw-600'>
              Edit business information ({user?.selectedBusiness?.handle})
            </Typography>
          </Col>

          <Col span={12}>
            <Form.Item
              name='name'
              label={`Business Name`}
              rules={[{ required: true }]}>
              <Input type='text' placeholder={`Enter Business Name`} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='countryAlpha2Code'
              label={`Country`}
              rules={[{ required: true }]}>
              <Select
                showSearch
                showArrow
                notFoundContent={!countries?.length && <Skeleton />}
                placeholder={'Enter Country'}
                filterOption={(input, option) =>
                  option.value.toLowerCase().includes(input.toLowerCase()) ||
                  option.name.toLowerCase().includes(input.toLowerCase())
                }>
                {countries.map((country) => (
                  <Option
                    name={country.displayName}
                    key={country.alpha2Code}
                    value={country.alpha2Code}>
                    <Image
                      src={`https://flagcdn.com/20x15/${country?.alpha2Code?.toLowerCase()}.png`}
                    />{' '}
                    {country.displayName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={`city`}
              label={`City`}
              rules={[{ required: true }]}>
              <Input placeholder={`Enter City`} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={`zipCode`}
              label={`Zip Code`}
              rules={[{ required: true }]}>
              <Input placeholder={`Enter Zip Code`} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={`street`}
              label={`Street`}
              rules={[{ required: true }]}>
              <Input placeholder={`Enter Street`} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={`block`}
              label={`Block`}
              rules={[{ required: true }]}>
              <Input placeholder={`Enter Block`} />
            </Form.Item>
          </Col>

          <Col push={22} span={2}>
            <Button className='cc-w-100' type='primary' htmlType='submit'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      {/* <Row className='sv-card'>
        <Col span={24} className='cc-mb-20'>
          <Typography className='cc-pc cc-fz-18 cc-fw-600'>Currency</Typography>
        </Col>

        <Col span={12} className='cc-mb-20'>
          <Form.Item name='currency' noStyle>
            <Select
              className='cc-w-100 cc-sc cc-fw-500'
              placeholder='Select Currency'>
              <Option value='USD'>USD</Option>
              <Option value='NIS'>NIS</Option>
              <Option value='TL'>TL</Option>
              <Option value='UAD'>UAD</Option>
            </Select>
          </Form.Item>

          <Typography className='cc-fz-14 sv-bc sv-select-txt'>
            You can’t change this later.
          </Typography>
        </Col>
      </Row> */}

      {/* <Row className='sv-card'>
        <Col span={24}>
          <Link to='#'>
            <Typography className='cc-fw-500 sv-bc sv-vertical-center'>
              <span className='cc-fz-24 cc-center-items sv-mr-10'>
                <VerifiedSvg />
              </span>{' '}
              Apply for verified buisness.
            </Typography>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Link to='#'>
            <Typography className='cc-fw-500 sv-rc'>
              Delete your business
            </Typography>
          </Link>
        </Col>
      </Row> */}
    </>
  );
}
