import { useContext, useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';
import SearchSvg from 'assets/jsx-svgs/search.svg';
import searchProducts from 'assets/images/search-products.png';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';

import ProductContext from '../contexts/ProductContext';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';

export default function ProductsPackage({ hideModal }) {
  const [searchLoading, setSearchLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [value, setValue] = useState('');

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { user } = useContext(UserContext);
  const { product, productIdx, refetchProduct } = useContext(ProductContext);
  const { t } = useTranslation(['products', 'common']);

  const onSave = async (values) => {
    setBtnLoading(true);

    const reqData = { productId: product.id, products: [] };
    Object.keys(values).forEach((key) => {
      reqData.products.push({ id: Number(key), quantity: values[key] });
    });

    try {
      await axios.post(
        `/seller/product/${user?.selectedBusiness?.id}/group-type/add`,
        reqData,
      );

      await refetchProduct(product.id, productIdx);
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setBtnLoading(false);
      hideModal();
    }
  };

  useDeepCompareEffect(() => {
    if (product.childProduct && product.childProduct.length) {
      const selectedProducts = product.childProduct.map((childProduct) => ({
        value: childProduct.id,
        label: (
          <Space direction='horizontal' size='small' align='center'>
            <Image width={64} src={childProduct.image} preview={false} />

            <div>
              <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                {childProduct?.productTranslations?.[0]?.name || ''}
              </Typography>

              <Typography.Text className='cc-pc cc-fz-12 cc-fw-500'>
                {childProduct.price} AED
              </Typography.Text>
            </div>
          </Space>
        ),
        image: childProduct.image,
        quantity: childProduct?.productGroup?.quantity || 0,
        name: childProduct?.productTranslations?.[0]?.name || '',
      }));

      setSelectedProducts(selectedProducts);
    }
  }, [product.childProduct]);

  const onSearch = async (value) => {
    setSearchLoading(true);

    if (value) {
      try {
        let {
          data: { data: products },
        } = await axios.get(
          `/seller/product/${user?.selectedBusiness?.id}/group-type/product-search`,
          {
            params: {
              name: value,
            },
          },
        );

        products = products
          .filter(
            (product) =>
              !selectedProducts.find(
                (selectedProduct) => selectedProduct.value === product.id,
              ),
          )
          .map((product) => ({
            value: product.id,
            label: (
              <Space direction='horizontal' size='small' align='center'>
                <Image width={64} src={product.image} preview={false} />

                <div>
                  <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                    {product?.productTranslations?.[0]?.name || ''}
                  </Typography>

                  <Typography.Text className='cc-pc cc-fz-12 cc-fw-500'>
                    {product.price} AED
                  </Typography.Text>
                </div>
              </Space>
            ),
            image: product.image,
            quantity: 0,
            name: product?.productTranslations?.[0]?.name || '',
          }));

        setProducts(products);
      } catch (error) {
        setProducts([]);
        message.error(t('generalError', { ns: 'common' }));
      }
    } else setProducts([]);

    setSearchLoading(false);
  };

  const onSelect = (value) => {
    if (selectedProducts.find((product) => product.value === value)) return;

    const newSelected = products.find((product) => product.value === value);

    setSelectedProducts([...selectedProducts, newSelected]);
    setValue('');
    setProducts([]);
  };

  const deleteProduct = (value) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.value !== value),
    );
  };

  useEffect(() => {
    if (!product.id) hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  return (
    <Form name='products-group-form' onFinish={onSave} preserve={false}>
      <Row className='cc-mb-20'>
        <Col className='cc-center-items cc-mb-20' span={24}>
          <AutoComplete
            className='cc-w-100 pv-search-auto-complete'
            options={products.map((product) => ({
              value: product.value,
              label: product.label,
            }))}
            onSelect={onSelect}
            onSearch={onSearch}
            value={value}
            onChange={setValue}
            autoFocus>
            <Input
              type='text'
              placeholder={t('autoCompletePlaceholder')}
              suffix={
                searchLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined className='cc-sc cc-fz-22' spin />
                    }
                  />
                ) : (
                  <Icon className='cc-sc cc-fz-22' component={SearchSvg} />
                )
              }
            />
          </AutoComplete>
        </Col>

        {!!selectedProducts.length ? (
          <Col span={24}>
            {selectedProducts.map((product) => (
              <Row
                className='cc-mb-15'
                key={product.value}
                justify='space-between'
                align='center'>
                <Col>
                  <Space direction='horizontal' align='start'>
                    <Image width={64} src={product.image} preview={false} />

                    <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
                      {product.name}
                    </Typography.Text>
                  </Space>
                </Col>

                <Col>
                  <Space direction='horizontal' align='center'>
                    <Form.Item
                      initialValue={product.quantity}
                      name={product.value}
                      noStyle>
                      <InputNumber size='middle' />
                    </Form.Item>

                    <Icon
                      className='cc-fz-22 cc-clickable pv-rc'
                      component={DeleteSvg}
                      onClick={() => deleteProduct(product.value)}
                    />
                  </Space>
                </Col>
              </Row>
            ))}
          </Col>
        ) : (
          <>
            <Col className='cc-center-items cc-mb-20' span={24}>
              <Image src={searchProducts} preview={false} width={107} />
            </Col>

            <Col className='cc-center-items' span={24}>
              <Typography className='cc-gc cc-fz-16 cc-fw-500'>
                {t('searchFormProductsTxt')}
              </Typography>
            </Col>
          </>
        )}
      </Row>

      <Space direction='horizontal' size='small'>
        <Button type='primary' htmlType='submit' loading={btnLoading}>
          {t('saveProductsPackageBtn')}
        </Button>
        <Button type='primary' onClick={hideModal} ghost>
          {t('cancelBtn')}
        </Button>
      </Space>
    </Form>
  );
}
