import axios from 'axios';

const dashboardIstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v6/customer-dashboard`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const commonInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v6/common`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export { dashboardIstance, commonInstance };
