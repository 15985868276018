import { createContext } from 'react';

const ProductContext = createContext({
  product: null,
  productIdx: null,
  refetchProduct: null,
  triggerQuantity: null,
  triggerShowStatus: null,
});

export default ProductContext;
