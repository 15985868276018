export default function SearchSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 23 23'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M0.333984 9.66634C0.333984 12.1417 1.31731 14.5157 3.06765 16.266C4.81799 18.0163 7.19196 18.9997 9.66732 18.9997C11.7401 19.0022 13.7535 18.3078 15.384 17.028L20.5056 22.1613C20.6141 22.2707 20.7431 22.3575 20.8853 22.4167C21.0275 22.4759 21.18 22.5064 21.334 22.5064C21.488 22.5064 21.6405 22.4759 21.7827 22.4167C21.9248 22.3575 22.0539 22.2707 22.1623 22.1613C22.2717 22.0529 22.3585 21.9238 22.4177 21.7817C22.4769 21.6395 22.5074 21.487 22.5074 21.333C22.5074 21.179 22.4769 21.0265 22.4177 20.8843C22.3585 20.7422 22.2717 20.6131 22.1623 20.5047L17.029 15.383C18.3088 13.7525 19.0032 11.7391 19.0006 9.66634C19.0006 7.19099 18.0173 4.81702 16.267 3.06668C14.5166 1.31634 12.1427 0.333008 9.66732 0.333008C7.19196 0.333008 4.81799 1.31634 3.06765 3.06668C1.31731 4.81702 0.333984 7.19099 0.333984 9.66634ZM9.66732 2.66634C11.0518 2.66634 12.4052 3.07688 13.5563 3.84605C14.7075 4.61522 15.6047 5.70847 16.1345 6.98756C16.6643 8.26664 16.8029 9.6741 16.5328 11.032C16.2627 12.3898 15.596 13.6371 14.6171 14.6161C13.6381 15.5951 12.3908 16.2617 11.0329 16.5318C9.67508 16.8019 8.26762 16.6633 6.98853 16.1335C5.70945 15.6037 4.6162 14.7065 3.84703 13.5553C3.07786 12.4042 2.66732 11.0508 2.66732 9.66634C2.66732 7.80982 3.40482 6.02935 4.71757 4.71659C6.03032 3.40384 7.8108 2.66634 9.66732 2.66634Z' />
    </svg>
  );
}
