const normalizeBrand = (brand) => {
  return {
    id: brand.id,
    image: brand.image,
    name: brand.productBrandTranslations.reduce(
      (prev, curr) => ({ ...prev, [curr.languageCode]: curr.name }),
      {},
    ),
  };
};

const normalizeCategory = (category) => {
  return {
    id: category.id,
    image: category.image,
    name: category.productCategoryTranslations.reduce(
      (prev, curr) => ({ ...prev, [curr.languageCode]: curr.name }),
      {},
    ),
    subCategories: category?.subCategories?.length
      ? category.subCategories.map(normalizeCategory)
      : [],
  };
};

const normalizeProduct = (product) => {
  if (isNaN(product.id)) return product;

  let orderForm;
  try {
    orderForm = JSON.parse(product.orderForm);

    if (!(orderForm instanceof Array)) orderForm = [];
  } catch (ignored) {
    orderForm = [];
  }

  let quantity;
  if (product.quantity === -1) quantity = 'Unlimited Quantity';
  else quantity = product.quantity;

  const name = product.productTranslations.reduce(
    (prev, curr) => ({ ...prev, [curr.languageCode]: curr.name }),
    {},
  );

  const description = product.productTranslations.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.languageCode]: curr.description,
    }),
    {},
  );

  const metaDescription = product.productTranslations.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.languageCode]: curr.metaDescription,
    }),
    {},
  );

  const metaTitle = product.productTranslations.reduce(
    (prev, curr) => ({ ...prev, [curr.languageCode]: curr.metaTitle }),
    {},
  );

  const pageUrl = product.productTranslations.reduce(
    (prev, curr) => ({ ...prev, [curr.languageCode]: curr.pageUrl }),
    {},
  );

  const categories = product.productCategories.map(normalizeCategory);

  return {
    ...product,
    orderForm,
    quantity,
    name,
    description,
    metaDescription,
    metaTitle,
    pageUrl,
    categories,
  };
};

const normalizeOptionsAndSkus = (data) => {
  if (!data?.options?.length || !data?.skus?.length) {
    console.warn('Failed to normalize options and skus');
    return { options: [], skus: [] };
  }

  const options = data.options.map((option) => ({
    id: option.id,
    type: option.type,
    name: option.productOptionTranslations.reduce(
      (prev, curr) => ({ ...prev, [curr.languageCode]: curr.name }),
      {},
    ),
    values: option.productOptionValues.map((value) => ({
      id: value.id,
      colorCode: value?.colorCode || '#000',
      img: value.img,
      text: value.productOptionValueTranslations.reduce(
        (prev, curr) => ({ ...prev, [curr.languageCode]: curr.text }),
        {},
      ),
    })),
  }));

  const skus = data.skus.map((sku) => ({
    id: sku.id,
    price: sku.price,
    quantity: sku.quantity,
    values: sku.productOptionValues.map((value) => ({
      id: value.id,
      colorCode: value?.colorCode || '#000',
      img: value.img,
      text: value.productOptionValueTranslations.reduce(
        (prev, curr) => ({ ...prev, [curr.languageCode]: curr.text }),
        {},
      ),
    })),
  }));

  return { options, skus };
};

export {
  normalizeProduct,
  normalizeOptionsAndSkus,
  normalizeBrand,
  normalizeCategory,
};
