const dummyData = [
  {
    id: 1,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Shipped',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 2,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Canceled',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 3,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Pending',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 4,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Canceled',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 5,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Canceled',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 6,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Pending',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 7,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Shipped',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
  {
    id: 8,
    name: 'Jomana Ahmad',
    phone: '+971 475 8980',
    date: new Date('10-05-2021'),
    status: 'Shipped',
    price: 100,
    lastEdit: '1 Hour ago',
    orderNumber: 983209,
  },
];

export default dummyData;
