export default function WhatsAppSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 13 12'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_5526_6816)'>
        <path d='M9.82854 7.22504L9.19372 6.58909C9.02052 6.41558 8.78113 6.32863 8.5374 6.35059C8.29347 6.3725 8.07353 6.50068 7.93393 6.70222L7.71279 7.02146C7.41206 6.96779 6.83028 6.81589 6.42362 6.40848C6.01679 6.0009 5.86518 5.41782 5.8117 5.117L6.13045 4.89545C6.33165 4.75557 6.4596 4.53519 6.48143 4.29083C6.5033 4.04647 6.4165 3.80685 6.24335 3.63341L5.60853 2.99746C5.35343 2.74192 4.96892 2.68093 4.65167 2.8456C4.09542 3.13431 3.73002 3.67548 3.62268 4.36942C3.44784 5.50002 3.96959 6.83385 4.98429 7.85035C5.86926 8.7369 6.99508 9.24779 8.01717 9.24779C8.16704 9.24779 8.31472 9.2368 8.459 9.21442C9.15175 9.10693 9.69195 8.74084 9.98012 8.18361C9.98012 8.18361 9.98012 8.18361 9.98014 8.18361C10.1445 7.86575 10.0836 7.48056 9.82854 7.22504ZM9.33421 7.84836C9.10519 8.29119 8.67131 8.444 8.34766 8.49423C7.4578 8.63228 6.33949 8.17733 5.49867 7.33503C4.65789 6.49274 4.20369 5.37247 4.34154 4.48098C4.39165 4.15674 4.54424 3.72212 4.98628 3.49267C4.99826 3.48648 5.0115 3.48346 5.02493 3.48346C5.04963 3.48346 5.07499 3.49365 5.09408 3.51275L5.72893 4.14873C5.75529 4.17519 5.75866 4.20524 5.75682 4.22579C5.75497 4.24637 5.74636 4.27534 5.71566 4.29667L5.21422 4.64523C5.10936 4.71812 5.05029 4.84079 5.05864 4.96838C5.0618 5.01657 5.14453 6.1577 5.90919 6.92373C6.67386 7.68978 7.81295 7.77271 7.86105 7.77582C7.98832 7.78403 8.11089 7.72501 8.18365 7.61994L8.53159 7.1176C8.55286 7.0869 8.5818 7.07825 8.60232 7.0764C8.62288 7.0745 8.65285 7.07792 8.67929 7.10436L9.31411 7.74029C9.34361 7.76982 9.35185 7.81426 9.33421 7.84836Z' />
        <path d='M11.0661 1.75744C9.93482 0.624117 8.43076 0 6.83086 0C5.23097 0 3.7269 0.624117 2.59562 1.75741C1.63413 2.72062 1.02373 3.99541 0.87681 5.34696C0.737909 6.62466 1.0151 7.92518 1.6582 9.02761L1.03405 11.3611C1.00041 11.4868 1.0363 11.621 1.12822 11.7131C1.2201 11.8051 1.35408 11.8411 1.47953 11.8074L3.80886 11.1821C4.72372 11.7177 5.77534 12 6.83683 12C7.05214 12 7.26787 11.9884 7.48295 11.9649C8.83209 11.8177 10.1046 11.2062 11.0661 10.243C12.1974 9.10973 12.8204 7.60294 12.8204 6.00023C12.8204 4.39753 12.1974 2.89073 11.0661 1.75744ZM10.5517 9.72769C8.81267 11.4698 6.1416 11.7794 4.05617 10.4806C3.99797 10.4444 3.93136 10.4257 3.86409 10.4257C3.8326 10.4257 3.80097 10.4298 3.76997 10.4381L1.89979 10.9401L2.40093 9.06661C2.42694 8.96927 2.41157 8.86547 2.35849 8.77992C1.06196 6.69068 1.37102 4.01484 3.11 2.27273C5.16172 0.217406 8.50001 0.217406 10.5517 2.27273C12.6034 4.32806 12.6034 7.67236 10.5517 9.72769Z' />
      </g>
      <defs>
        <clipPath id='clip0_5526_6816'>
          <rect
            width='11.9786'
            height='12'
            fill='white'
            transform='translate(0.841309)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
