import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import LoginView from 'views/auth/Login';
import SignupView from 'views/auth/Signup';

export default function NoAuthRouter() {
  return (
    <BrowserRouter basename='/'>
      <Switch>
        <Route exact path='/login' component={LoginView} />
        <Route exact path='/signup' component={SignupView} />

        <Route path='*' render={() => <Redirect to='/login' />} />
      </Switch>
    </BrowserRouter>
  );
}
