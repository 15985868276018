import { useEffect, useContext, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { message, Spin } from 'antd';

import normalizeDimension from 'utils/dimensions/normalize-dimensions';
import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';

import DimensionsView from './Dimensions';
import MyDimensionView from './MyDimension';
import DimensionsContext from './DimensionsContext';
import VirtualSupportView from './VirtualSupport';

export default function DimensionsSwitch() {
  const [dimensions, setDimensions] = useState();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation(['common']);
  const { user } = useContext(UserContext);

  const fetchDimensions = async () => {
    setLoading(true);

    try {
      const {
        data: { data: dimensions },
      } = await axios.get(
        `/business/dimension/${user?.selectedBusiness?.id}/list`,
      );

      setDimensions(dimensions.map(normalizeDimension));
    } catch (error) {
      message.error(t('generalError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, user?.selectedBusiness?.id]);

  return loading ? (
    <Spin spinning={loading} />
  ) : (
    <DimensionsContext.Provider
      value={{
        dimensions,
        fetchDimensions,
      }}>
      <Switch>
        <Route exact path='/manage-dimensions' component={DimensionsView} />
        <Route
          exact
          path='/manage-dimensions/:id'
          component={MyDimensionView}
        />
        <Route
          exact
          path='/manage-dimensions/:id/virtual-support'
          component={VirtualSupportView}
        />

        <Route path='*' render={() => <Redirect to='/manage-dimensions' />} />
      </Switch>
    </DimensionsContext.Provider>
  );
}
