import { Row, Space, Tag, Typography } from 'antd';
import Icon from '@ant-design/icons';

import PhoneSvg from 'assets/jsx-svgs/phone.svg';
import CalenderSvg from 'assets/jsx-svgs/calender.svg';

const getTagClass = (status) => {
  switch (status.toLowerCase()) {
    case 'shipped':
      return 'ov-br-16 ov-tag-green';
    case 'canceled':
      return 'ov-br-16 ov-tag-red';
    case 'pending':
      return 'ov-br-16 ov-tag-gold';
    default:
      return 'ov-br-16';
  }
};

const columns = (numOfOrders) => [
  {
    title: () => (
      <Space direction='horizontal' align='center' size='small'>
        <Typography className='cc-pc cc-fz-16 cc-fw-600'>Orders</Typography>

        <Typography className='cc-gc cc-fz-14 cc-fw-500'>
          ({numOfOrders} Orders)
        </Typography>
      </Space>
    ),
    render: (_, record) => (
      <Row justify='space-between' align='middle'>
        <Space direction='vertical' align='start' size='small'>
          <Typography className='cc-sc cc-fz-12 cc-fw-600'>
            {record.name}
          </Typography>

          <Space direction='horizontal' align='center' size='middle'>
            <Typography className='cc-gc cc-fz-12 cc-fw-500'>
              <Icon className='cc-gc cc-fz-16' component={PhoneSvg} />{' '}
              {record.phone}
            </Typography>

            <Typography className='cc-gc cc-fz-12 cc-fw-500'>
              <Icon className='cc-gc cc-fz-16' component={CalenderSvg} />{' '}
              {new Date(record.date).toLocaleDateString()}
            </Typography>

            <Tag className={getTagClass(record.status)}>{record.status}</Tag>
          </Space>
        </Space>

        <Space direction='horizontal' align='center' size='large'>
          <Typography className='cc-pc cc-fz-14 cc-fw-500'>
            {record.price} AED
          </Typography>
          <Typography className='cc-gc cc-fz-12 cc-fw-500'>
            {record.lastEdit}
          </Typography>
        </Space>
      </Row>
    ),
  },
];

export default columns;
