export default function CheckBoxUncheckedSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 18'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z'
      />
    </svg>
  );
}
