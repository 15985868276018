export default function DummyImgSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 33 31'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M9.30989 16.5983C10.0753 16.5983 10.8094 16.2942 11.3506 15.753C11.8919 15.2117 12.1959 14.4777 12.1959 13.7122C12.1959 12.9468 11.8919 12.2127 11.3506 11.6715C10.8094 11.1302 10.0753 10.8262 9.30989 10.8262C8.54446 10.8262 7.81038 11.1302 7.26914 11.6715C6.72789 12.2127 6.42383 12.9468 6.42383 13.7122C6.42383 14.4777 6.72789 15.2117 7.26914 15.753C7.81038 16.2942 8.54446 16.5983 9.30989 16.5983Z' />
      <path d='M28.6637 25.7565C28.6637 26.895 28.2421 27.9869 27.4917 28.792C26.7412 29.597 25.7234 30.0493 24.6621 30.0493H4.65397C3.59267 30.0493 2.57484 29.597 1.82439 28.792C1.07394 27.9869 0.652344 26.895 0.652344 25.7565V8.58551C0.652343 7.44775 1.07339 6.35652 1.82298 5.55159C2.57256 4.74667 3.58937 4.29389 4.64997 4.29276C4.64997 3.15425 5.07157 2.06237 5.82202 1.25732C6.57247 0.452271 7.5903 0 8.6516 0H28.6597C29.721 0 30.7389 0.452271 31.4893 1.25732C32.2398 2.06237 32.6614 3.15425 32.6614 4.29276V21.4638C32.6614 22.6015 32.2403 23.6928 31.4907 24.4977C30.7412 25.3026 29.7244 25.7554 28.6637 25.7565ZM28.6597 2.14638H8.6516C8.12095 2.14638 7.61204 2.37251 7.23681 2.77504C6.86159 3.17756 6.65079 3.7235 6.65079 4.29276H24.6621C25.7234 4.29276 26.7412 4.74503 27.4917 5.55008C28.2421 6.35512 28.6637 7.447 28.6637 8.58551V23.6102C29.1937 23.609 29.7016 23.3824 30.076 22.98C30.4503 22.5776 30.6606 22.0323 30.6606 21.4638V4.29276C30.6606 3.7235 30.4498 3.17756 30.0745 2.77504C29.6993 2.37251 29.1904 2.14638 28.6597 2.14638ZM4.65397 6.43913C4.12332 6.43913 3.61441 6.66527 3.23918 7.06779C2.86396 7.47032 2.65316 8.01626 2.65316 8.58551V25.7565L7.94731 20.704C8.11085 20.5292 8.3257 20.4204 8.55544 20.3962C8.78518 20.3719 9.01567 20.4336 9.20783 20.5709L14.53 24.3764L21.953 16.4134C22.1014 16.254 22.2927 16.1488 22.4999 16.1126C22.7071 16.0764 22.9197 16.111 23.1075 16.2116L26.6629 20.3906V8.58551C26.6629 8.01626 26.4521 7.47032 26.0769 7.06779C25.7017 6.66527 25.1928 6.43913 24.6621 6.43913H4.65397Z' />
    </svg>
  );
}
