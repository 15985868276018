import { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import UserContext from 'contexts/UserContext';

import NoAuthRouter from './NoAuthRouter';
import NoBusinessRouter from './NoBusinessRouter';
import DashboardRouter from './DashboardRouter';

export default function ChickChackRouter() {
  const { user } = useContext(UserContext);

  if (!user) {
    return <NoAuthRouter />;
  } else if (!user.hasBusiness) {
    return <NoBusinessRouter />;
  } else {
    return (
      <BrowserRouter basename='/'>
        <Switch>
          <Route path='/dashboard' component={DashboardRouter} />
          <Route path='*' render={() => <Redirect to='/dashboard' />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
