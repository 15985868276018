export default function ChatSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_3844:2989)'>
        <path d='M15.6184 5.39248C14.2635 2.62594 11.4293 0.796875 8.25017 0.796875C3.7012 0.796875 0.000361231 4.47671 0.000361231 8.9998C0.000361231 10.229 0.264261 11.4072 0.785124 12.5057L0.0140015 16.3618C-0.0841524 16.8526 0.348869 17.2865 0.839826 17.1894L4.74344 16.4176C5.26904 16.6669 5.81277 16.8565 6.37146 16.9872C5.11913 11.0557 9.61509 5.48801 15.6184 5.39248Z' />
        <path d='M23.2149 18.5054C23.3496 18.2213 23.4667 17.9318 23.5669 17.6376H23.5412C25.2965 12.4853 21.6051 7.07027 16.1554 6.8066C16.1553 6.80632 16.1553 6.80604 16.1552 6.80576C11.4661 6.58967 7.57031 10.3441 7.57031 14.9995C7.57031 19.5188 11.2434 23.1957 15.7612 23.2023C16.9866 23.2006 18.1612 22.9368 19.2565 22.4173C23.5317 23.2625 23.1983 23.2024 23.2965 23.2024C23.7408 23.2024 24.0727 22.7953 23.986 22.3615L23.2149 18.5054Z' />
      </g>
      <defs>
        <clipPath id='clip0_3844:2989'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
