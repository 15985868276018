import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';

import OrdersContext from './OrdersContext';
import OrdersView from './Orders';
import dummyData from './dummy-data';
import OrderDetailsView from './OrderDetails';

export default function OrdersSwitch() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setOrders(dummyData);
    }, 1000);
  }, []);

  return (
    <OrdersContext.Provider value={{ orders, setOrders }}>
      <Switch>
        <Route exact path='/orders' component={OrdersView} />
        <Route exact path='/orders/:id' component={OrderDetailsView} />
      </Switch>
    </OrdersContext.Provider>
  );
}
