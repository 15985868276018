export default function ArrowDownSvg({ className = '' }) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'
      className={className}>
      <path
        d='M2 5L8 11L14 5'
        stroke='currentColor'
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
