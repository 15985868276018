export default function MultipleChoiceSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_5771_9274)'>
        <path d='M4.92584 16.0002H1.11328V12.1895H4.92584V16.0002Z' />
        <path d='M13.9726 14.3521H6.69262C6.55611 14.3521 6.44531 14.2365 6.44531 14.094C6.44531 13.9516 6.55611 13.8359 6.69262 13.8359H13.9726C14.1091 13.8359 14.2199 13.9516 14.2199 14.094C14.2199 14.2365 14.1093 14.3521 13.9726 14.3521Z' />
        <path d='M4.92584 9.90459H1.11328V6.09375H4.92584V9.90459Z' />
        <path d='M13.9726 8.25832H6.69262C6.55611 8.25832 6.44531 8.1427 6.44531 8.00025C6.44531 7.8578 6.55611 7.74219 6.69262 7.74219H13.9726C14.1091 7.74219 14.2199 7.8578 14.2199 8.00025C14.2199 8.1427 14.1093 8.25832 13.9726 8.25832Z' />
        <path d='M4.92584 0H1.11328V3.81084H4.92584V0ZM4.05172 1.44697L2.93869 2.63987C2.85646 2.72813 2.72007 2.74245 2.62263 2.67342L2.01028 2.23974C2.00694 2.23742 2.00447 2.23355 2.00348 2.22955C1.92706 1.9009 2.2043 1.75368 2.38187 1.87923L2.72897 2.1249L3.61917 1.17071C3.76892 1.01019 4.06952 1.09665 4.05666 1.43497C4.05629 1.43936 4.05456 1.44387 4.05172 1.44697Z' />
        <path d='M4.92584 3.90459H1.11328V0.09375H4.92584V3.90459Z' />
        <path d='M13.9726 2.16457H6.69262C6.55611 2.16457 6.44531 2.04895 6.44531 1.9065C6.44531 1.76405 6.55611 1.64844 6.69262 1.64844H13.9726C14.1091 1.64844 14.2199 1.76405 14.2199 1.9065C14.2199 2.04895 14.1093 2.16457 13.9726 2.16457Z' />
      </g>
      <defs>
        <clipPath id='clip0_5771_9274'>
          <rect width='15.3333' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
