import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Image, Modal, Row, Typography } from 'antd';

import I18nContext from 'contexts/I18nContext';

import DimensionsContext from '../DimensionsContext';
import CreateDimensionForm from './CreateDimensionForm';
import './style.css';

export default function DimensionsView() {
  const [isVisible, setIsVisible] = useState(false);
  const { language } = useContext(I18nContext);
  const { dimensions } = useContext(DimensionsContext);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify='space-between'>
            <Col span={14}>
              <Typography className='cc-sc cc-fz-24 cc-fw-600 cc-mb-10'>
                Welcome To Your Dimensions!
              </Typography>
              <Typography className='cc-sc cc-fz-24 cc-fw-600 cc-mb-20'>
                Start managing your Dimensions.
              </Typography>
            </Col>

            <Col>
              <Button
                className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
                type='primary'
                onClick={() => setIsVisible(true)}>
                Create new dimension
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 20]} className='cc-mb-25'>
            {dimensions?.map((dimension) => (
              <Col key={dimension.id} span={8}>
                <div className='dv-card'>
                  <Image
                    className='cc-mb-5 dv-card-img'
                    src={dimension.image}
                    width='100%'
                    preview={false}
                  />

                  <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-10'>
                    {dimension.name[language.code] || dimension.name.ar}
                  </Typography>

                  <Typography.Paragraph className='cc-gc cc-fw-500'>
                    {dimension.description[language.code] ||
                      dimension.description.ar}
                  </Typography.Paragraph>

                  <Row gutter={20} justify='center'>
                    <Col>
                      <Link to={`/manage-dimensions/${dimension.id}`}>
                        <Button className='cc-fz-14 cc-fw-500' type='primary'>
                          Edit
                        </Button>
                      </Link>
                    </Col>

                    <Col>
                      <Link
                        to={`/manage-dimensions/${dimension.id}/virtual-support`}>
                        <Button className='cc-fz-14 cc-fw-500' type='primary'>
                          Virtual Support
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Modal
        visible={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width='50%'
        closable={false}
        footer={null}>
        <CreateDimensionForm setIsVisible={setIsVisible} />
      </Modal>
    </>
  );
}
