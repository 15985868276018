import { useContext, useState } from 'react';
import { Input } from 'antd';

import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';

export default function MultiLingualInput({ ...props }) {
  const inputProps = {};

  if (props instanceof Array)
    props.forEach((prop) => Object.assign(inputProps, prop));
  else Object.assign(inputProps, props);

  const [value, setValue] = useState(inputProps.value || {});
  const {
    forms: { inputsLang },
  } = useContext(ChickChackConfigContext);

  const onChange = (e) => {
    const newValue = { ...value, [inputsLang.code]: e.target.value };

    setValue(newValue);
    inputProps.onChange(newValue);
  };

  useDeepCompareEffect(() => {
    setValue(inputProps.value || {});
  }, [inputProps.value]);

  return (
    <Input {...inputProps} onChange={onChange} value={value[inputsLang.code]} />
  );
}
