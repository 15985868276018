export default function ReloadSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 18'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M16.6024 9.63461C16.0438 9.54961 15.5477 9.93251 15.4676 10.4784C15.2664 11.8495 14.6336 13.1005 13.6375 14.0966C11.1023 16.6318 6.9803 16.6298 4.4461 14.0966C1.9124 11.5624 1.9124 7.43891 4.4461 4.90521C5.3992 3.95261 6.6024 3.33151 7.949 3.10541C8.5281 2.99551 9.1507 2.97651 9.7967 3.04971C10.6837 3.15181 11.5093 3.44611 12.2627 3.87821L10.9608 4.10051C10.4169 4.19381 10.0506 4.71041 10.1444 5.25481C10.2274 5.74211 10.6503 6.08631 11.1288 6.08631C11.1845 6.08631 11.2411 6.08191 11.2987 6.07211L14.7889 5.47541C15.0496 5.43051 15.283 5.28401 15.4364 5.06721C15.5897 4.85091 15.6503 4.58231 15.6053 4.32061L15.0076 0.830913C14.9158 0.287013 14.406 -0.0772868 13.8533 0.0140132C13.3094 0.107313 12.9431 0.624413 13.0369 1.16881L13.1942 2.08731C12.2263 1.54431 11.1586 1.19301 10.0242 1.06241C9.1746 0.965713 8.3504 0.992613 7.596 1.13711C5.8592 1.42811 4.2811 2.24211 3.032 3.49111C-0.2815 6.80461 -0.2815 12.1962 3.032 15.5106C4.6887 17.1678 6.8655 17.996 9.0418 17.996C11.2186 17.996 13.3943 17.1679 15.0516 15.5106C16.3534 14.2088 17.1815 12.5692 17.4461 10.7694C17.5263 10.2225 17.1483 9.71461 16.6024 9.63461Z' />
    </svg>
  );
}
