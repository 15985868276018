import { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Typography,
  Modal,
  Spin,
} from 'antd';

import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import I18nContext from 'contexts/I18nContext';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import EditSvg from 'assets/jsx-svgs/edit.svg';

import DimensionsContext from '../DimensionsContext';
import UpdateDimensionForm from './UpdateDimensionForm';
import './style.css';

export default function MyDimensionView() {
  const [isVisible, setIsVisible] = useState(false);
  const [dimension, setDimension] = useState({});
  const [loading, setLoading] = useState(true);
  const [unityContainer, setUnityContainer] = useState(null);

  const { dimensions } = useContext(DimensionsContext);
  const { language } = useContext(I18nContext);
  const { id } = useParams();
  const history = useHistory();

  const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

  useDeepCompareEffect(() => {
    const dimension = dimensions?.find(
      (dimension) => Number(dimension.id) === Number(id),
    );

    if (dimension) {
      setDimension(dimension);
      setLoading(false);
    } else history.push('/manage-dimensions');
  }, [dimensions]);

  useEffect(() => {
    if (window.unityInstance) {
      window.unityInstance.Quit();
    }

    const oldScript = document.getElementById('unity-script');
    if (oldScript) {
      oldScript.remove();
    }

    if (unityContainer) {
      const unityScript = document.createElement('script');
      unityScript.id = 'unity-script';
      unityScript.type = 'text/javascript';
      unityScript.async = true;

      unityScript.innerHTML = `
        var apiBase = "https://portal.chickchack.net:7005/api/v6/";
        var language = "english"; // "arabic"
        var startScene = "EditDimension"; // "EditLand", "ProductsLoader_UI", "EditDimension"
        var dimensionId = ${dimension.id};
        var landId = -1;
        var unityLoaded = true;

        var buildUrl = '${toAbsoluteUrl('/WebGL/Build')}';
        var loaderUrl = buildUrl + '/WebGL.loader.js';
        var config = {
          dataUrl: buildUrl + '/WebGL.data.unityweb',
          frameworkUrl: buildUrl + '/WebGL.framework.js.unityweb',
          codeUrl: buildUrl + '/WebGL.wasm.unityweb',
          streamingAssetsUrl: 'StreamingAssets',
          companyName: 'REVTECH',
          productName: 'PiPhiDashboard',
          productVersion: '0.1',
        };
  
        var container = document.querySelector('#unity-container');
        var canvas = document.querySelector('#unity-canvas');
        var loadingBar = document.querySelector('#unity-loading-bar');
        var progressBarFull = document.querySelector('#unity-progress-bar-full');
        var mobileWarning = document.querySelector('#unity-mobile-warning');
  
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          container.className = 'unity-mobile';
          config.devicePixelRatio = 1;
          mobileWarning.style.display = 'block';
          setTimeout(() => {
            mobileWarning.style.display = 'none';
          }, 5000);
        } 
  
        loadingBar.style.display = 'block';
        
        if(!window.UnityLoader) {
          var script = document.createElement('script');
          window.UnityLoader = script;
          script.src = loaderUrl;
          script.onload = () => {
            document.onkeydown = function(e){
              var n = (window.Event) ? e.which : e.keyCode;
              if (n == 9 && window.unityInstance){
                  // window.unityInstance.SendMessage("WebReqeustsGO", "MoveToNextInput");
                  return false;
              }
            }
            
            createUnityInstance(canvas, config, (progress) => {
              progressBarFull.style.width = 100 * progress + '%';
            })
              .then((unityInstance) => {
                window.unityInstance = unityInstance;
                loadingBar.style.display = 'none';
              })
              .catch((message) => {
                alert(message);
              });
          };
          document.body.appendChild(script);
        } else {
          createUnityInstance(canvas, config, (progress) => {
            progressBarFull.style.width = 100 * progress + '%';
          })
            .then((unityInstance) => {
              window.unityInstance = unityInstance;
              loadingBar.style.display = 'none';
            })
            .catch((message) => {
              alert("Something Went Wrong");
              console.log(message);
            });
        }
        `;

      document.body.appendChild(unityScript);
    }
    return () => {
      if (window.unityLoaded) {
        try {
          window.unityInstance.SendMessage('WebReqeustsGO', 'GiveUpKeyboard');
        } catch (error) {}
        window.unityInstance.Quit();
        window.unityLoaded = false;
      }
    };
  }, [dimension, unityContainer]);

  return loading ? (
    <Spin spinning={loading} />
  ) : (
    <>
      <Row>
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Welcome To Your Dimension!
          </Typography>
        </Col>

        <Divider />

        <Col span={24}>
          <Card
            className='mdv-card'
            title={
              <Row justify='space-between'>
                <Col>
                  <Typography className='cc-sc cc-fw-600'>
                    {dimension.name[language.code] || dimension.name.ar}
                  </Typography>
                </Col>
                <Col>
                  <span
                    className='cc-sc cc-clickable mdv-mr-15'
                    onClick={() => setIsVisible(true)}>
                    <EditSvg />
                  </span>
                  <span className='cc-sc cc-clickable'>
                    <DeleteSvg />
                  </span>
                </Col>
              </Row>
            }
            actions={[
              <Space className='mdv-card-desc' direction='vertical'>
                <Typography className='cc-sc cc-fw-600'>Description</Typography>

                <Typography.Paragraph className='cc-gc cc-fz-14 cc-fw-500'>
                  {dimension.description[language.code] ||
                    dimension.description.ar}
                </Typography.Paragraph>
              </Space>,
            ]}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Row justify='space-between'>
                  <Space direction='horizontal' align='center' size='middle'>
                    <Button
                      className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
                      type='primary'>
                      Create new model
                    </Button>

                    <Button
                      className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
                      type='primary'>
                      Reset store location
                    </Button>
                  </Space>

                  <Space direction='horizontal' align='center' size='middle'>
                    <Button
                      onClick={() => {
                        if (window.unityInstance) {
                          window.unityInstance.SetFullscreen(true);
                        }
                      }}
                      className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
                      type='primary'>
                      Full screen
                    </Button>

                    <Button
                      onClick={() => {
                        if (window.unityInstance) {
                          window.unityInstance.SendMessage(
                            'WebReqeustsGO',
                            'ResetTemplate',
                          );
                        }
                      }}
                      className='cc-fz-14 cc-fw-500'
                      type='primary'>
                      Choose a New Template
                    </Button>
                    <Button
                      onClick={() => {
                        if (window.unityInstance) {
                          window.unityInstance.SendMessage(
                            'WebReqeustsGO',
                            'SaveDimension',
                          );
                        }
                      }}
                      className='cc-fz-14 cc-fw-500'
                      type='primary'>
                      Save Changes
                    </Button>
                  </Space>
                </Row>
              </Col>

              <Col span={24}>
                <div
                  style={{
                    width: '100%',
                    height: '700px',
                  }}
                  id='unity-container'
                  class='unity-desktop'
                  ref={(r) => setUnityContainer(r)}>
                  <canvas
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    id='unity-canvas'></canvas>

                  <div id='unity-loading-bar'>
                    <div id='unity-logo'></div>
                    <div id='unity-progress-bar-empty'>
                      <div id='unity-progress-bar-full'></div>
                    </div>
                  </div>

                  <div id='unity-mobile-warning'>
                    WebGL builds are not supported on mobile devices.
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        visible={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width='50%'
        closable={false}
        footer={null}>
        <UpdateDimensionForm
          setIsVisible={setIsVisible}
          dimension={dimension}
        />
      </Modal>
    </>
  );
}
