const normalizeDimension = (dimension) => {
  return {
    id: dimension.id,
    image: dimension.image,
    name: dimension.businessDimensionTranslations.reduce(
      (prev, curr) => ({ ...prev, [curr.languageCode]: curr.name }),
      {},
    ),
    description: dimension.businessDimensionTranslations.reduce(
      (prev, curr) => ({ ...prev, [curr.languageCode]: curr.description }),
      {},
    ),
    streamingLink: dimension.streamingLink,
    streamerToken: dimension.streamerToken,
  };
};

export default normalizeDimension;
