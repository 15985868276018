export default function MoneyBagSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        d='M10.1758 13.404C10.1758 13.5265 10.2197 13.6207 10.3139 13.6866C10.4018 13.7494 10.5368 13.809 10.7063 13.8561V12.9395C10.5493 12.9646 10.4206 13.0148 10.3265 13.0933C10.2229 13.1718 10.1758 13.2753 10.1758 13.404Z'
        fillOpacity='0.8'
      />
      <path
        d='M11.4855 14.6019C11.3913 14.5454 11.2469 14.4889 11.0586 14.4355V15.3302C11.4447 15.2957 11.6331 15.1387 11.6331 14.8499C11.6331 14.7432 11.586 14.6616 11.4855 14.6019Z'
        fillOpacity='0.8'
      />
      <path
        d='M10.8762 10.7422C9.00842 10.7422 7.49219 12.2616 7.49219 14.1388C7.49219 16.0066 9.00842 17.5229 10.8762 17.5229C12.7566 17.5229 14.2728 16.0066 14.2728 14.1388C14.2728 12.2616 12.7566 10.7422 10.8762 10.7422ZM11.9655 15.3348C11.8776 15.4667 11.7489 15.5766 11.5857 15.655C11.4319 15.7304 11.2529 15.7775 11.052 15.79V16.082C11.052 16.1322 11.0363 16.173 11.0049 16.2044C10.9704 16.2358 10.9296 16.2515 10.8762 16.2515C10.8292 16.2515 10.7883 16.2358 10.757 16.2075C10.7256 16.1761 10.7067 16.1353 10.7067 16.082V15.79C10.3897 15.768 10.0883 15.6958 9.80891 15.5766C9.7681 15.5609 9.73357 15.5295 9.70532 15.4918C9.67707 15.451 9.66137 15.407 9.66137 15.3599C9.66137 15.3003 9.68335 15.2469 9.72415 15.203C9.76496 15.159 9.81833 15.1339 9.88111 15.1339C9.91565 15.1339 9.95018 15.1433 9.98157 15.1559C10.2139 15.2595 10.4556 15.3191 10.7067 15.3348V14.3491C10.509 14.2958 10.3426 14.2393 10.2076 14.1765C10.0632 14.1105 9.94076 14.0164 9.84344 13.8971C9.74299 13.7747 9.6959 13.6177 9.6959 13.4325C9.6959 13.2692 9.73671 13.1186 9.81519 12.9804C9.89367 12.8423 10.0161 12.7262 10.1731 12.6351C10.3206 12.5504 10.5027 12.497 10.7067 12.475V12.1894C10.7067 12.1423 10.7224 12.0983 10.757 12.0669C10.8197 11.9979 10.9327 11.9979 11.0018 12.0638C11.0363 12.0983 11.052 12.1391 11.052 12.1894V12.4687C11.3377 12.4844 11.5983 12.5347 11.8211 12.6194C11.9185 12.654 11.9687 12.7293 11.9687 12.8329C11.9687 12.8894 11.9498 12.9428 11.9122 12.9867C11.8714 13.0338 11.818 13.0589 11.7552 13.0589C11.7332 13.0589 11.705 13.0526 11.6705 13.0369C11.479 12.971 11.2718 12.9302 11.052 12.9177V13.9379C11.2624 13.9881 11.4382 14.0446 11.5763 14.1011C11.7238 14.1639 11.8494 14.2581 11.9498 14.3837C12.0503 14.5092 12.1005 14.6756 12.1005 14.8796C12.1005 15.046 12.0566 15.1998 11.9655 15.3348Z'
        fillOpacity='0.8'
      />
      <path
        d='M18.9722 7.92366C18.947 7.61916 18.6959 7.38686 18.3914 7.38686H17.1954C17.1922 7.38686 17.1922 7.38686 17.1891 7.38686H15.3715V5.97108C15.3715 3.77992 13.5884 2 11.4004 2C9.20925 2 7.42933 3.78306 7.42933 5.97108V7.38686H5.60546C5.3041 7.38686 5.04982 7.6223 5.02785 7.92052L4.00447 20.7033C3.97308 21.0989 4.10806 21.4944 4.37803 21.7864C4.648 22.0783 5.03099 22.2447 5.42652 22.2447H15.2177H15.315H18.5703C18.969 22.2447 19.352 22.0783 19.622 21.7832C19.8919 21.4913 20.0269 21.0957 19.9955 20.7033L18.9722 7.92366ZM8.69757 5.97736C8.69757 4.48938 9.9093 3.27765 11.3973 3.27765C12.8853 3.27765 14.097 4.48938 14.097 5.97736V7.39314H8.69757V5.97736ZM15.2145 21.937H5.42339C5.10947 21.937 4.81752 21.8083 4.60406 21.5792C4.39059 21.3469 4.287 21.0486 4.31211 20.7347L5.33549 7.95192C5.34804 7.81379 5.46419 7.70706 5.60232 7.70706H7.42619V9.43989C7.42619 9.79148 7.71186 10.0772 8.06345 10.0772C8.41504 10.0772 8.70071 9.79148 8.70071 9.43989V7.70706H14.1001V9.43989C14.1001 9.79148 14.3858 10.0772 14.7374 10.0772C15.089 10.0772 15.3746 9.79148 15.3746 9.43989V7.70706H17.1985C17.2738 7.70706 17.3429 7.73845 17.3963 7.79182C17.4465 7.84832 17.4716 7.92052 17.4653 7.99273L16.4294 20.9105C16.3823 21.485 15.8957 21.937 15.3181 21.937H15.2145Z'
        fillOpacity='0.8'
      />
    </svg>
  );
}
