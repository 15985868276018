export default function WalletSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 22'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_3844:2975)'>
        <path d='M22.3633 18H18.5452C16.1392 18 14.1816 16.2056 14.1816 14.0001C14.1816 11.7946 16.1392 10.0002 18.5452 10.0002H22.3633C22.6648 10.0002 22.9088 9.77656 22.9088 9.50018V8.00021C22.9088 6.95173 22.0216 6.09904 20.9006 6.01635L17.7681 1.00081C17.4778 0.536941 17.009 0.2054 16.4481 0.0677302C15.8899 -0.0690019 15.3061 0.00280941 14.8065 0.269383L4.06783 6.00023H2.1818C0.97853 6.00023 0 6.89716 0 8.00021V20C0 21.103 0.978479 22 2.1818 22H20.727C21.9303 22 22.9088 21.1031 22.9088 20V18.5C22.9088 18.2237 22.6648 18 22.3633 18ZM18.4445 4.09732L19.633 6.00023H14.8789L18.4445 4.09732ZM6.23598 6.00023L15.3556 1.1336C15.6022 1.00128 15.8904 0.966122 16.1658 1.03353C16.4444 1.10187 16.6766 1.26691 16.821 1.49786L16.8221 1.49969L8.38935 6.00023H6.23598Z' />
        <path d='M22.3623 11H18.5442C16.7395 11 15.2715 12.3457 15.2715 14C15.2715 15.6542 16.7395 16.9999 18.5442 16.9999H22.3623C23.2646 16.9999 23.9986 16.3271 23.9986 15.4999V12.5C23.9986 11.6728 23.2646 11 22.3623 11ZM18.5442 14.9999C17.9428 14.9999 17.4533 14.5512 17.4533 14C17.4533 13.4487 17.9428 13 18.5442 13C19.1455 13 19.635 13.4487 19.635 14C19.6351 14.5512 19.1456 14.9999 18.5442 14.9999Z' />
      </g>
      <defs>
        <clipPath id='clip0_3844:2975'>
          <rect width='24' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
