export default function SpoonForkSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M8 5.3334C8 2.38801 6.65559 0 5 0C3.34379 0 2 2.38801 2 5.3334C2 6.6152 2.80801 7.70121 3.93879 8.1302L3.6666 14.6666C3.6666 15.403 4.26359 16 5 16C5.73641 16 6.3334 15.403 6.3334 14.6666L6.06121 8.13078C7.19281 7.70121 8 6.6152 8 5.3334Z' />
      <path d='M14 4.8334L13.8334 0H13L12.8334 4.8334H12.1666L12 0H11L10.8334 4.8334H10.1666L10 0H9.1666L9 4.8334C9 5.8502 9.60941 6.72121 10.4818 7.11199L10.1666 14.6666C10.1666 15.403 10.7636 16 11.5 16C12.2364 16 12.8334 15.403 12.8334 14.6666L12.5182 7.11199C13.3906 6.72121 14 5.8502 14 4.8334Z' />
    </svg>
  );
}
