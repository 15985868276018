export default function LinkSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M8.46492 20.5346C6.44276 20.5346 4.6197 19.3164 3.84572 17.4483C3.07175 15.5801 3.49925 13.4296 4.92892 11.9995L7.05092 9.87753L8.46492 11.2915L6.34392 13.4125C5.58598 14.1705 5.28997 15.2752 5.5674 16.3106C5.84482 17.3459 6.65354 18.1546 7.6889 18.4321C8.72426 18.7095 9.82898 18.4135 10.5869 17.6555L12.7079 15.5345L14.1219 16.9495L12.0009 19.0705C11.065 20.0111 9.79178 20.5382 8.46492 20.5346ZM9.17192 16.2415L7.75792 14.8275L14.8289 7.75653L16.2429 9.17053L9.17292 16.2405L9.17192 16.2415ZM16.9509 14.1205L15.5359 12.7065L17.6569 10.5855C18.4252 9.82986 18.7291 8.7202 18.4532 7.67851C18.1772 6.63683 17.3637 5.82318 16.3221 5.54698C15.2805 5.27077 14.1708 5.57445 13.4149 6.34253L11.2929 8.46353L9.87892 7.04953L12.0009 4.92753C13.956 2.9895 17.1099 2.9964 19.0565 4.94298C21.003 6.88956 21.01 10.0434 19.0719 11.9985L16.9509 14.1195V14.1205Z' />
    </svg>
  );
}
