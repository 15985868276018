export default function MessageSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        d='M17.8794 8.7832L13.4433 12.3549C12.6038 13.0131 11.427 13.0131 10.5875 12.3549L6.11328 8.7832'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.08342 3H16.8885C18.3023 3.01586 19.6479 3.61353 20.612 4.65382C21.576 5.69411 22.0744 7.08622 21.991 8.50592V15.295C22.0744 16.7147 21.576 18.1068 20.612 19.1471C19.6479 20.1874 18.3023 20.785 16.8885 20.8009H7.08342C4.0467 20.8009 2 18.3304 2 15.295V8.50592C2 5.47048 4.0467 3 7.08342 3Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
