export default function MegaphoneSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      fill='currentColor'
      viewBox='0 0 24 23'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_3844:2999)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.45253 13.6129C2.09266 13.6123 0.162109 11.8299 0.162109 9.65144C0.162109 7.4727 2.09327 5.69009 4.45361 5.69009L8.1475 5.69014C8.1475 8.29867 8.24331 11.0377 8.1475 13.6129H4.45253ZM8.20192 15.1125C8.27603 15.7695 8.41923 16.4203 8.64316 17.0415C8.98201 17.9815 9.52825 18.9031 10.3318 19.5733C10.4865 19.7023 10.5472 19.8832 10.5067 20.0711L10.1493 21.7295C10.1109 21.9082 9.98908 22.0447 9.80589 22.1128C9.61726 22.1829 9.43164 22.1629 9.26509 22.0559C7.94219 21.2059 6.97605 19.957 6.34445 18.6011C5.8307 17.4982 5.52714 16.3116 5.40686 15.1125H8.20192ZM9.77214 5.51459V13.7573C14.3381 14.6747 18.1352 17.1919 21.7634 19.2791C22.2026 19.5319 22.7545 19.3727 23.1804 19.1127C23.6063 18.8528 23.8388 18.4646 23.8388 18.0132V1.37035C23.8388 0.920482 23.608 0.533439 23.1844 0.273261C22.7608 0.0131261 22.2419 -0.0963018 21.7729 0.101525C18.4776 1.49224 14.2813 4.42083 9.77214 5.51459Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_3844:2999'>
          <rect width='24' height='22.1538' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
