import { Layout } from 'antd';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import NoBusinessNavBar from 'components/NoBusinessNavBar';
import NoBusinessView from 'views/no-business/NoBusiness';
import CreateBusinessView from 'views/no-business/CreateBusiness';

const { Content } = Layout;

export default function NoBusinessRouter() {
  return (
    <BrowserRouter basename='/'>
      <Layout className='cc-layout'>
        <NoBusinessNavBar />

        <Content className='cc-layout-nbv-content'>
          <Switch>
            <Route path='/no-business' component={NoBusinessView} />
            <Route path='/create-business' component={CreateBusinessView} />
            <Route path='*' render={() => <Redirect to='/no-business' />} />
          </Switch>
        </Content>
      </Layout>
    </BrowserRouter>
  );
}
