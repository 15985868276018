import { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Typography,
  Modal,
  Spin,
  message,
  Select,
  Skeleton,
  Image,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import { normalizeProduct } from 'utils/products/normalize-product';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import I18nContext from 'contexts/I18nContext';
import UserContext from 'contexts/UserContext';
import placeholderImg from 'assets/images/placeholder.png';
import DimensionsContext from '../DimensionsContext';
import UpdateDimensionForm from './UpdateDimensionForm';
import './style.css';

const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

const { Option } = Select;

export default function VirtualSupportView() {
  const [isVisible, setIsVisible] = useState(false);
  const [dimension, setDimension] = useState({});
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addingToCart, setAddingToCart] = useState(false);
  const [unityContainer, setUnityContainer] = useState(null);
  const [customerId, setCustomerId] = useState(-1);

  const { dimensions } = useContext(DimensionsContext);
  const { language } = useContext(I18nContext);
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['products', 'common']);

  useDeepCompareEffect(() => {
    const dimension = dimensions?.find(
      (dimension) => Number(dimension.id) === Number(id),
    );

    if (dimension) {
      setDimension(dimension);
      setLoading(false);
    } else history.push('/manage-dimensions');
  }, [dimensions]);

  useEffect(() => {
    window.updateSupportedUser = function (userId) {
      try {
        console.log('userId', userId);
        setCustomerId(userId);
      } catch (ignored) {}
    };

    if (window.unityInstance) {
      window.unityInstance.Quit();
    }

    const oldScript = document.getElementById('unity-script');
    if (oldScript) {
      oldScript.remove();
    }

    if (unityContainer) {
      const unityScript = document.createElement('script');
      unityScript.id = 'unity-script';
      unityScript.type = 'text/javascript';
      unityScript.async = true;

      unityScript.innerHTML = `
      var apiBase = 'https://portal.chickchack.net:7005/api/v6/mobile-game/';
      var socketBase = "https://portal.chickchack.net:7005/";
      var dimensionId = ${dimension.id};
      var accessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY3VzdG9tZXItZ2FtZSIsImlkIjoxNywiaWF0IjoxNjUxNzEwMTM3fQ.pUigwzUJXj17I5MOAc1cpR8OkCXd3W-3LYnMnJEZb7w';
      var unityLoaded = true;

      var buildUrl = '${toAbsoluteUrl('/WebGL-Support/Build')}';
      var loaderUrl = buildUrl + '/WebGL.loader.js';
      var config = {
        dataUrl: buildUrl + "/WebGL.data.unityweb",
        frameworkUrl: buildUrl + "/WebGL.framework.js.unityweb",
        codeUrl: buildUrl + "/WebGL.wasm.unityweb",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "PiPhi Technology",
        productName: "PiPhi",
        productVersion: "0.9",
      };
  
        var container = document.querySelector('#unity-container');
        var canvas = document.querySelector('#unity-canvas');
        var loadingBar = document.querySelector('#unity-loading-bar');
        var progressBarFull = document.querySelector('#unity-progress-bar-full');
        var mobileWarning = document.querySelector('#unity-mobile-warning');
  
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          container.className = 'unity-mobile';
          config.devicePixelRatio = 1;
          mobileWarning.style.display = 'block';
          setTimeout(() => {
            mobileWarning.style.display = 'none';
          }, 5000);
        } 
  
        loadingBar.style.display = 'block';
        
        if(!window.UnityLoader) {
          var script = document.createElement('script');
          window.UnityLoader = script;
          script.src = loaderUrl;
          script.onload = () => {
            createUnityInstance(canvas, config, (progress) => {
              progressBarFull.style.width = 100 * progress + '%';
            })
              .then((unityInstance) => {
                window.unityInstance = unityInstance;
                loadingBar.style.display = 'none';
              })
              .catch((message) => {
                alert(message);
              });
          };
          document.body.appendChild(script);
        } else {
          createUnityInstance(canvas, config, (progress) => {
            progressBarFull.style.width = 100 * progress + '%';
          })
            .then((unityInstance) => {
              window.unityInstance = unityInstance;
              unityInstance.SendMessage("VirtualSupport", "UpdateSupportedUser");
              loadingBar.style.display = 'none';
            })
            .catch((message) => {
              alert("Something Went Wrong");
              console.log(message);
            });
        }
        `;

      document.body.appendChild(unityScript);
    }
    return () => {
      if (window.unityLoaded) {
        try {
          window.unityInstance.SendMessage('BG_Scripts', 'GiveUpKeyboard');
        } catch (error) {}

        // window.unityInstance.Quit();
        window.unityLoaded = false;
        window.updateSupportedUser = () => {};
        window.location.reload();
      }
    };
  }, [dimension, unityContainer]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const {
          data: { data: products },
        } = await axios.get(
          `/seller/product/${user?.selectedBusiness?.id}/get-list`,
        );

        setProducts(products.map(normalizeProduct));
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.selectedBusiness?.id]);

  const addToCart = async () => {
    if (!selectedProduct) return;

    setAddingToCart(true);
    try {
      if (!customerId || customerId === -1) {
        message.error('You must select a customer to add to cart');
        return;
      }

      await axios.post('/seller/cart/add-to-cart', {
        productId: selectedProduct,
        customerId,
      });

      message.success('Product was added to cart');
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setAddingToCart(false);
      setSelectedProduct(null);
    }
  };

  return loading ? (
    <Spin spinning={loading} />
  ) : (
    <>
      <Row>
        <Col span={24}>
          <Typography className='cc-sc cc-fz-24 cc-fw-600'>
            Virtual Support
          </Typography>
        </Col>

        <Divider />

        <Col span={24}>
          <Card
            className='vsv-card'
            title={
              <Row justify='space-between' align='center'>
                <Col className='cc-center-items'>
                  <Space direction='vertical' align='start' size={2}>
                    <Typography
                      style={{ textTransform: 'capitalize' }}
                      className='cc-sc cc-fz-18 cc-fw-600'>
                      {dimension.name[language.code] || dimension.name.en}
                    </Typography>

                    {dimension.streamingLink ? (
                      <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                        Streaming Link:{' '}
                        <Typography className='cc-pc cc-fw-400'>
                          {dimension.streamingLink}
                        </Typography>
                      </Typography>
                    ) : null}

                    {dimension.streamerToken ? (
                      <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                        Streamer Token:{' '}
                        <Typography className='cc-pc cc-fw-400'>
                          {dimension.streamerToken}
                        </Typography>
                      </Typography>
                    ) : null}
                  </Space>
                </Col>
                <Col className='cc-center-items'>
                  <Space direction='horizontal' align='center' size={10}>
                    <Select
                      style={{ width: 320 }}
                      showSearch
                      notFoundContent={!products?.length && <Skeleton />}
                      placeholder='Search products to add to cart...'
                      filterOption={(input, option) =>
                        option?.name
                          ?.toLowerCase()
                          ?.includes(input.toLowerCase())
                      }
                      value={selectedProduct}
                      onChange={(value) => setSelectedProduct(value)}>
                      {products.map((product) => (
                        <Option
                          name={product?.name?.en || product?.name?.ar}
                          key={product.id}
                          value={product.id}>
                          <Space
                            direction='horizontal'
                            align='center'
                            size={10}>
                            <Image
                              style={{ objectFit: 'contain' }}
                              src={product.image || placeholderImg}
                              width={30}
                              height={30}
                            />

                            <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                              {product?.name?.en || product?.name?.ar}
                            </Typography>
                          </Space>
                        </Option>
                      ))}
                    </Select>

                    <Button
                      onClick={addToCart}
                      className='cc-fz-14 cc-fw-500'
                      type='primary'
                      loading={addingToCart}
                      disabled={!selectedProduct}>
                      Add to cart
                    </Button>
                  </Space>
                </Col>
              </Row>
            }>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Row justify='end'>
                  <Button
                    onClick={() => {
                      if (window.unityInstance) {
                        window.unityInstance.SetFullscreen(true);
                      }
                    }}
                    className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
                    type='primary'>
                    Full screen
                  </Button>
                </Row>
              </Col>

              <Col span={24}>
                <div
                  style={{
                    width: '100%',
                    height: '700px',
                  }}
                  id='unity-container'
                  className='unity-desktop'
                  ref={(r) => setUnityContainer(r)}>
                  <canvas
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    id='unity-canvas'></canvas>

                  <div id='unity-loading-bar'>
                    <div id='unity-logo'></div>
                    <div id='unity-progress-bar-empty'>
                      <div id='unity-progress-bar-full'></div>
                    </div>
                  </div>

                  <div id='unity-mobile-warning'>
                    WebGL builds are not supported on mobile devices.
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        visible={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width='50%'
        closable={false}
        footer={null}>
        <UpdateDimensionForm
          setIsVisible={setIsVisible}
          dimension={dimension}
        />
      </Modal>
    </>
  );
}
