import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Image,
  Input,
  message,
  Select,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import ScaleSvg from 'assets/jsx-svgs/scale.svg';
import StorageSvg from 'assets/jsx-svgs/storage.svg';
import BrandSvg from 'assets/jsx-svgs/brand.svg';
import LargeTextSvg from 'assets/jsx-svgs/large-text.svg';
import LinkSvg from 'assets/jsx-svgs/link.svg';

import ProductContext from '../contexts/ProductContext';
import MultiLingualInput from 'components/MultiLingualInput';

const { Option } = Select;

export default function ProductInformation({ hideModal }) {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaDescription, setMetaDescription] = useState({});
  const [productDescription, setProductDescription] = useState({});
  const [form] = Form.useForm();

  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { product, productIdx, refetchProduct, brands } =
    useContext(ProductContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation(['products', 'common']);

  const onFinish = async (values) => {
    setLoading(true);

    const reqData = {
      productId: product.id,
      weight: Number(values.weight),
      storageCode: values.storageCode,
      brandId: values.productBrandId,
      libraryId: values.libraryId,
      description: productDescription,
      metaTitle: values.metaTitle,
      pageUrl: values.pageUrl,
      metaDescription,
    };

    try {
      await axios.post(
        `/seller/product/${user?.selectedBusiness?.id}/add-extra-info/${product.id}`,
        reqData,
      );

      await refetchProduct(product.id, productIdx);
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
      hideModal();
    }
  };

  useDeepCompareEffect(() => {
    setMetaDescription(product.metaDescription);
    setProductDescription(product.description);
  }, [product.pageUrl, product.metaDescription]);

  useDeepCompareEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      metaDescription: metaDescription[inputsLang.code],
      productDescription: productDescription[inputsLang.code],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDescription, metaDescription, product, inputsLang]);

  useEffect(() => {
    if (!product.id) hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: models },
        } = await axios.get('/seller/model/get-trader-model');

        setModels(models);
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      }
    })();
  }, [t]);

  return (
    <Form
      name='product-info-form'
      layout='vertical'
      initialValues={product}
      form={form}
      onFinish={onFinish}>
      <Form.Item
        name='weight'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('productWeightLabel')}
          </Typography.Text>
        }>
        <Input
          type='number'
          placeholder={t('productWeightPlaceholder')}
          prefix={<Icon className='cc-gc cc-fz-24' component={ScaleSvg} />}
          addonAfter={
            <Typography.Text className='cc-sc cc-fz-16 cc-fw-500'>
              KG
            </Typography.Text>
          }
        />
      </Form.Item>

      <Form.Item
        name='storageCode'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('storageCodeLabel')}
          </Typography.Text>
        }>
        <Input
          type='text'
          placeholder={t('storageCodePlaceholder')}
          prefix={<Icon className='cc-gc cc-fz-24' component={StorageSvg} />}
        />
      </Form.Item>

      <Form.Item
        className='pv-pi-brand-container'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('brandLabel')}
          </Typography.Text>
        }>
        <Icon
          className='cc-gc cc-fz-24 pv-pi-brand-icon'
          component={BrandSvg}
        />
        <Form.Item name='productBrandId' noStyle>
          <Select
            className='cc-sc cc-fw-500'
            placeholder={t('brandPlaceholder')}
            notFoundContent={!brands?.length && <Skeleton />}>
            {brands.map((brand) => (
              <Option key={brand.id} value={brand.id}>
                {brand.name[inputsLang.code]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        className='pv-pi-brand-container'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('modelLabel')}
          </Typography.Text>
        }>
        <Icon
          className='cc-gc cc-fz-24 pv-pi-brand-icon'
          component={BrandSvg}
        />
        <Form.Item name='libraryId' noStyle>
          <Select
            className='cc-sc cc-fw-500'
            placeholder={t('modelPlaceholder')}
            notFoundContent={!models?.length && <Skeleton />}>
            {models.map((model) => (
              <Option key={model.id} value={model.id}>
                <Space direction='horizontal' align='center' size={3}>
                  <Image
                    style={{ objectFit: 'contain' }}
                    preview={false}
                    alt={model.name}
                    src={model.image}
                    width={30}
                    height={30}
                  />

                  <Typography.Text>{model.name}</Typography.Text>
                </Space>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        className='cc-mb-25'
        name='productDescription'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('productDescLabel')}
          </Typography.Text>
        }>
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 5 }}
          placeholder={t('productDescPlaceholder')}
          onChange={(e) =>
            setProductDescription({
              ...productDescription,
              [inputsLang.code]: e.target.value,
            })
          }
        />
      </Form.Item>

      <Typography className='cc-sc cc-fz-18 cc-fw-600 cc-mb-15'>
        {t('seoTitle')}
      </Typography>

      <Form.Item
        name='metaTitle'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('pageTitleLabel')}
          </Typography.Text>
        }
        rules={[
          {
            type: 'object',
            required: true,
            fields: {
              ar: { type: 'string', required: true },
              en: { type: 'string', required: true },
            },
            message: 'Please enter a value in all languages',
          },
        ]}>
        <MultiLingualInput
          type='text'
          placeholder={t('pageTitlePlaceholder')}
          prefix={<Icon className='cc-gc cc-fz-24' component={LargeTextSvg} />}
          addonAfter={
            <Select
              className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
              defaultValue='en'
              value={inputsLang?.code}
              onChange={(code) =>
                setInputsLang(langs.find((lang) => lang.code === code))
              }>
              {langs?.map((lang) => (
                <Option className='cc-fz-14' key={lang.code} value={lang.code}>
                  {lang.code.toUpperCase()}
                </Option>
              ))}
            </Select>
          }
        />
      </Form.Item>

      <Form.Item
        name='pageUrl'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('pageUrlLabel')}
          </Typography.Text>
        }
        rules={[
          {
            type: 'object',
            required: true,
            fields: {
              ar: { type: 'string', required: true },
              en: { type: 'string', required: true },
            },
            message: 'Please enter a value in all languages',
          },
        ]}>
        <MultiLingualInput
          type='text'
          placeholder={t('pageUrlPlaceholder')}
          prefix={<Icon className='cc-gc cc-fz-24' component={LinkSvg} />}
          addonAfter={
            <Select
              className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
              defaultValue='en'
              value={inputsLang?.code}
              onChange={(code) =>
                setInputsLang(langs.find((lang) => lang.code === code))
              }>
              {langs?.map((lang) => (
                <Option className='cc-fz-14' key={lang.code} value={lang.code}>
                  {lang.code.toUpperCase()}
                </Option>
              ))}
            </Select>
          }
        />
      </Form.Item>

      <Form.Item
        name='metaDescription'
        label={
          <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
            {t('pageDescriptionLabel')}
          </Typography.Text>
        }>
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 5 }}
          placeholder={t('pageDescriptionPlaceholder')}
          onChange={(e) =>
            setMetaDescription({
              ...metaDescription,
              [inputsLang.code]: e.target.value,
            })
          }
        />
      </Form.Item>

      <Space direction='horizontal' size='small'>
        <Button type='primary' htmlType='submit' loading={loading}>
          {t('saveProductInfoBtn')}
        </Button>
        <Button type='primary' onClick={hideModal} ghost>
          {t('cancelBtn')}
        </Button>
      </Space>
    </Form>
  );
}
