import { Typography } from 'antd';

import './style.css';

const { Title } = Typography;

export default function UnderlinedTitle({
  level = 1,
  children = null,
  ...props
}) {
  return (
    <Title level={level} {...props}>
      <span className='cc-p-ut_wrapper'>
        {children}
        <div className='cc-p-ut_line'></div>
      </span>
    </Title>
  );
}
