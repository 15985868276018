export default function EditSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M12.25 16H3.75C1.68225 16 0 14.3177 0 12.25V3.75C0 1.68225 1.68225 0 3.75 0H10.25C10.5952 0 10.875 0.279813 10.875 0.625C10.875 0.970187 10.5952 1.25 10.25 1.25H3.75C2.3715 1.25 1.25 2.3715 1.25 3.75V12.25C1.25 13.6285 2.3715 14.75 3.75 14.75H12.25C13.6285 14.75 14.75 13.6285 14.75 12.25V6.46875C14.75 6.12356 15.0298 5.84375 15.375 5.84375C15.7202 5.84375 16 6.12356 16 6.46875V12.25C16 14.3177 14.3177 16 12.25 16ZM12.0417 7.40119L15.5462 3.21506C15.5469 3.21425 15.5476 3.21341 15.5483 3.21259C16.2383 2.37966 16.1221 1.14056 15.2891 0.450437C14.4562 -0.239625 13.2171 -0.123375 12.527 0.709531C12.5258 0.710969 12.5246 0.712406 12.5234 0.713844L9.07766 4.94931C8.85981 5.21706 8.90028 5.61075 9.16806 5.82859C9.43581 6.04637 9.82947 6.00594 10.0473 5.73819L13.4911 1.50513C13.7418 1.20459 14.1901 1.16319 14.4917 1.413C14.7936 1.66316 14.8359 2.11209 14.5864 2.41428L11.0833 6.59881C10.8617 6.86347 10.8966 7.25766 11.1613 7.47925C11.2783 7.57722 11.4206 7.62503 11.5622 7.625C11.7408 7.625 11.9181 7.54884 12.0417 7.40119Z' />
      <path d='M5.29632 13.5315C4.65167 13.5315 4.02917 13.1819 3.71042 12.6409C3.43876 12.1797 3.42848 11.6527 3.68217 11.1948C3.99523 10.6298 4.17435 10.1052 4.34757 9.59792C4.64273 8.7336 4.92154 7.9172 5.76342 7.21676C6.37476 6.70814 7.14642 6.46948 7.9361 6.54492C8.7271 6.62042 9.4411 7.0017 9.94654 7.61848C10.3839 8.15217 10.6247 8.83014 10.6247 9.52748C10.6247 10.7573 9.88464 11.8555 8.54082 12.6199C7.56742 13.1737 6.29385 13.5315 5.29632 13.5315ZM7.65267 7.78139C7.25532 7.78139 6.87351 7.9192 6.56292 8.17764C5.99098 8.65351 5.79785 9.21907 5.53051 10.0019C5.34857 10.5347 5.14235 11.1386 4.77557 11.8006C4.75704 11.834 4.72257 11.8963 4.78742 12.0064C4.86798 12.1431 5.06867 12.2815 5.29635 12.2815C6.68054 12.2815 9.3747 11.3094 9.3747 9.52748C9.3747 9.11229 9.2381 8.7261 8.97967 8.41073C8.68701 8.05357 8.2742 7.83289 7.81732 7.78926C7.76223 7.78398 7.70729 7.78139 7.65267 7.78139Z' />
    </svg>
  );
}
