import { Button, Col, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';

import UnderlinedTitle from 'components/partials/UnderlinedTitle';
import './style.css';
import WalletOutlinedSvg from 'assets/jsx-svgs/wallet-outlined.svg';
import MoneyBagSvg from 'assets/jsx-svgs/money-bag.svg';

export default function PaymentView() {
  return (
    <Row gutter={[30, 20]}>
      <Col span={24}>
        <UnderlinedTitle level={3}>Wallet</UnderlinedTitle>
      </Col>

      {/* Start: Balance Cards */}
      <Col span={12}>
        <Row className='payv-card' gutter={[10, 20]}>
          <Col span={24}>
            <Typography className='cc-fz-16 cc-fw-500 payv-bal-card-title'>
              <Icon className='cc-fz-24' component={WalletOutlinedSvg} /> Store
              Balance
            </Typography>
          </Col>

          <Col className='cc-center-items' span={24}>
            <Typography.Text className='cc-sc cc-fw-600 payv-bal-card-credit'>
              150.0
              <Typography.Text className='cc-gc cc-fw-500 payv-bal-card-curr'>
                AED
              </Typography.Text>
            </Typography.Text>
          </Col>

          <Col className='cc-center-items' span={24}>
            <Space direction='horizontal' align='center' size='middle'>
              <Button type='primary'>Add Balance</Button>
              <Button type='primary' ghost>
                Withdraw
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row className='payv-card' gutter={[10, 20]}>
          <Col span={24}>
            <Typography className='cc-fz-16 cc-fw-500 payv-bal-card-title'>
              <Icon className='cc-fz-24' component={MoneyBagSvg} /> E-payment
              Balance
            </Typography>
          </Col>

          <Col className='cc-center-items' span={24}>
            <Typography.Text className='cc-sc cc-fw-600 payv-bal-card-credit'>
              0.0
              <Typography.Text className='cc-gc cc-fw-500 payv-bal-card-curr'>
                AED
              </Typography.Text>
            </Typography.Text>
          </Col>

          <Col className='cc-center-items' span={24}>
            <Space direction='horizontal' align='center' size='middle'>
              <Button type='primary'>Add Balance</Button>
              <Button type='primary' ghost>
                Withdraw
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
      {/* End: Balance Cards */}
    </Row>
  );
}
