import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Image,
  message,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';
import DummyImgSvg from 'assets/jsx-svgs/dummy-img.svg';
import RadioUnSelectedSvg from 'assets/jsx-svgs/radio-unselected.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import RadioSelectedSvg from 'assets/jsx-svgs/radio-selected.svg';

import ProductContext from './contexts/ProductContext';

const { Dragger } = Upload;

export default function ProductImages({ hideModal }) {
  const [spinning, setSpinning] = useState({ spinning: false, tip: '' });
  const { user } = useContext(UserContext);
  const { product, productIdx, refetchProduct } = useContext(ProductContext);
  const { t } = useTranslation(['products', 'common']);

  const onChange = async ({ file }) => {
    try {
      if (file.percent === 100 && file.status === 'done') {
        await refetchProduct(product.id, productIdx);

        setSpinning({ spinning: false, tip: '' });
        message.success('Image uploaded successfully');
      } else {
        setSpinning({ spinning: true, tip: t('uploadingImgLoadingTxt') });
      }
    } catch (error) {
      setSpinning({ spinning: false, tip: '' });
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    }
  };

  const setAsDefaultImage = async (imgId) => {
    setSpinning({ spinning: true, tip: t('settingDefaultImgLoadingTxt') });
    try {
      await axios.put(
        `/seller/product/${user?.selectedBusiness?.id}/update-product-default-image`,
        {
          imageId: imgId,
          productId: product.id,
        },
      );

      await refetchProduct(product.id, productIdx);
      message.success('Default image set successfully');
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setSpinning({ spinning: false, tip: '' });
    }
  };

  const deleteImg = async (imgId) => {
    setSpinning({ spinning: true, tip: t('deleteImgLoadingTxt') });
    try {
      await axios.delete(
        `/seller/product/${user?.selectedBusiness?.id}/image/delete/${imgId}`,
      );

      await refetchProduct(product.id, productIdx);
      message.success('Image deleted successfully');
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setSpinning({ spinning: false, tip: '' });
    }
  };

  useEffect(() => {
    if (!product.id) hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  return (
    <Spin spinning={spinning.spinning} tip={spinning.tip}>
      <Row>
        <Col className='cc-mb-25 pv-product-imgs-dragger-container' span={24}>
          <Dragger
            className='cc-dragger'
            listType='picture'
            name='image'
            action={`https://portal.chickchack.net:7005/api/v6/customer-dashboard/seller/product/${user?.selectedBusiness?.id}/image/add/${product.id}`}
            onChange={onChange}
            withCredentials
            itemRender={() => null}>
            <div className='cc-dragger-icon'>
              <Icon component={DummyImgSvg} />
            </div>
            <div className='cc-fw-500'>
              <Typography className='cc-sc'>
                {t('productImgsDropImgTxt')}
              </Typography>
              <Typography className='cc-pc'>
                {t('productImgsBrowseTxt')}
              </Typography>
            </div>
          </Dragger>
        </Col>

        {product?.productImages && product?.productImages?.length ? (
          <Col span={24}>
            <Row gutter={15}>
              {product.productImages.map((image, idx) => (
                <Col key={`${image.id}-${idx}`} className='cc-mb-15' span={12}>
                  <div className='cc-w-100 pv-product-imgs-img-container'>
                    <Image src={image.url} width='100%' />

                    <Row
                      className='pv-product-imgs-actions-container'
                      justify='space-between'>
                      <Col
                        className='cc-center-items cc-clickable'
                        onClick={() => setAsDefaultImage(image.id)}>
                        <Icon
                          className='cc-fz-18 pv-mr-5'
                          component={
                            product.image === image.url
                              ? RadioSelectedSvg
                              : RadioUnSelectedSvg
                          }
                        />

                        <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
                          {t('thumbnailTxt')}
                        </Typography.Text>
                      </Col>

                      <Col>
                        <Icon
                          className='cc-fz-18 cc-center-items pv-rc'
                          component={DeleteSvg}
                          onClick={() => deleteImg(image.id)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        ) : null}

        <Button
          className='cc-fz-18'
          style={{ padding: '7.4px 25px' }}
          type='primary'
          onClick={hideModal}>
          {t('closeBtn')}
        </Button>
      </Row>
    </Spin>
  );
}
