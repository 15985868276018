import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Row,
  Typography,
  Card,
  Form,
  Radio,
  Divider,
  Space,
  Avatar,
  Image,
  Tag,
} from 'antd';
import Icon from '@ant-design/icons';

import SearchSvg from 'assets/jsx-svgs/search.svg';
import ArrowDownSvg from 'assets/jsx-svgs/arrow-down.svg';
import PhoneSvg from 'assets/jsx-svgs/phone.svg';
import WhatsAppSvg from 'assets/jsx-svgs/whatsapp.svg';
import SmsSvg from 'assets/jsx-svgs/sms.svg';
import EmailSignSvg from 'assets/jsx-svgs/email-sign.svg';
import img8 from 'assets/temp/8.png';
import img10 from 'assets/temp/10.png';

import UnderlinedTitle from 'components/partials/UnderlinedTitle';

import OrdersContext from '../OrdersContext';
import dummyData from '../dummy-data';
import './style.css';
import CheckoutSvg from 'assets/jsx-svgs/checkout.svg';

export default function OrderDetailsView() {
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState(null);
  const [isStatusOverlayVisible, setIsStatusOverlayVisible] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const { orders } = useContext(OrdersContext);

  const orderId = Number(id);

  const onSearchChange = (e) => {
    console.log(`search value:`, e.target.value);
  };

  useEffect(() => {
    if (!orderId || isNaN(orderId)) history.push('/orders');
    else {
      // fetch order data from api
      const order = dummyData.find((order) => order.id === orderId);

      if (order) setOrder(order);
      else history.push('/orders');
    }
  }, [history, orderId, orders]);

  return (
    <>
      <Row className='cc-mb-15' justify='space-between'>
        <Col>
          <UnderlinedTitle level={3}>Order</UnderlinedTitle>
        </Col>

        <Col>
          <Row gutter={15}>
            <Col>
              <Button className='cc-fz-14 cc-fw-500' type='primary'>
                + Add new Order
              </Button>
            </Col>

            <Col>
              <Input
                className='odv-search'
                type='text'
                placeholder='Search for products'
                suffix={
                  <Icon
                    className='cc-sc cc-fz-22 odv-search-icon'
                    component={SearchSvg}
                  />
                }
                onChange={onSearchChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='odv-card cc-mb-25' justify='space-between'>
        <Col>
          <Typography className='cc-gc cc-fz-12 cc-fw-600'>
            #OrderNumber
          </Typography>
          <Typography className='cc-sc cc-fz-14 cc-fw-600'>983209</Typography>
        </Col>

        <Col>
          <Typography className='cc-gc cc-fz-12 cc-fw-600'>
            Order Date
          </Typography>
          <Typography className='cc-sc cc-fz-14 cc-fw-600'>
            10-05-2021
          </Typography>
        </Col>

        <Col>
          <Dropdown
            overlay={
              <Form name='odv-status-form' requiredMark={false}>
                <Radio.Group className='odv-status-overlay'>
                  <Radio
                    className='cc-w-100 cc-sc cc-fz-12 cc-fw-500 odv-status-overlay-item'
                    value={1}>
                    Shipped
                  </Radio>

                  <Divider className='odv-divider' />

                  <Radio
                    className='cc-w-100 cc-sc cc-fz-12 cc-fw-500 odv-status-overlay-item'
                    value={2}>
                    Processing
                  </Radio>

                  <Divider className='odv-divider' />

                  <Radio
                    className='cc-w-100 cc-sc cc-fz-12 cc-fw-500 cc-mb-5 odv-status-overlay-item'
                    value={3}>
                    Pinned
                  </Radio>

                  <Row justify='center'>
                    <Col span={20}>
                      <Button
                        className='cc-w-100 cc-fz-14 cc-mb-5 odv-status-overlay-btn'
                        type='primary'
                        htmlType='submit'
                        size='small'
                        onClick={() => setIsStatusOverlayVisible(false)}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form>
            }
            visible={isStatusOverlayVisible}
            onVisibleChange={setIsStatusOverlayVisible}
            trigger={['click']}
            placement='bottomRight'>
            <Button className='cc-gc cc-fz-12 cc-fw-500 odv-bg-fff'>
              Status <Icon component={ArrowDownSvg} />
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>
          <Card
            className='odv-ant-card'
            title={
              <Typography className='cc-sc cc-fz-14 cc-fw-600'>
                Client
              </Typography>
            }
            extra={
              <Button className='cc-fz-12 cc-fw-500' type='primary'>
                Client Options
              </Button>
            }>
            <Space direction='horizontal' size='middle' align='start'>
              <Avatar
                size={53}
                src={
                  <Image
                    className='cc-mb-5'
                    src={img8}
                    width='100%'
                    preview={false}
                  />
                }
              />

              <Space direction='vertical' size='small'>
                <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                  Mhammed Ahmad
                </Typography>

                <Space direction='horizontal' size='middle'>
                  <Typography className='cc-gc cc-fz-14'>
                    +972597531480
                  </Typography>

                  <a href='tel:+972597531480' target='_blank' rel='noreferrer'>
                    <Tag className='cc-pc odv-tag' color='#F4F0F3'>
                      <Icon component={PhoneSvg} />
                      <Typography.Text className='cc-pc cc-fz-10 cc-fw-500'>
                        Call
                      </Typography.Text>
                    </Tag>
                  </a>
                </Space>

                <Space direction='horizontal' size='middle'>
                  <a
                    className='cc-center-items'
                    href='https://wa.me/+972597531480'
                    target='_blank'
                    rel='noreferrer'>
                    <Icon className='cc-sc' component={WhatsAppSvg} />
                    <Typography.Text className='cc-sc cc-fz-10 cc-fw-500 odv-ml-5'>
                      What’s App
                    </Typography.Text>
                  </a>

                  <a
                    className='cc-center-items'
                    href='sms:+972597531480'
                    target='_blank'
                    rel='noreferrer'>
                    <Icon className='cc-sc' component={SmsSvg} />
                    <Typography.Text className='cc-sc cc-fz-10 cc-fw-500 odv-ml-5'>
                      SMS
                    </Typography.Text>
                  </a>

                  <a
                    className='cc-center-items'
                    href='mailto:example@example.com'
                    target='_blank'
                    rel='noreferrer'>
                    <Icon className='cc-sc' component={EmailSignSvg} />
                    <Typography.Text className='cc-sc cc-fz-10 cc-fw-500 odv-ml-5'>
                      Email
                    </Typography.Text>
                  </a>
                </Space>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            className='odv-ant-card'
            title={
              <Typography className='cc-sc cc-fz-14 cc-fw-600'>
                Shipping
              </Typography>
            }
            extra={
              <Button className='cc-fz-12 cc-fw-500' type='primary'>
                Shipping Info.
              </Button>
            }>
            <Space direction='horizontal' size='middle' align='start'>
              <Avatar
                size={53}
                src={
                  <Image
                    className='cc-mb-5'
                    src={img10}
                    width='100%'
                    preview={false}
                  />
                }
              />

              <Space direction='vertical' size='small'>
                <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                  UAE, Dubai
                </Typography>

                <Typography.Paragraph className='cc-gc cc-fz-14 cc-fw-500'>
                  16 Hassan Moussa El-Akkad, El-Horreya street
                </Typography.Paragraph>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            className='odv-ant-card'
            title={
              <Typography className='cc-sc cc-fz-14 cc-fw-600'>
                Payment
              </Typography>
            }
            extra={
              <Button className='cc-fz-12 cc-fw-500' type='primary'>
                Payment Info.
              </Button>
            }>
            <Space direction='vertical' size='small'>
              <Space direction='horizontal' size='middle'>
                <Icon className='cc-sc' component={CheckoutSvg} />

                <Typography className='cc-sc cc-fz-14 cc-fw-500'>
                  Cash on delivery
                </Typography>
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
}
