import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import deepEqual from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import {
  reduceOptionsToSkus,
  reduceOptionsToSkusWithIds,
} from 'utils/products/reduce-options-to-skus';
import { normalizeOptionsAndSkus } from 'utils/products/normalize-product';
import MultiLingualInput from 'components/MultiLingualInput';
import BoxesSvg from 'assets/jsx-svgs/boxes.svg';
import PriceTagSvg from 'assets/jsx-svgs/price-tag.svg';
import LargeTextSvg from 'assets/jsx-svgs/large-text.svg';

import ProductContext from '../contexts/ProductContext';
import ValuesList from './ValuesList';

const { Option } = Select;
const { Panel } = Collapse;

export default function OptionsAndQty({ hideModal }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { user } = useContext(UserContext);
  const { product } = useContext(ProductContext);
  const { t } = useTranslation(['products', 'common']);

  const fetchOptionsAndSkus = async () => {
    if (product?.id) {
      try {
        const {
          data: { data: response },
        } = await axios.get(
          `/seller/product/${user?.selectedBusiness?.id}/get-options/${product.id}`,
        );

        const norm = normalizeOptionsAndSkus({
          options: response.options,
          skus: response.skus,
        });

        setData({
          hasOption: response.hasOption,
          options: norm.options,
          skus: norm.skus,
        });
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      }
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    const optionsReqData = {
      hasOption: true,
      productId: product.id,
      options: values.options,
    };

    try {
      const {
        data: { data: createdOptions },
      } = await axios.put(
        `/seller/product/${user?.selectedBusiness?.id}/edit-options`,
        optionsReqData,
      );

      const skusWithIds = reduceOptionsToSkusWithIds(createdOptions);
      const skus = form.getFieldValue('skus');

      const skusReqData = { productId: product.id };
      skusReqData.skus = skus.map((sku, idx) => ({
        price: sku.price || null,
        quantity: sku.quantity || null,
        values: skusWithIds[idx].values,
      }));

      await axios.put(
        `/seller/product/${user?.selectedBusiness?.id}/edit-skus`,
        skusReqData,
      );

      await fetchOptionsAndSkus();
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    } finally {
      setLoading(false);
      hideModal();
    }
  };

  const onFieldsChange = (changedFields) => {
    let changed = false;
    changedFields.forEach((field) => {
      changed = field.name.includes('options');
    });

    const options = form.getFieldValue('options');

    if (changed) {
      if (!options?.length)
        form.setFieldsValue({ hasOption: false, options: [], skus: [] });
      else {
        let skus = reduceOptionsToSkus(options);

        const oldSkus = form.getFieldValue('skus');

        if (oldSkus?.length)
          skus = skus.map((sku, i) => Object.assign({}, oldSkus[i], sku));

        form.setFieldsValue({ skus });
      }
    }
  };

  useDeepCompareEffect(() => {
    if (product?.hasOption && !product?.options?.length)
      form.setFieldsValue({ hasOption: false });
  }, [product?.hasOption, product?.options?.length]);

  useDeepCompareEffect(() => {
    fetchOptionsAndSkus();
  }, [product?.id]);

  useEffect(() => {
    form.setFieldsValue({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form
      className='pv-options-form'
      name='options-form'
      initialValues={{
        hasOption: product.hasOption,
        options: data.options,
        skus: data.skus,
      }}
      colon={false}
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}>
      <Form.Item
        label={
          <Typography.Text className='cc-sc cc-fz-18 cc-fw-600'>
            {t('activateOptionsTxt')}
          </Typography.Text>
        }
        name='hasOption'
        valuePropName='checked'>
        <Switch
          className='pv-switch'
          onChange={(checked) => {
            if (checked && !form.getFieldValue('options')?.length)
              form.setFieldsValue({
                options: [{ name: {}, type: 'TEXT', values: [{ text: {} }] }],
              });
          }}
        />
      </Form.Item>

      <Form.List name='options'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Form.Item
                key={field.name}
                shouldUpdate={(prev, curr) =>
                  prev?.hasOption !== curr?.hasOption ||
                  !deepEqual(prev.options, curr.options)
                }
                noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('hasOption') && (
                    <>
                      <Row gutter={20}>
                        <Form.Item name={[field.name, 'id']} noStyle>
                          <Input hidden />
                        </Form.Item>

                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'name']}
                            rules={[
                              {
                                type: 'object',
                                required: true,
                                fields: {
                                  ar: { type: 'string', required: true },
                                  en: { type: 'string', required: true },
                                },
                                message:
                                  'Please enter a value in all languages',
                              },
                            ]}>
                            <MultiLingualInput
                              type='text'
                              placeholder={t('optionNamePlaceholder')}
                              prefix={
                                <Icon
                                  className='cc-gc pv-mr-5'
                                  component={LargeTextSvg}
                                />
                              }
                              addonAfter={
                                <Select
                                  className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                                  defaultValue='en'
                                  value={inputsLang?.code}
                                  onChange={(code) =>
                                    setInputsLang(
                                      langs.find((lang) => lang.code === code),
                                    )
                                  }>
                                  {langs?.map((lang) => (
                                    <Option
                                      className='cc-fz-14'
                                      key={lang.code}
                                      value={lang.code}>
                                      {lang.code.toUpperCase()}
                                    </Option>
                                  ))}
                                </Select>
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'type']}
                            rules={[{ type: 'string' }]}>
                            <Select className='cc-sc cc-fw-500'>
                              <Option value='TEXT'>{t('typeText')}</Option>
                              <Option value='COLOR'>{t('typeColor')}</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <ValuesList
                        name={field.name}
                        removeParent={() => remove(field.name)}
                      />
                    </>
                  )
                }
              </Form.Item>
            ))}

            <Form.Item
              shouldUpdate={(prev, curr) => prev?.hasOption !== curr?.hasOption}
              noStyle>
              {({ getFieldValue }) =>
                getFieldValue('hasOption') &&
                getFieldValue('options')?.length ? (
                  <Button
                    className='cc-pc cc-fz-14 cc-fw-500 cc-w-100 cc-custom-btn'
                    type='primary'
                    onClick={() =>
                      add({ name: {}, type: 'TEXT', values: [{ text: {} }] })
                    }>
                    {t('addNewOptionBtn')}
                  </Button>
                ) : null
              }
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        shouldUpdate={(prev, curr) =>
          prev?.hasOption !== curr?.hasOption ||
          prev?.skus?.length !== curr?.skus?.length ||
          !deepEqual(prev?.options, curr?.options)
        }
        noStyle>
        {({ getFieldValue }) =>
          getFieldValue('hasOption') && getFieldValue('skus')?.length ? (
            <>
              <Divider />

              <Form.List name='skus'>
                {(fields) => (
                  <Collapse accordion>
                    {fields.map((field) => (
                      <Panel
                        key={field.name}
                        header={
                          <Typography.Text className='cc-sc cc-fw-400'>
                            {getFieldValue(['skus', field.name]).values.map(
                              (value) =>
                                `${value?.text?.[inputsLang.code] || ''}/`,
                            )}
                          </Typography.Text>
                        }>
                        <Row gutter={15}>
                          <Form.Item name={[field.name, 'id']} noStyle>
                            <Input hidden />
                          </Form.Item>

                          <Col span={12}>
                            <Form.Item
                              className='pv-product-form-item'
                              name={[field.name, 'price']}>
                              <Input
                                type='number'
                                placeholder={t('skuPricePlaceholder')}
                                addonBefore={
                                  <Icon
                                    className='cc-sc cc-fz-20'
                                    component={PriceTagSvg}
                                  />
                                }
                                addonAfter={
                                  <Typography.Text className='cc-sc cc-fz-14 cc-fw-500'>
                                    AED
                                  </Typography.Text>
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              className='pv-product-form-item'
                              name={[field.name, 'quantity']}
                              normalize={(value) => (value < 0 ? 0 : value)}>
                              <Input
                                type='number'
                                placeholder={t('skuQuantityPlaceholder')}
                                addonBefore={
                                  <Icon
                                    className='cc-sc cc-fz-20'
                                    component={BoxesSvg}
                                  />
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    ))}
                  </Collapse>
                )}
              </Form.List>
            </>
          ) : null
        }
      </Form.Item>

      <Divider />

      <Button
        className='cc-w-100'
        type='primary'
        htmlType='submit'
        loading={loading}>
        {t('saveOptionsAndQtyBtn')}
      </Button>
    </Form>
  );
}
