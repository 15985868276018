import { useContext, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Upload,
  Typography,
  Button,
  Select,
  message,
} from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import MultiLingualInput from 'components/MultiLingualInput';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import UserContext from 'contexts/UserContext';
import DummyImgSvg from 'assets/jsx-svgs/dummy-img.svg';
import DimensionsContext from '../DimensionsContext';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';

const { Dragger } = Upload;
const { Option } = Select;

export default function UpdateDimensionForm({ setIsVisible, dimension }) {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(dimension.description);

  const { fetchDimensions } = useContext(DimensionsContext);

  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);

  const onFinish = async (values) => {
    setLoading(true);

    const { image: fileList, ...data } = { ...values, description };
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('image', fileList?.[0]?.originFileObj);

    try {
      await axios.put(
        `/business/dimension/${user?.selectedBusiness?.id}/edit/${dimension.id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      fetchDimensions();
      setIsVisible(false);
    } catch (error) {
      message.error(t('generalError'));
    } finally {
      setLoading(false);
    }
  };

  useDeepCompareEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      description: description[inputsLang.code],
    });
  }, [description, inputsLang]);

  useDeepCompareEffect(() => {
    setDescription(dimension.description);

    form.setFieldsValue({
      name: dimension.name,
      description: dimension.description[inputsLang.code],
    });
  }, [dimension]);

  return (
    <Form
      name='dimension-form'
      layout='vertical'
      form={form}
      onFinish={onFinish}>
      <Row gutter={20}>
        <Col span={16}>
          <Form.Item
            name='name'
            label={`Dimension Name`}
            rules={[
              { required: true },
              {
                type: 'object',
                required: true,
                fields: {
                  ar: { type: 'string', required: true },
                  en: { type: 'string', required: true },
                },
                message: 'Please enter a value in all languages',
              },
            ]}>
            <MultiLingualInput
              type='text'
              placeholder={`Enter Dimension Name...`}
              addonAfter={
                <Select
                  className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                  defaultValue='en'
                  value={inputsLang?.code}
                  onChange={(code) =>
                    setInputsLang(langs.find((lang) => lang.code === code))
                  }>
                  {langs?.map((lang) => (
                    <Option
                      key={lang.code}
                      className='cc-fz-14'
                      value={lang.code}>
                      {lang.code.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              }
            />
          </Form.Item>

          <Form.Item
            name='description'
            label={`Dimension Description`}
            rules={[{ required: true }]}>
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 5 }}
              placeholder={`Enter Dimension Description...`}
              onChange={(e) =>
                setDescription({
                  ...description,
                  [inputsLang.code]: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>

        <Col span={8} className='cc-dragger-container'>
          <Typography className='cc-mb-10 cc-dragger-title'>Images</Typography>

          <Form.Item
            name='image'
            valuePropName='fileList'
            getValueFromEvent={({ fileList }) => fileList}
            noStyle>
            <Dragger
              className='cc-dragger'
              listType='picture'
              maxCount={1}
              customRequest={({ onSuccess }) => onSuccess('ok')}
              defaultFileList={[dimension.image]}
              itemRender={(originNode, file) => {
                if (typeof file === 'string') {
                  return (
                    <div className='ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture'>
                      <div className='ant-upload-list-item-info'>
                        <span className='ant-upload-span'>
                          <div className='ant-upload-list-item-thumbnail'>
                            <img
                              style={{ objectFit: 'contain' }}
                              className='ant-upload-list-item-image'
                              src={file}
                              alt='Dimension'
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                  );
                } else return originNode;
              }}>
              <div className='cc-dragger-icon'>
                <Icon component={DummyImgSvg} />
              </div>
              <div className='cc-fw-500'>
                <Typography className='cc-sc'>Drop your image here</Typography>
                <Typography className='cc-pc'>or browse</Typography>
              </div>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>

      <Button
        type='primary'
        htmlType='submit'
        loading={loading}
        style={{ marginInlineEnd: 15 }}>
        Save
      </Button>

      <Button type='primary' ghost onClick={() => setIsVisible(false)}>
        Cancel
      </Button>
    </Form>
  );
}
