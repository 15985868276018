import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Image,
  Input,
  Layout,
  Menu,
  message,
  Typography,
  Switch,
  Space,
} from 'antd';
import Icon, { LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import en from 'antd/lib/locale/en_US';
import ar from 'antd/lib/locale/ar_EG';

import { dashboardIstance as axios } from 'api/axios';
import UserContext from 'contexts/UserContext';
import I18nContext from 'contexts/I18nContext';
import BurgerMenuSvg from 'assets/jsx-svgs/burger-menu.svg';
import SearchSvg from 'assets/jsx-svgs/search.svg';
import MessageSvg from 'assets/jsx-svgs/message.svg';
import BellSvg from 'assets/jsx-svgs/bell.svg';
import saFlag from 'assets/svgs/flags/sa.svg';
import ukFlag from 'assets/svgs/flags/uk.svg';

const { Header } = Layout;

export default function NavBar({ triggerSideBar }) {
  const { user, setUser } = useContext(UserContext);
  const { setLanguage } = useContext(I18nContext);
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const onSearchChange = (e) => {
    console.log(`search value:`, e.target.value);
  };

  const logout = async () => {
    try {
      await axios.get('/auth/logout');

      setUser(null);
      history.push('/login');
    } catch (error) {
      message.error(t('generalError'));
    }
  };

  const handleLngChange = (checked) => {
    if (checked) {
      setLanguage({
        lang: en,
        code: 'en',
        dir: 'ltr',
      });
    } else {
      setLanguage({
        lang: ar,
        code: 'ar',
        dir: 'rtl',
      });
    }
  };

  return (
    <Header className='cc-layout-navbar'>
      <Icon
        className='cc-sc cc-fz-24'
        component={BurgerMenuSvg}
        onClick={triggerSideBar}
      />

      <div className='cc-center-items' style={{ height: '100%' }}>
        <Input
          className='cc-layout-navbar-search'
          type='text'
          placeholder={t('navBar.searchInputPlaceholder')}
          suffix={
            <Icon
              className='cc-sc cc-fz-22 cc-layout-navbar-search-icon'
              component={SearchSvg}
            />
          }
          onChange={onSearchChange}
        />

        <Badge count={5}>
          <Icon
            className='cc-sc cc-fz-24 cc-layout-navbar-icon'
            component={MessageSvg}
          />
        </Badge>

        <Badge count={2}>
          <Icon
            className='cc-sc cc-fz-24 cc-layout-navbar-icon'
            component={BellSvg}
          />
        </Badge>

        <Dropdown
          trigger={['click']}
          placement='bottomCenter'
          overlay={
            <Menu>
              <Typography
                className='cc-sc cc-fz-12 cc-fw-500 cc-w-100'
                style={{ padding: '5px 12px' }}>
                {user.email}
              </Typography>

              <Menu.Divider />

              {user.businesses.map((businesse) => (
                <Menu.Item
                  key={businesse.id}
                  onClick={() =>
                    setUser({ ...user, selectedBusiness: businesse })
                  }>
                  <Typography className='cc-sc cc-fz-12 cc-fw-500 cc-w-100'>
                    {businesse.handle}
                  </Typography>
                </Menu.Item>
              ))}

              <Menu.Divider />

              <Menu.Item
                key='logout'
                icon={<LogoutOutlined />}
                onClick={logout}>
                <Typography className='cc-sc cc-fz-12 cc-fw-500 cc-w-100'>
                  {t('navBar.logoutBtn')}
                </Typography>
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item key='create-new'>
                <Link to='/create-business'>
                  <Button
                    className='cc-w-100 cc-fz-14'
                    type='link'
                    size='small'>
                    {t('navBar.createNewBusinessBtn')}
                  </Button>
                </Link>
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item className='cc-layout-navbar-lng-change' key='chgLng'>
                <Switch
                  className='cc-layout-navbar-lng-change-switch'
                  onChange={handleLngChange}
                  checkedChildren={
                    <Space direction='horizontal' align='center' size='small'>
                      English <Image preview={false} src={ukFlag} />
                    </Space>
                  }
                  unCheckedChildren={
                    <Space direction='horizontal' align='center' size='small'>
                      Arabic <Image preview={false} src={saFlag} />
                    </Space>
                  }
                  defaultChecked
                />
              </Menu.Item>
            </Menu>
          }>
          <Avatar
            className='cc-clickable cc-layout-navbar-avatar'
            src={
              <Image
                src='https://joeschmoe.io/api/v1/random'
                width='100%'
                preview={false}
              />
            }
          />
        </Dropdown>
      </div>
    </Header>
  );
}
