export default function ScaleSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_6119_7236)'>
        <path d='M23.6454 11.8496L19.8818 4.32231C19.8311 4.16252 19.6933 4.0459 19.5273 4.02231C19.3909 4.02231 19.2546 4.18596 19.1727 4.18596H14.3999C14.2049 3.00218 13.1991 2.12217 11.9999 2.08594C10.8007 2.12211 9.79497 3.00218 9.59989 4.18596H4.82725C4.74545 4.18596 4.60905 4.02231 4.47269 4.02231C4.31087 4.03484 4.17175 4.14185 4.11814 4.29501L0.354554 11.8223C0.155822 11.8601 0.0090831 12.0294 0 12.2315C0 14.4678 1.99092 16.2678 4.47275 16.2678C6.95457 16.2678 8.97274 14.4678 8.97274 12.2587C8.97629 12.0315 8.81462 11.8351 8.59094 11.795L5.23635 5.00412H9.65455C9.79586 5.96552 10.5091 6.74354 11.4546 6.96774V16.7314H12.2727V6.96779C13.3284 6.8576 14.1784 6.05228 14.3454 5.00417H18.7636L15.4091 11.7951C15.1897 11.8228 15.0256 12.0103 15.0273 12.2315C15.0273 14.4405 17.0455 16.2678 19.5273 16.2678C22.0091 16.2678 24.0001 14.4678 24.0001 12.2315C23.9886 12.0356 23.8399 11.8754 23.6454 11.8496ZM7.69093 11.8223H1.28181L4.47275 5.38598L7.69093 11.8223ZM16.3091 11.8223L19.5273 5.38598L22.7182 11.8223H16.3091Z' />
        <path d='M16.636 20.5498H7.36328V21.9134H16.636V20.5498Z' />
        <path d='M12.0001 17.5498C10.4455 17.5498 9.16369 18.368 9.02734 19.7316H14.9728C14.8364 18.368 13.5546 17.5498 12.0001 17.5498Z' />
      </g>
      <defs>
        <clipPath id='clip0_6119_7236'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
