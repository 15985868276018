export default function RadioSelectedSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
        fill='#1B1464'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00078 16.2008C12.9772 16.2008 16.2008 12.9772 16.2008 9.00078C16.2008 5.02433 12.9772 1.80078 9.00078 1.80078C5.02433 1.80078 1.80078 5.02433 1.80078 9.00078C1.80078 12.9772 5.02433 16.2008 9.00078 16.2008Z'
        fill='#F9F9F9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00156 14.3996C11.9839 14.3996 14.4016 11.9819 14.4016 8.99961C14.4016 6.01727 11.9839 3.59961 9.00156 3.59961C6.01922 3.59961 3.60156 6.01727 3.60156 8.99961C3.60156 11.9819 6.01922 14.3996 9.00156 14.3996Z'
        fill='#1B1464'
      />
    </svg>
  );
}
