export default function DigitsSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M9.73828 13.1875V0H6.14453C6.14453 1.80933 4.73394 3.28125 3 3.28125V6.09375C4.15686 6.09375 5.23627 5.74097 6.14453 5.13293V13.1875H3V16H12.4336V13.1875H9.73828Z' />
    </svg>
  );
}
