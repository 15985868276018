import { Link, useHistory } from 'react-router-dom';
import { Image, Layout, Menu, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import logo from 'assets/piphi_logo_nobg.png';
import HomeSvg from 'assets/jsx-svgs/home.svg';
import DimensionsSvg from 'assets/jsx-svgs/dimensions.svg';
import ProductsSvg from 'assets/jsx-svgs/products.svg';
import OrdersSvg from 'assets/jsx-svgs/orders.svg';
import CouponsSvg from 'assets/jsx-svgs/coupons.svg';
import RentSvg from 'assets/jsx-svgs/rent.svg';
import MegaphoneSvg from 'assets/jsx-svgs/megaphone.svg';
import ChatSvg from 'assets/jsx-svgs/chat.svg';
import CarSvg from 'assets/jsx-svgs/car.svg';
import WalletSvg from 'assets/jsx-svgs/wallet.svg';
import GearSvg from 'assets/jsx-svgs/gear.svg';

const { Sider } = Layout;

export default function SideBar({ isCollapsed = false }) {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const defaultSelectedKey = history.location.pathname.split('/')[1] || 'home';

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      className='cc-layout-sidebar'
      width={256}
      theme='light'>
      <div className='cc-layout-sidebar-logo'>
        <Image src={logo} width={45} preview={false} />
        <Typography className='cc-sc cc-fz-22 cc-fw-500 cc-layout-sidebar-logo-title'>
          {t('sideBar.title')}
        </Typography>
      </div>
      <Menu
        className='cc-layout-sidebar-menu'
        mode='inline'
        defaultSelectedKeys={[defaultSelectedKey]}>
        <Menu.ItemGroup>
          <Menu.Item
            className='cc-fz-14'
            key='home'
            title={t('sideBar.home')}
            icon={<Icon component={HomeSvg} />}>
            <Link to='/home'>{t('sideBar.home')}</Link>
          </Menu.Item>

          <Menu.Item
            className='cc-fz-14'
            key='manage-dimensions'
            title={t('sideBar.manageDimensions')}
            icon={<Icon component={DimensionsSvg} />}>
            <Link to='/manage-dimensions'>{t('sideBar.manageDimensions')}</Link>
          </Menu.Item>

          <Menu.Item
            className='cc-fz-14'
            key='products'
            title={t('sideBar.products')}
            icon={<Icon component={ProductsSvg} />}>
            <Link to='/products'>{t('sideBar.products')}</Link>
          </Menu.Item>

          <Menu.Item
            className='cc-fz-14'
            key='orders'
            title={t('sideBar.orders')}
            icon={<Icon component={OrdersSvg} />}>
            <Link to='/orders'>{t('sideBar.orders')}</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.Divider />

        <Menu.ItemGroup title={t('sideBar.marketing')}>
          <Menu.Item
            className='cc-fz-14'
            key='offers'
            title={t('sideBar.offers')}
            icon={<Icon component={CouponsSvg} />}>
            <Link to='/offers'>{t('sideBar.offers')}</Link>
          </Menu.Item>

          <Menu.Item
            className='cc-fz-14'
            key='rent'
            title={t('sideBar.rent')}
            icon={<Icon component={RentSvg} />}>
            <Link to='/rent'>{t('sideBar.rent')}</Link>
          </Menu.Item>

          <Menu.Item
            className='cc-fz-14'
            key='campaigns'
            title={t('sideBar.campaigns')}
            icon={<Icon component={MegaphoneSvg} />}>
            <Link to='/campaigns'>{t('sideBar.campaigns')}</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.Divider />

        <Menu.ItemGroup title={t('sideBar.services')}>
          <Menu.Item
            className='cc-fz-14'
            key='chatbot'
            title={t('sideBar.chatbot')}
            icon={<Icon component={ChatSvg} />}>
            <Link to='/chatbot'>{t('sideBar.chatbot')}</Link>
          </Menu.Item>
          <Menu.Item
            className='cc-fz-14'
            key='delivery'
            title={t('sideBar.delivery')}
            icon={<Icon component={CarSvg} />}>
            <Link to='/delivery'>{t('sideBar.delivery')}</Link>
          </Menu.Item>
          <Menu.Item
            className='cc-fz-14'
            key='payment'
            title={t('sideBar.payment')}
            icon={<Icon component={WalletSvg} />}>
            <Link to='/payment'>{t('sideBar.payment')}</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.Divider />
        <Menu.Item
          className='cc-fz-14'
          key='settings'
          title={t('sideBar.settings')}
          icon={<Icon component={GearSvg} />}>
          <Link to='/settings'>{t('sideBar.settings')}</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
