export default function DocumentSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path d='M14.3271 6C14.327 5.82675 14.2655 5.65608 14.138 5.52863L8.80471 0.195292C8.67725 0.0674165 8.50662 0.00537491 8.33333 0.00537491V0H4.33333C2.49546 0 1 1.49546 1 3.33333V12.6667C1 14.5045 2.49546 16 4.33333 16H11C12.8379 16 14.3333 14.5045 14.3333 12.6667V6H14.3271ZM9 2.27604L12.0573 5.33333H11C9.89712 5.33333 9 4.43621 9 3.33333V2.27604ZM11 14.6667H4.33333C3.23046 14.6667 2.33333 13.7695 2.33333 12.6667V3.33333C2.33333 2.23046 3.23046 1.33333 4.33333 1.33333H7.66667V3.33333C7.66667 5.17121 9.16212 6.66667 11 6.66667H13V12.6667C13 13.7695 12.1029 14.6667 11 14.6667ZM11 12C11 12.3685 10.7015 12.6667 10.3333 12.6667H5C4.63183 12.6667 4.33333 12.3685 4.33333 12C4.33333 11.6315 4.63183 11.3333 5 11.3333H10.3333C10.7015 11.3333 11 11.6315 11 12ZM11 9.33333C11 9.70183 10.7015 10 10.3333 10H5C4.63183 10 4.33333 9.70183 4.33333 9.33333C4.33333 8.96483 4.63183 8.66667 5 8.66667H10.3333C10.7015 8.66667 11 8.96483 11 9.33333ZM4.33333 6.66667C4.33333 6.29817 4.63183 6 5 6H6.33333C6.7015 6 7 6.29817 7 6.66667C7 7.03517 6.7015 7.33333 6.33333 7.33333H5C4.63183 7.33333 4.33333 7.03517 4.33333 6.66667Z' />
    </svg>
  );
}
