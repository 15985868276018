export default function LargeTextSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_5771_9261)'>
        <path d='M6.3427 3.49169C6.18128 2.9694 5.69782 2.61328 5.1509 2.61328H3.7967C3.24833 2.61328 2.76364 2.97173 2.60328 3.49669L0.0525388 11.8437C-0.0587093 12.2064 0.00884571 12.5999 0.234954 12.9047C0.460416 13.2093 0.816991 13.3892 1.19623 13.3892C1.73377 13.3892 2.20511 13.0307 2.34893 12.5131L2.87334 10.6231H5.95891L6.52367 12.5013C6.68199 13.0284 7.16707 13.3892 7.71841 13.3892C8.11526 13.3892 8.48815 13.2007 8.72306 12.8815C8.95849 12.5622 9.02772 12.1502 8.91037 11.7716L6.3427 3.49169ZM3.22517 8.80081L3.8639 6.51416C4.03951 5.89062 4.2001 5.0757 4.36042 4.43588H4.39093C4.55132 5.0757 4.74431 5.87485 4.93527 6.51416L5.60701 8.80081H3.22517Z' />
        <path d='M15.9643 11.6871V9.46134C15.9643 7.95623 15.283 6.69922 13.1482 6.69922C12.3557 6.69922 11.6966 6.84452 11.2162 7.01568C10.8969 7.12954 10.7212 7.47064 10.814 7.79658L10.8203 7.81802C10.869 7.98954 10.9866 8.13348 11.1449 8.21632C11.3027 8.29923 11.4873 8.31387 11.6566 8.25673C12.0065 8.13867 12.4077 8.06061 12.7952 8.06061C13.7775 8.06061 13.961 8.5452 13.961 8.88552V8.97752C11.6955 8.96414 10.2031 9.76258 10.2031 11.4255C10.2031 12.447 10.9755 13.3891 12.2724 13.3891C13.0313 13.3891 13.6852 13.1138 14.1047 12.6033H14.1433L14.1948 12.8834C14.2337 13.0928 14.4161 13.2452 14.6296 13.2452H15.5572C15.6805 13.2452 15.7979 13.194 15.8814 13.1038C15.9653 13.0136 16.0077 12.8925 15.9983 12.7698C15.9742 12.4546 15.9643 12.0763 15.9643 11.6871ZM14.0255 10.9147C14.0255 11.0324 14.0129 11.1505 13.9862 11.255C13.8555 11.661 13.4497 11.9882 12.952 11.9882C12.5073 11.9882 12.1665 11.7396 12.1665 11.2291C12.1665 10.4566 12.9787 10.2081 14.0255 10.2209V10.9147Z' />
      </g>
      <defs>
        <clipPath id='clip0_5771_9261'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
