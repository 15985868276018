import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import SideBar from 'components/SideBar';
import NavBar from 'components/NavBar';

import HomeView from 'views/dashboard/Home';
import RentView from 'views/dashboard/Rent';
import DimensionsSwitch from 'views/dashboard/ManageDimensions';
import SettingView from 'views/dashboard/Settings';
import OrdersSwitch from 'views/dashboard/Orders';
import ProductsView from 'views/dashboard/Products';
import CreateBusinessView from 'views/no-business/CreateBusiness';
import DeliveryView from 'views/dashboard/Delivery';
import PaymentView from 'views/dashboard/Payment';

const { Content } = Layout;

export default function DashboardRouter() {
  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth < 1300 || false,
  );

  const triggerSideBar = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      if (event.target.innerWidth < 1250) setIsCollapsed(true);
      else setIsCollapsed(false);
    });
  }, []);

  return (
    <BrowserRouter basename='/dashboard'>
      <Layout>
        <SideBar isCollapsed={isCollapsed} />

        <Layout
          className={`cc-layout ${
            isCollapsed ? 'cc-layout-collapsed' : 'cc-layout-open'
          }`}>
          <NavBar triggerSideBar={triggerSideBar} />
          <Content className='cc-layout-content'>
            <Switch>
              <Route exact path='/home' component={HomeView} />

              <Route path='/manage-dimensions' component={DimensionsSwitch} />

              <Route exact path='/products' component={ProductsView} />

              <Route path='/orders' component={OrdersSwitch} />

              <Route exact path='/offers' render={() => <h1>Sample Page</h1>} />

              <Route exact path='/rent' component={RentView} />

              <Route
                exact
                path='/campaigns'
                render={() => <h1>Sample Page</h1>}
              />

              <Route
                exact
                path='/chatbot'
                render={() => <h1>Sample Page</h1>}
              />

              <Route exact path='/delivery' component={DeliveryView} />

              <Route exact path='/payment' component={PaymentView} />

              <Route exact path='/settings' component={SettingView} />

              <Route path='/create-business' component={CreateBusinessView} />

              <Route path='*' render={() => <Redirect to='/home' />} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
}
