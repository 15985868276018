import { Button, Col, Image, Row, Switch, Typography } from 'antd';
import Icon from '@ant-design/icons';

import UnderlinedTitle from 'components/partials/UnderlinedTitle';
import GearSvg from 'assets/jsx-svgs/gear.svg';
import dhl from 'assets/temp/dhl.png';
import './style.css';

export default function DeliveryView() {
  return (
    <>
      <Row className='cc-mb-25' justify='space-between'>
        <Col>
          <UnderlinedTitle level={3}>Shipping</UnderlinedTitle>
        </Col>

        <Col>
          <Button className='cc-fz-14 cc-fw-500' type='primary'>
            + Add New Shipping Company
          </Button>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        {Array(16)
          .fill(0)
          .map((_, idx) => (
            <Col key={idx} span={6}>
              <div className='delv-card'>
                <div className='cc-center-items delv-card-up'>
                  <Image src={dhl} preview={false} height='90%' width='auto' />
                </div>

                <Row
                  className='delv-card-down'
                  justify={idx % 3 === 0 ? 'space-between' : 'center'}>
                  {idx % 3 === 0 ? (
                    <>
                      <Col className='cc-center-items cc-clickable'>
                        <Icon className='cc-gc cc-fz-18' component={GearSvg} />

                        <Typography className='cc-gc cc-fz-12 delv-ml-5'>
                          Settings
                        </Typography>
                      </Col>

                      <Switch />
                    </>
                  ) : (
                    <Typography.Text
                      className='cc-pc cc-fw-500 cc-clickable delv-conn-txt'
                      role='button'>
                      Connect Service
                    </Typography.Text>
                  )}
                </Row>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
}
