export default function DoneSvg() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#009FDB' fillOpacity='0.15' />
      <g clipPath='url(#clip0_5510_5576)'>
        <path
          d='M23.772 11.2803L23.0312 10.5395C22.7259 10.2342 22.2276 10.2342 21.9198 10.5395L14.1509 18.311L10.0812 14.2413C9.77596 13.9361 9.27767 13.9361 8.96983 14.2413L8.22891 14.9822C7.9237 15.2875 7.9237 15.7857 8.22891 16.0936L13.5926 21.4572C13.7465 21.6111 13.9448 21.6894 14.1457 21.6894C14.3465 21.6894 14.5474 21.6111 14.6987 21.4572L23.7642 12.3917C24.0773 12.0813 24.0773 11.5856 23.772 11.2803Z'
          fill='#009FDB'
        />
      </g>
      <defs>
        <clipPath id='clip0_5510_5576'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
