export default function TimeSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_5771_9287)'>
        <path d='M7.66667 16C11.8935 16 15.3333 12.4107 15.3333 8C15.3333 3.58934 11.8936 0 7.66667 0C3.43974 0 0 3.58934 0 8C0 12.4107 3.43979 16 7.66667 16ZM7.66667 1.06665C11.3313 1.06665 14.3111 4.17597 14.3111 8C14.3111 11.824 11.3314 14.9334 7.66667 14.9334C4.00197 14.9334 1.02221 11.824 1.02221 8C1.02221 4.17597 4.00202 1.06665 7.66667 1.06665Z' />
        <path d='M9.90347 10.5486C9.99804 10.6285 10.1105 10.6659 10.2229 10.6659C10.3737 10.6659 10.5219 10.5966 10.6216 10.4659C10.7979 10.2366 10.7621 9.90056 10.5423 9.71655L8.17845 7.74321V3.73254C8.17845 3.43921 7.94847 3.19922 7.66735 3.19922C7.38624 3.19922 7.15625 3.43921 7.15625 3.73254V7.99923C7.15625 8.16191 7.22782 8.3139 7.34791 8.41521L9.90347 10.5486Z' />
      </g>
      <defs>
        <clipPath id='clip0_5771_9287'>
          <rect width='15.3333' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
