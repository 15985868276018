export default function CheckoutSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5552 3.5013C13.7074 3.50426 13.83 3.62691 13.833 3.77908V12.668C13.833 12.8245 13.7062 12.9513 13.5497 12.9513H2.44412C2.28981 12.9483 2.16634 12.8223 2.16634 12.668V3.77908C2.1693 3.62691 2.29195 3.50426 2.44412 3.5013H13.5552ZM13.5552 2.66797H2.44412C1.83047 2.66797 1.33301 3.16543 1.33301 3.77908V12.668C1.33301 13.2816 1.83047 13.7791 2.44412 13.7791H13.5552C14.1689 13.7791 14.6663 13.2816 14.6663 12.668V3.77908C14.6663 3.16543 14.1689 2.66797 13.5552 2.66797Z'
        fill='currentColor'
      />
      <path
        d='M1.97754 5.43945H13.9275'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.99414 7.66797H13.9441'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.11084 11H7.44417'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.7769 11H11.888'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
