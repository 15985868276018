import { useContext, useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';

import { dashboardIstance as axios } from 'api/axios';
import UnderlinedTitle from 'components/partials/UnderlinedTitle';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import UserContext from 'contexts/UserContext';
import CheckBoxUncheckedSvg from 'assets/jsx-svgs/checkbox-unchecked.svg';
import ReloadSvg from 'assets/jsx-svgs/reload.svg';
import ShowSvg from 'assets/jsx-svgs/show.svg';
import HideSvg from 'assets/jsx-svgs/hide.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';
import SearchSvg from 'assets/jsx-svgs/search.svg';
import ProductsSvg from 'assets/jsx-svgs/products.svg';
import ArrowDownSvg from 'assets/jsx-svgs/arrow-down.svg';
import ServiceSvg from 'assets/jsx-svgs/service.svg';
import SpoonForkSvg from 'assets/jsx-svgs/spoon-fork.svg';
import ProductsPackageSvg from 'assets/jsx-svgs/products-package.svg';

import ProductContext from './contexts/ProductContext';
import {
  normalizeProduct,
  normalizeBrand,
  normalizeCategory,
} from 'utils/products/normalize-product';
import ProductCard from './ProductCard';
import './style.css';

export default function ProductsView2() {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const {
    forms: { langs },
  } = useContext(ChickChackConfigContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation(['products', 'common']);

  const addProduct = (type) => {
    const newProduct = {
      type,
      showStatus: false,
      hasOption: false,
      name: langs.reduce((prev, curr) => ({ ...prev, [curr.code]: '' }), {}),
      timestamp: Date.now(),
    };

    const _products = [newProduct, ...products];
    setProducts(_products);
    message.success('Product added successfully');
  };

  const refetchProduct = async (productId, productIdx) => {
    const {
      data: { data: product },
    } = await axios.get(
      `/seller/product/${user?.selectedBusiness?.id}/get-one-product/${productId}`,
    );

    const updatedProducts = cloneDeep(products);
    updatedProducts[productIdx] = normalizeProduct(product);

    setProducts(updatedProducts);
  };

  const triggerQuantity = (productIdx) => {
    const updatedProducts = cloneDeep(products);

    if (updatedProducts[productIdx].quantity === 'Unlimited Quantity')
      updatedProducts[productIdx].quantity = 0;
    else updatedProducts[productIdx].quantity = 'Unlimited Quantity';

    setProducts(updatedProducts);
  };

  const triggerShowStatus = async (productId, productIdx) => {
    const updatedProducts = cloneDeep(products);

    try {
      await axios.put(
        `/seller/product/${user?.selectedBusiness?.id}/show-status`,
        {
          productId,
          showStatus: !updatedProducts[productIdx].showStatus,
        },
      );

      updatedProducts[productIdx].showStatus =
        !updatedProducts[productIdx].showStatus;

      setProducts(updatedProducts);
      if (updatedProducts[productIdx].showStatus)
        message.success('Product is now visible');
      else message.success('Product is now hidden');
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        message.error(error.response.data.message);
      } else {
        message.error(t('generalError', { ns: 'common' }));
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const {
          data: { data: products },
        } = await axios.get(
          `/seller/product/${user?.selectedBusiness?.id}/get-list`,
        );

        const {
          data: { data: brands },
        } = await axios.get('/seller/brand');

        const {
          data: { data: models },
        } = await axios.get('/seller/model/get-trader-model');

        const {
          data: { data: categories },
        } = await axios.get('/seller/category');

        setProducts(products.map(normalizeProduct));
        setBrands(brands.map(normalizeBrand));
        setCategories(categories.map(normalizeCategory));
        setModels(models);
      } catch (error) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          message.error(error.response.data.message);
        } else {
          message.error(t('generalError', { ns: 'common' }));
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.selectedBusiness?.id]);

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <UnderlinedTitle level={3}>{t('title')}</UnderlinedTitle>
        </Col>

        <Col>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item
                  className='pv-align-start cc-w-100'
                  key='0'
                  icon={
                    <Icon className='cc-sc cc-fz-22' component={ProductsSvg} />
                  }
                  onClick={() => addProduct('READYTOUSE')}>
                  <Typography className='cc-sc cc-fz-12 cc-fw-500 cc-w-100'>
                    {t('readyToUseTitle')}
                  </Typography>

                  <Typography.Text className='cc-gc cc-fz-12 cc-w-100'>
                    {t('readyToUseDesc')}
                  </Typography.Text>
                </Menu.Item>

                <Menu.Item
                  className='pv-align-start'
                  key='1'
                  icon={
                    <Icon className='cc-sc cc-fz-22' component={ServiceSvg} />
                  }
                  onClick={() => addProduct('SERVICE')}>
                  <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                    {t('serviceTitle')}
                  </Typography>

                  <Typography.Text className='cc-gc cc-fz-12'>
                    {t('serviceDesc')}
                  </Typography.Text>
                </Menu.Item>

                <Menu.Item
                  className='pv-align-start'
                  key='2'
                  icon={
                    <Icon className='cc-sc cc-fz-22' component={SpoonForkSvg} />
                  }
                  onClick={() => addProduct('FOOD')}>
                  <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                    {t('foodTitle')}
                  </Typography>

                  <Typography.Text className='cc-gc cc-fz-12'>
                    {t('foodDesc')}
                  </Typography.Text>
                </Menu.Item>

                <Menu.Item
                  className='pv-align-start'
                  key='3'
                  icon={
                    <Icon
                      className='cc-sc cc-fz-22'
                      component={ProductsPackageSvg}
                    />
                  }
                  onClick={() => addProduct('GROUP')}>
                  <Typography className='cc-sc cc-fz-12 cc-fw-500'>
                    {t('groupTitle')}
                  </Typography>

                  <Typography.Text className='cc-gc cc-fz-12'>
                    {t('groupDesc')}
                  </Typography.Text>
                </Menu.Item>
              </Menu>
            }>
            <Button
              className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn'
              type='primary'>
              {t('addNewBtn')} <Icon component={ArrowDownSvg} />
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <Divider className='pv-horizontal-divider' />

      <Row justify='space-between'>
        <Col className='cc-sc cc-center-items'>
          <Icon
            className='cc-fz-18 pv-mr-20 cc-clickable'
            component={CheckBoxUncheckedSvg}
          />

          <Icon className='cc-fz-18 cc-clickable' component={ReloadSvg} />

          <Divider className='pv-vertical-divider' type='vertical' />

          <Icon
            className='cc-fz-24 pv-mr-20 cc-clickable'
            component={ShowSvg}
          />

          <Icon className='cc-fz-24 cc-clickable' component={HideSvg} />

          <Divider className='pv-vertical-divider' type='vertical' />
          <Icon className='cc-fz-24 cc-clickable' component={DeleteSvg} />
        </Col>

        <Col className='cc-center-items'>
          <AutoComplete
            className='pv-search-auto-complete'
            style={{ width: 400 }}>
            <Input
              type='text'
              placeholder={t('autoCompletePlaceholder')}
              suffix={<Icon className='cc-sc cc-fz-22' component={SearchSvg} />}
              onChange={(e) => console.log(`search value:`, e.target.value)}
            />
          </AutoComplete>
        </Col>
      </Row>

      <Divider className='pv-horizontal-divider' />

      <Row gutter={15}>
        {loading ? (
          Array(3)
            .fill(1)
            .map((_, k) => (
              <Col key={k} span={8} className='cc-mb-15'>
                <Skeleton.Image active />
                <Skeleton active />
              </Col>
            ))
        ) : products?.length ? (
          products.map((product, idx) => (
            <ProductContext.Provider
              key={product.id || product.timestamp}
              value={{
                product,
                productIdx: idx,
                refetchProduct,
                triggerQuantity,
                triggerShowStatus,
                brands,
                categories,
                models,
              }}>
              <Col
                className={`cc-mb-15 ${
                  !product.showStatus ? 'pv-transparent' : ''
                }`}
                xxl={6}
                span={8}>
                <ProductCard />
              </Col>
            </ProductContext.Provider>
          ))
        ) : (
          <UnderlinedTitle level={2} style={{ margin: 'auto' }}>
            You have no products
          </UnderlinedTitle>
        )}
      </Row>
    </>
  );
}
