import { useContext } from 'react';
import { Button, Col, Row, Form, Dropdown, Select, Input } from 'antd';
import { SketchPicker } from 'react-color';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import MultiLingualInput from 'components/MultiLingualInput';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';
import LargeTextSvg from 'assets/jsx-svgs/large-text.svg';
import DeleteSvg from 'assets/jsx-svgs/delete.svg';

const { Option } = Select;

export default function ValuesList({ name, removeParent }) {
  const {
    forms: { langs, inputsLang, setInputsLang },
  } = useContext(ChickChackConfigContext);
  const { t } = useTranslation(['products']);

  return (
    <Form.List name={[name, 'values']}>
      {(fields, { add, remove }) => (
        <Row className='pv-modal-choices-container cc-mb-20'>
          {fields.map((field) => (
            <Col className='cc-mb-15' key={field.name} span={24}>
              <Row justify='space-between' align='middle'>
                <Form.Item name={[field.name, 'id']} noStyle>
                  <Input hidden />
                </Form.Item>

                <Col span={22}>
                  <Form.Item
                    shouldUpdate={(prev, curr) =>
                      prev?.options?.[name]?.type !==
                        curr?.options?.[name]?.type ||
                      prev?.options?.[name]?.values?.[field.name]?.colorCode !==
                        curr?.options?.[name]?.values?.[field.name]?.colorCode
                    }
                    noStyle>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name={[field.name, 'text']}
                        rules={[
                          {
                            type: 'object',
                            required: true,
                            fields: {
                              ar: { type: 'string', required: true },
                              en: { type: 'string', required: true },
                            },
                            message: 'Please enter a value in all languages',
                          },
                        ]}
                        noStyle>
                        <MultiLingualInput
                          type='text'
                          placeholder={t('valueNamePlaceholder')}
                          prefix={
                            <Icon
                              className='cc-gc pv-mr-5'
                              component={LargeTextSvg}
                            />
                          }
                          suffix={
                            getFieldValue(['options', name, 'type']) ===
                              'COLOR' && (
                              <Dropdown
                                trigger={['click']}
                                overlay={
                                  <Form.Item
                                    name={[field.name, 'colorCode']}
                                    valuePropName='color'
                                    getValueFromEvent={(color) => color?.hex}
                                    noStyle>
                                    <SketchPicker />
                                  </Form.Item>
                                }
                                placement='bottomLeft'>
                                <div
                                  className='pv-modal-choices-color-container'
                                  style={{
                                    backgroundColor:
                                      getFieldValue([
                                        'options',
                                        name,
                                        'values',
                                        field.name,
                                        'colorCode',
                                      ]) || '#000',
                                  }}
                                />
                              </Dropdown>
                            )
                          }
                          addonAfter={
                            <Select
                              className='cc-sc cc-fz-14 cc-fw-500 cc-lang-select'
                              defaultValue='en'
                              value={inputsLang?.code}
                              onChange={(code) =>
                                setInputsLang(
                                  langs.find((lang) => lang.code === code),
                                )
                              }>
                              {langs?.map((lang) => (
                                <Option
                                  className='cc-fz-14'
                                  key={lang.code}
                                  value={lang.code}>
                                  {lang.code.toUpperCase()}
                                </Option>
                              ))}
                            </Select>
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <Form.Item
                  shouldUpdate={(prev, curr) =>
                    prev?.options?.[name]?.values !==
                    curr?.options?.[name]?.values
                  }
                  noStyle>
                  {({ getFieldValue }) => (
                    <Col span={1}>
                      <Icon
                        className='cc-fz-24 pv-rc'
                        component={DeleteSvg}
                        onClick={() => {
                          remove(field.name);

                          const values = getFieldValue([
                            'options',
                            name,
                            'values',
                          ]);
                          if (!values?.length) removeParent();
                        }}
                      />
                    </Col>
                  )}
                </Form.Item>
              </Row>
            </Col>
          ))}

          <Col span={24}>
            <Button
              className='cc-pc cc-fz-14 cc-fw-500 cc-custom-btn-2'
              type='primary'
              onClick={() => add({ text: {} })}>
              {t('addNewValueBtn')}
            </Button>
          </Col>
        </Row>
      )}
    </Form.List>
  );
}
