export default function BellSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 11V8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8V11C5 14.3 2 15.1 2 17C2 18.7 5.9 20 12 20C18.1 20 22 18.7 22 17C22 15.1 19 14.3 19 11Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9995 22.0004C10.9885 22.0004 10.0385 21.9664 9.14453 21.9004C9.5357 23.1482 10.6919 23.9974 11.9995 23.9974C13.3072 23.9974 14.4634 23.1482 14.8545 21.9004C13.9605 21.9664 13.0105 22.0004 11.9995 22.0004Z'
        fill='currentColor'
      />
    </svg>
  );
}
