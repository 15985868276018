export default function WalletOutlinedSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <path
        d='M19.6975 11.6637V9.77642C19.6975 8.84923 18.929 8.09153 17.9714 8.05845V6.90006C17.9714 6.29669 17.4601 5.8058 16.8316 5.8058H13.6934L13.2882 4.72444C13.1849 4.44869 12.9757 4.22833 12.6993 4.10399C12.4229 3.97962 12.1126 3.96637 11.8258 4.0666L6.13121 6.05632C5.84469 6.15643 5.6161 6.35775 5.48752 6.62318C5.35894 6.88862 5.34588 7.18617 5.45078 7.46105L5.67957 8.06055C4.74374 8.11611 4 8.86427 4 9.77642V18.2804C4 19.2286 4.80354 20 5.79121 20H17.9063C18.894 20 19.6975 19.2286 19.6975 18.2804V16.3307C20.2491 16.2546 20.6745 15.798 20.6745 15.2478V12.7466C20.6745 12.1963 20.2491 11.7398 19.6975 11.6637V11.6637ZM19.6975 15.2478C19.6975 15.334 19.6245 15.4041 19.5347 15.4041H16.9293C16.1212 15.4041 15.4637 14.7729 15.4637 13.9972C15.4637 13.2214 16.1212 12.5903 16.9293 12.5903H19.5347C19.6245 12.5903 19.6975 12.6604 19.6975 12.7466V15.2478ZM6.36818 7.1384C6.34795 7.0854 6.36277 7.04066 6.37342 7.01868C6.38407 6.99673 6.41016 6.957 6.46539 6.93771L12.1599 4.94799C12.2152 4.9287 12.2618 4.94305 12.2847 4.95333C12.3076 4.96362 12.349 4.98882 12.3689 5.04197L12.6551 5.80583H12.435C11.8065 5.80583 11.2951 6.29672 11.2951 6.9001V8.05689H6.7187L6.36818 7.1384ZM16.9944 6.90006V8.05686H12.2721V6.90006C12.2721 6.81387 12.3452 6.74374 12.435 6.74374H16.8316C16.9214 6.74374 16.9944 6.81387 16.9944 6.90006V6.90006ZM17.9063 19.0621H5.79121C5.34227 19.0621 4.97702 18.7114 4.97702 18.2804V9.77642C4.97702 9.34544 5.34227 8.9948 5.79121 8.9948H17.9063C18.3552 8.9948 18.7205 9.34544 18.7205 9.77642V11.6523H16.9293C15.5825 11.6523 14.4867 12.7042 14.4867 13.9972C14.4867 15.2901 15.5825 16.342 16.9293 16.342H18.7205V18.2804C18.7205 18.7114 18.3552 19.0621 17.9063 19.0621Z'
        fillOpacity='0.8'
      />
      <path
        d='M16.9299 14.4653C17.1997 14.4653 17.4184 14.2553 17.4184 13.9963C17.4184 13.7373 17.1997 13.5273 16.9299 13.5273C16.6601 13.5273 16.4414 13.7373 16.4414 13.9963C16.4414 14.2553 16.6601 14.4653 16.9299 14.4653Z'
        fillOpacity='0.8'
      />
    </svg>
  );
}
