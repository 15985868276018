export default function ServiceSvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g clipPath='url(#clip0_6147_9698)'>
        <path d='M8.46874 0C7.43465 0 6.5625 0.872157 6.5625 1.90625C6.5625 2.94035 7.43465 3.8125 8.46874 3.8125C9.50284 3.8125 10.3437 2.94035 10.3437 1.90625C10.3437 0.872157 9.50284 0 8.46874 0Z' />
        <path d='M10.5497 3.8125C10.0346 4.38372 9.29674 4.75 8.46874 4.75C7.64075 4.75 6.87165 4.38372 6.3565 3.8125C5.90675 4.31122 5.625 4.96456 5.625 5.6875V6.15625C5.625 6.41534 5.83466 6.625 6.09375 6.625H10.8125C11.0716 6.625 11.2812 6.41534 11.2812 6.15625V5.6875C11.2812 4.96456 10.9995 4.31122 10.5497 3.8125Z' />
        <path d='M4.5288 14.3876L2.14002 9.5984C1.90936 9.13593 1.34799 8.94733 0.884956 9.17674L0.260612 9.48602C0.0281433 9.6013 -0.0664505 9.88383 0.0495808 10.1159L2.86208 15.7409C2.97774 15.9729 3.25908 16.0652 3.48968 15.9515L4.10599 15.6461C4.57083 15.4159 4.7603 14.8518 4.5288 14.3876Z' />
        <path d='M15.6161 7.74032C15.2317 7.45907 14.6973 7.51532 14.3786 7.86219L11.8161 11.0122C11.6379 11.1997 11.3004 11.3122 11.1223 11.3122H8.9379C8.67537 11.3122 8.46915 11.106 8.46915 10.8434C8.46915 10.5809 8.67537 10.3747 8.9379 10.3747C9.56572 10.3747 10.2608 10.3747 10.8129 10.3747C11.3285 10.3747 11.7504 9.95282 11.7504 9.43719C11.7504 8.92157 11.3285 8.49969 10.8129 8.49969C8.61147 8.49969 10.7113 8.49969 8.35665 8.49969C8.12303 8.49969 8.00653 8.35154 7.82228 8.19029C7.097 7.53763 6.01137 7.22319 4.91231 7.47794C4.30206 7.61938 3.89053 7.86591 3.47316 8.19648L3.45925 8.18519L2.78125 8.78188L5.44575 14.1247H6.23475H10.8129C11.6942 14.1247 12.5379 13.7028 13.0629 12.9997L15.8129 9.06219C16.1223 8.64966 16.0379 8.04973 15.6161 7.74032Z' />
      </g>
      <defs>
        <clipPath id='clip0_6147_9698'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
