import { useEffect, useState } from 'react';
import { ConfigProvider as AntdConfigProvider, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import en from 'antd/lib/locale/en_US';

import { dashboardIstance, commonInstance } from 'api/axios';
import AddNewLangModal from 'components/AddNewLangModal';
import I18nContext from 'contexts/I18nContext';
import UserContext from 'contexts/UserContext';
import ChickChackConfigContext from 'contexts/ChickChackConfigContext';

import ChickChackRouter from './router';
import i18n from './i18n';

export default function App() {
  const [user, setUser] = useState(null);
  const [language, setLanguage] = useState({
    lang: en,
    code: 'en',
    dir: 'ltr',
  });

  // form langs config
  const [langs, setLangs] = useState([]);
  const [inputsLang, setInputsLang] = useState(langs[0]);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation(['common']);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: user },
        } = await dashboardIstance.get('/auth/me');

        const {
          data: { data: langs },
        } = await commonInstance.get('/language-list');

        if (user.hasBusiness) {
          const {
            data: { data: businesses },
          } = await dashboardIstance.get('/business/list');

          setUser({ ...user, businesses, selectedBusiness: businesses[0] });
        } else setUser(user);

        setLangs(langs);
        setInputsLang(langs?.find((lang) => lang.code === 'ar') || langs[0]);
      } catch (ignored) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // set i18n config
    document.body.dir = language.dir;
    i18n.changeLanguage(language.code);
  }, [language]);

  return loading ? (
    <Spin
      className='cc-loading-screen'
      tip={
        <Typography className='cc-pc cc-fz-18 cc-fw-600'>Loading...</Typography>
      }
      size='large'
      spinning
    />
  ) : (
    <I18nContext.Provider value={{ language, setLanguage }}>
      <UserContext.Provider value={{ user, setUser }}>
        <AntdConfigProvider
          direction={language.dir}
          locale={language.lang}
          form={{ validateMessages: { required: t('formRequiredMessage') } }}>
          <ChickChackConfigContext.Provider
            value={{
              forms: {
                langs,
                setLangs,
                inputsLang,
                setInputsLang,
                isVisible,
                setIsVisible,
              },
            }}>
            <ChickChackRouter />
            <AddNewLangModal />
          </ChickChackConfigContext.Provider>
        </AntdConfigProvider>
      </UserContext.Provider>
    </I18nContext.Provider>
  );
}
