import { Link } from 'react-router-dom';
import { Button, Col, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import nbvFg from 'assets/images/nbv-fg.png';
import UnderlinedTitle from 'components/partials/UnderlinedTitle';

import './style.css';

export default function NoBusinessView() {
  const { t } = useTranslation(['noBusiness']);

  return (
    <Row justify='center' className='nbv'>
      <Col>
        <UnderlinedTitle className='cc-mb-25' level={3}>
          {t('title')}
        </UnderlinedTitle>

        <Image
          className='cc-mb-25'
          src={nbvFg}
          width={window.innerHeight / 1.8}
          preview={false}
        />

        <Typography className='cc-sc cc-fz-18 cc-fw-500 cc-mb-25'>
          {t('noBusinessTxt')}?{' '}
          <span className='cc-fw-600'> {t('createBusinessTxt')}</span>
        </Typography>

        <Link to='/create-business'>
          <Button className='cc-pc cc-fz-18 cc-fw-500 cc-custom-btn'>
            {t('startBusinessBtn')}
          </Button>
        </Link>
      </Col>
    </Row>
  );
}
