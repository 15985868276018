import ProductsSvg from 'assets/jsx-svgs/products.svg';
import ServiceSvg from 'assets/jsx-svgs/service.svg';
import SpoonForkSvg from 'assets/jsx-svgs/spoon-fork.svg';
import ProductsPackageSvg from 'assets/jsx-svgs/products-package.svg';

const getProductIcon = (type) => {
  switch (type) {
    case 'FOOD':
      return SpoonForkSvg;
    case 'READYTOUSE':
      return ProductsSvg;
    case 'SERVICE':
      return ServiceSvg;
    case 'GROUP':
      return ProductsPackageSvg;
    default:
      return ProductsSvg;
  }
};

const getProductToolTip = (type, t) => {
  switch (type) {
    case 'FOOD':
      return t('foodToolTip');
    case 'READYTOUSE':
      return t('readyToUseTooltip');
    case 'SERVICE':
      return t('serviceToolTip');
    case 'GROUP':
      return t('groupToolTip');
    default:
      return '';
  }
};

const getFullFieldType = (type, t) => {
  switch (type) {
    case 'text':
      return t('smallTxtField');
    case 'textArea':
      return t('largeTxtField');
    case 'number':
      return t('digitsField');
    case 'radio':
      return t('singleChoice');
    case 'checkbox':
      return t('multupleChoices');
    case 'date':
      return t('dateField');
    case 'time':
      return t('timeField');
    default:
      return '-';
  }
};

export { getProductIcon, getProductToolTip, getFullFieldType };
