export default function InfinitySvg() {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'>
      <g>
        <path d='M18.5141 6.17188C15.1092 6.17188 12.8559 8.95574 10.868 11.4124C9.16957 13.511 7.56571 15.4927 5.6123 15.4927C3.58188 15.4927 2.33525 13.4059 2.33525 11.9099C2.33525 10.5228 3.70282 8.50712 5.84619 8.50712C7.92598 8.50712 9.00949 10.1717 9.06413 10.257C9.40106 10.8023 10.1165 10.9739 10.6645 10.6396C11.2152 10.3041 11.3896 9.58568 11.054 9.03498C10.9827 8.91835 9.2699 6.17203 5.84619 6.17203C2.40107 6.17203 0 9.19634 0 11.9101C0 14.7093 2.30489 17.8281 5.61214 17.8281C8.67942 17.8281 10.715 15.3135 12.6831 12.8814C14.5037 10.6323 16.2236 8.50728 18.5141 8.50728C19.8769 8.50728 20.7723 8.96964 21.251 9.91958C21.6657 10.744 21.6654 11.6768 21.6651 12C21.6651 12.1853 21.5671 13.1148 21.1137 13.9518C20.5967 14.9053 19.8628 15.3491 18.8021 15.3491C16.6663 15.3491 15.5123 13.9608 15.0777 13.4379C14.9648 13.3018 14.8835 13.2038 14.7748 13.1108C14.2856 12.6918 13.5482 12.748 13.1283 13.2378C12.7235 13.7103 12.7625 14.4126 13.2047 14.8377C13.2258 14.8617 13.2512 14.8931 13.2814 14.9297C13.8477 15.6113 15.5698 17.684 18.8016 17.684C22.7296 17.684 23.9998 13.8541 23.9998 11.984C24.0002 10.793 23.7771 9.74528 23.3362 8.8693C22.7171 7.63913 21.3777 6.17188 18.5141 6.17188Z' />
      </g>
    </svg>
  );
}
