import { createContext } from 'react';

const UserContext = createContext({
  user: null,
  setUser: null,
  businesses: null,
  selectedBusiness: null,
});

export default UserContext;
