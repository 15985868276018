const reduceOptionsToSkus = (options) => {
  const attributes = options.reduce(
    (prev, curr, idx) => ({
      ...prev,
      [idx]: curr.values,
    }),
    {},
  );

  let attrs = [];

  for (const [attr, values] of Object.entries(attributes))
    attrs.push(
      values.map((v) => ({
        [attr]: v,
      })),
    );

  attrs = attrs.reduce((a, b) =>
    a.flatMap((d) =>
      b.map((e) => ({
        ...d,
        ...e,
      })),
    ),
  );

  return attrs.map((attr) => ({ values: Object.values(attr) }));
};

const reduceOptionsToSkusWithIds = (options) => {
  const attributes = options.reduce(
    (prev, curr, idx) => ({
      ...prev,
      [idx]: curr.productOptionValues,
    }),
    {},
  );

  let attrs = [];

  for (const [attr, values] of Object.entries(attributes))
    attrs.push(
      values.map((v) => ({
        [attr]: v,
      })),
    );

  attrs = attrs.reduce((a, b) =>
    a.flatMap((d) =>
      b.map((e) => ({
        ...d,
        ...e,
      })),
    ),
  );

  return attrs
    .map((attr) => ({ values: Object.values(attr) }))
    .map((sku) => ({ values: sku.values.map((value) => value.id) }));
};

export { reduceOptionsToSkus, reduceOptionsToSkusWithIds };
